import React, { useEffect, useState } from "react";

import logo from "../images/tap karo logo.png";
import { BASE_URI, LOGIN_URI } from "../utils/index";
import { MdEmail } from "react-icons/md";
import { BsKeyFill } from "react-icons/bs";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router";
import axios from "axios";
import Axios from "axios";
import { useRef } from "react";
import Captcha from "../Components/Captcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const history = useNavigate();
  const captchaRef = useRef(null);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showPassword, setshowPassword] = useState(false);
  return (
    <div className="overflow-x-hidden">
      <Nav />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="h-[100vh] bg-navyBlue flex justify-center items-center">
        <div className="border flex flex-col border-gray-700 mobile:py-3 md:py-4 items-center text-white rounded-3xl backdrop-blur-xl mobile:w-[90%] md:w-[25%]">
          <img src={logo} alt={logo} className="w-[30%]" />
          <h1 className="text-2xl mt-3 self-start ml-10">Log In</h1>
          <p className="mb-2 self-start ml-10 mt-1">
            Log in to manage your account
          </p>
          <div className="mobile:w-[85%] md:w-[80%] m-auto">
            <div className="flex items-center">
              <MdEmail size={22} className="absolute left-[13%]" />
              <input
                type="text"
                value={data.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
                placeholder="Email address"
                className="border my-2 outline-none bg-transparent py-1 px-3 rounded-lg w-[100%] relative mobile:pl-12 md:pl-10 tracking-widest"
              />
            </div>
            <div className="flex items-center">
              <BsKeyFill size={24} className="absolute left-[13%]" />
              <input
                value={data.password}
                onChange={(e) => {
                  setData({ ...data, password: e.target.value });
                }}
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                className="border my-2 outline-none bg-transparent py-1 mobile:pl-12 md:pl-10 pr-16 rounded-lg w-[100%] relative tracking-widest"
              />
              <p
                onClick={(e) => {
                  e.preventDefault();
                  setshowPassword(!showPassword);
                }}
                className="absolute right-[15%] cursor-pointer transition-all font-medium text-gray-400 hover:text-white"
              >
                {showPassword ? "Hide" : "Show"}
              </p>
            </div>
            <div className="flex items-center w-[100%] justify-center my-2">
              <Captcha captchaRef={captchaRef} />
            </div>
            <div className="flex items-center my-1 justify-between">
              <div className="flex">
                <input type="checkbox" />
                <p className="ml-1.5 text-sm">Remember Me</p>
              </div>
              <div
                className="text-sm cursor-pointer"
                onClick={async (e) => {
                  e.preventDefault();
                  if (data.email) {
                    captchaRef.current.reset();
                    axios
                      .post(`${LOGIN_URI}/password-reset`, {
                        email: data.email,
                      })
                      .then((res) => {
                        toast.success(res.data.data);
                      })
                      .catch((err) => {
                        toast.error("Internal server error");
                      });
                  } else {
                    captchaRef.current.reset();
                    toast.warning("Please Enter email address");
                  }
                }}
              >
                Forgot Password?
              </div>
            </div>
            <button
              onClick={async (e) => {
                e.preventDefault();
                let token = captchaRef.current.getValue();

                if (token) {
                  let response = await Axios.post(`${BASE_URI}verify-token`, {
                    reCAPTCHA_TOKEN: token,
                  });
                  if (
                    response.status === 200 &&
                    response.data.verification_info.success === true
                  ) {
                    if (data.email && data.password) {
                      captchaRef.current.reset();
                      axios
                        .post(`${LOGIN_URI}/signin`, data)
                        .then((res) => {
                          if (res.status !== 200) {
                            toast.warning(res.data.data);
                          } else {
                            toast.success("Login Success!");
                            localStorage.setItem("token", res.data.jwtToken);
                            setData({
                              email: "",
                              password: "",
                            });
                            history("/user");
                          }
                        })
                        .catch((err) => {
                          toast.error("Internal server error");
                        });
                    } else {
                      captchaRef.current.reset();
                      toast.warning("Please fill all the details");
                    }
                  } else {
                    captchaRef.current.reset();
                    toast.warning("User not verified");
                  }
                } else {
                  captchaRef.current.reset();
                  toast.warning("User not verified");
                }
              }}
              className="bg-lightBlue w-full mt-1 py-1 rounded-lg font-medium text-white hover:bg-white hover:text-lightBlue"
            >
              Log In
            </button>
            <p
              className="text-sm text-center mt-2 cursor-pointer text-gray-300 hover:text-white"
              onClick={(e) => {
                e.preventDefault();
                history("/Register");
              }}
            >
              New Here? Sign up now
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
