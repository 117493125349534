import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import bg from "../../images/user/fbg.jpg";
import { PROFILE_URI } from "../../utils";
import axios from "axios";
import TapKaroContext from "../../context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { data as codes } from "../../data/countryCode.js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth < 550 ? "80vw" : "20vw",
    borderRadius: "0.5rem",
    backgroundImage: `url(${bg})`,
    padding: 0,
    backgroundPosition: "center",
  },
};

let icons = ["phone", "location", "portfolio", "mail"];

const ContactUpdate = ({ setIsOpen, modalIsOpen, iconData, contactData }) => {
  const { getProfile } = useContext(TapKaroContext);

  const [data, setData] = useState({
    name: "",
    url: "",
    phone: "",
    location: "",
    portfolio: "",
    mail: "",
    cCode: 91,
  });
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (contactData) {
      let icon = icons.find((e) => {
        return e.toLowerCase().includes(contactData?.name?.toLowerCase());
      });
      setData({
        name: icon,
        url: contactData?.url,
        cCode: contactData?.cCode ? contactData?.cCode : 91,
      });
    }
  }, [iconData]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="text-white bg-transparent h-full w-full px-5 p-4">
          <label htmlFor="social">Add Contact :</label>
          <select
            id="social"
            value={data?.name}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
            disabled={iconData?.name}
            className="outline-none block my-1 bg-transparent w-full px-2 border-b pb-1 border-b-gray-400 mb-6 capitalize"
          >
            {icons.map((e, i) => {
              return (
                <option value={e} key={i} className="text-navyBlue capitalize">
                  {e}
                </option>
              );
            })}
          </select>
          <label htmlFor="input">Value :</label>{" "}
          <div className="flex items-center">
            {data?.name === "phone" ? (
              <select
                value={data?.cCode}
                className="border bg-transparent mb-1.5 py-0.5 w-[28%] mr-2 text-sm h-full rounded-lg px-0.5 outline-none"
                onChange={(e) => {
                  setData({ ...data, cCode: e.target.value });
                }}
              >
                {codes?.map((e, i) => {
                  return (
                    <option
                      value={e?.code}
                      key={i}
                      className="text-darkNavyBlue"
                    >
                      {e?.country}-{e?.code}
                    </option>
                  );
                })}
              </select>
            ) : null}
            <input
              type={data?.name === "phone" ? "number" : "text"}
              value={data.url}
              disabled={data.name === "mail"}
              onChange={(e) => {
                setData({ ...data, url: e.target.value });
              }}
              id="input"
              placeholder="Enter Text"
              className="outline-none block px-2 my-1 bg-transparent w-full border-b pb-1 border-b-gray-400 mb-3"
            />
          </div>
          {data?.name !== "portfolio" ? null : (
            <p className="text-sm text-right">
              Please add url with https://test.com/user
            </p>
          )}
          <button
            onClick={(e) => {
              e.preventDefault();
              if (contactData?.name == "phone") {
                if (data?.url?.toString().length != 10) {
                  toast.warning("Phone number length must be 10 characters");
                } else {
                  axios
                    .post(`${PROFILE_URI}/user/updateContactIcon`, {
                      cCode:
                        contactData?.name == "phone"
                          ? data?.cCode
                            ? data?.cCode
                            : iconData?.cCode
                          : "",
                      phone:
                        contactData?.name == "phone"
                          ? data?.url
                            ? data?.url
                            : iconData?.phone
                          : iconData?.phone,
                      location:
                        contactData?.name == "location"
                          ? data?.url
                            ? data?.url
                            : iconData?.location
                          : iconData?.location,
                      portfolio:
                        contactData?.name == "portfolio"
                          ? data?.url
                            ? data?.url
                            : iconData?.portfolio
                          : iconData?.portfolio,
                      token: localStorage.getItem("token"),
                    })
                    .then((res) => {
                      getProfile();
                      setData({
                        name: "",
                        url: "",
                        phone: "",
                        location: "",
                        portfolio: "",
                      });
                      setIsOpen(!modalIsOpen);
                    })
                    .catch((err) => {});
                }
              } else {
                axios
                  .post(`${PROFILE_URI}/user/updateContactIcon`, {
                    phone:
                      contactData?.name == "phone"
                        ? data?.url
                          ? data?.url
                          : iconData?.phone
                        : iconData?.phone,
                    location:
                      contactData?.name == "location"
                        ? data?.url
                          ? data?.url
                          : iconData?.location
                        : iconData?.location,
                    portfolio:
                      contactData?.name == "portfolio"
                        ? data?.url
                          ? data?.url
                          : iconData?.portfolio
                        : iconData?.portfolio,
                    token: localStorage.getItem("token"),
                  })
                  .then((res) => {
                    getProfile();
                    setData({
                      name: "",
                      url: "",
                      phone: "",
                      location: "",
                      portfolio: "",
                    });
                    setIsOpen(!modalIsOpen);
                  })
                  .catch((err) => {});
              }
            }}
            className="bg-lightBlue block mx-auto mt-3 px-4 py-1 rounded-lg"
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ContactUpdate;
