import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Topbar from "../Components/Topbar";

import card from "../../images/tapkaro card.png";
import TapKaroContext from "../../context/Context";
import {
  AiOutlineDelete,
  AiOutlineFilter,
  AiOutlineSearch,
} from "react-icons/ai";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { CARD_URI } from "../../utils/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Card = () => {
  const [showFilter, setShowFilter] = useState(false);
  const history = useNavigate();
  const context = useContext(TapKaroContext);
  const [viewLoadMore, setViewLoadMore] = useState(false);

  useEffect(() => {
    context.getCards();
    setViewLoadMore(
      context?.cardsConfig?.size * context?.cardPage < context?.cardsConfig?.max
    );
  }, [context?.cardPage]);

  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[80vw] pt-6 px-6">
        <Topbar title={"Cards"} />
        <div>
          <div className="flex justify-between items-center mt-1 relative">
            <button
              onClick={(e) => {
                e.preventDefault();
                history("/admin/cards/addNew");
              }}
              className="bg-black text-white px-5 py-1 rounded-lg"
            >
              Add New +
            </button>
            <div className="flex items-center relative">
              <AiOutlineSearch size={30} />
              <input
                type="text"
                value={context.cardsConfig.search}
                onChange={(e) => {
                  context.setCardsConfig({
                    ...context.cardsConfig,
                    search: e.target.value,
                  });
                }}
                placeholder="Enter card name"
                className="border outline-none py-1 px-3 border-black rounded-lg mx-2 w-[15vw]"
              />
              <AiOutlineFilter
                size={30}
                onClick={(e) => {
                  e.preventDefault();
                  setShowFilter(!showFilter);
                }}
                className="cursor-pointer"
              />
              {showFilter ? (
                <div className="w-[15%] absolute right-0 top-[90%] rounded-md bg-black p-3">
                  <p value="Active" className="text-center py-0.5 Active">
                    Active
                  </p>
                  <p value="Disable" className="text-center py-0.5 Disable">
                    Disable
                  </p>
                  <p
                    value="Deactivate"
                    className="text-center py-0.5 Deactivate"
                  >
                    Deactivate
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="mt-4 h-[75vh] overflow-y-scroll flex flex-col items-center">
            {context?.cards?.map((e, i) => {
              return <Block data={e} key={i} />;
            })}
            {viewLoadMore ? (
              <button
                className="text-white bg-black px-4 py-0.5 rounded-lg"
                onClick={(e) => {
                  e.preventDefault();
                  context.setCardPage(context.cardPage + 1);
                }}
              >
                Load More
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const Block = ({ data }) => {
  const [status, setStatus] = useState(data?.status);
  const [priority, setPriority] = useState(data?.priority);
  const context = useContext(TapKaroContext);
  const history = useNavigate();

  useEffect(() => {
    setPriority(data?.priority);
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          history(`/admin/cards/edit/${data?._id}`);
        }}
        className="w-[98%] bg-adminlightBlue mb-2 rounded-xl p-3 flex items-center justify-between cursor-pointer"
      >
        <div className="flex items-center">
          <img
            src={data?.mainImg ? data?.mainImg : card}
            alt={data?.mainImg ? data?.mainImg : card}
            className="rounded-xl w-[150px]"
          />
          <div className="ml-3 text-lg">
            <p className="font-bold text-xl">
              {data?.title}:{" "}
              <span className="font-normal">{data.category}</span>{" "}
            </p>
            <select
              className={`outline-none px-4 mt-1 rounded-lg ${status}`}
              value={status}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                setStatus(e.target.value);
                axios
                  .put(`${CARD_URI}/updateStatus`, {
                    id: data._id,
                    status: e.target.value,
                  })
                  .then((response) => {
                    toast.success("Status changed successfully");
                  })
                  .catch((err) => {
                    toast.success("Internal server error");
                  });
              }}
            >
              <option value="Active" className="Active">
                Active
              </option>
              <option value="Disable" className="Disable">
                Disable
              </option>
            </select>
          </div>
        </div>
        <p className="text-lg w-[15%]">
          Price: <span>{data?.price}</span>{" "}
          <span className="ml-0.5 text-red-500 line-through">
            {data?.cutPrice}
          </span>
        </p>
        <select
          className={`outline-none px-4 mt-1 rounded-lg ${priority}`}
          value={priority}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            setPriority(e.target.value);
            axios
              .put(`${CARD_URI}/updatePriority`, {
                id: data._id,
                priority: e.target.value,
              })
              .then((response) => {
                toast.success("Priority changed successfully");
              })
              .catch((err) => {
                toast.success("Internal server error");
              });
          }}
        >
          <option value="Low" className="Low">
            Low
          </option>
          <option value="High" className="High">
            High
          </option>
        </select>
        <div>
          <AiOutlineDelete
            onClick={(e) => {
              e.stopPropagation();
              axios
                .delete(`${CARD_URI}/${data._id}`)
                .then((response) => {
                  context.getCards();
                })
                .catch((err) => {});
            }}
            size={30}
            className="cursor-pointer"
          />
        </div>
      </div>
    </>
  );
};

export default Card;
