import React, { useContext, useEffect, useRef, useState } from "react";

import location from "../../images/contact/location.png";
import url from "../../images/contact/url.png";
import mail from "../../images/contact/mail.png";
import call from "../../images/contact/call.png";
import facebook from "../../images/icons/facebook.png";
import linkedin from "../../images/icons/linkedin.png";
import instagram from "../../images/icons/instagram.png";
import youtube from "../../images/icons/youtube.png";
import whatsapp from "../../images/icons/whatsapp.png";
import plus from "../../images/icons/plus.png";
import paytm from "../../images/icons/paytm.png";
import gpay from "../../images/icons/google-pay.png";
import phonepe from "../../images/icons/phonepe.png";
import { useNavigate, useParams } from "react-router";
import QRCodeStyling from "qr-code-styling";
import obj from "../../Components/QR";
import twitter from "../../images/icons/twitter.png";
import calling from "../../images/icons/call.png";
import reviews from "../../images/icons/google-reviews.png";
import indiamart from "../../images/icons/indiamart.png";
import justdial from "../../images/icons/justdial.png";
import meesho from "../../images/icons/meesho.png";
import snapchat from "../../images/icons/snapchat.png";
import telegram from "../../images/icons/telegram.png";
import threads from "../../images/icons/threads.png";
import Footer from "../Components/Footer";
import ReactModal from "../Components/ReactModal";
import TapKaroContext from "../../context/Context";
import ContactUpdate from "../Components/ContactUpdate";
import { InfinitySpin } from "react-loader-spinner";

const Profile = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const history = useNavigate();
  const qr = useRef(null);
  const { id } = useParams();
  const {
    check,
    profile,
    getProfile,
    checkLoader,
    getProfileTaps,
    getConnectionTaps,
    getSocialIconsReport,
  } = useContext(TapKaroContext);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getProfile();
    } else {
      history("/login");
    }
    getProfileTaps();
    getSocialIconsReport();
    getConnectionTaps();
  }, []);

  const qrCode = new QRCodeStyling({
    width: 95,
    height: 95,
    ...obj,
    data: `${window.location.origin}/${profile?.username}`,
  });

  useEffect(() => {
    qrCode.update({
      data: `${window.location.origin}/${profile?.username}`,
    });
    if (!qr?.current?.innerHTML.includes("canvas")) {
      qrCode.append(qr.current);
    }
  }, [profile]);

  const [iconData, setIconData] = useState();
  const [contactData, setContactData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState(false);

  return (
    <>
      <ReactModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        iconData={iconData}
        type={type}
      />
      <ContactUpdate
        modalIsOpen={openModal}
        setIsOpen={setOpenModal}
        iconData={{
          phone: profile?.phone ? profile?.phone : "",
          location: profile?.location ? profile?.location : "",
          portfolio: profile?.portfolio ? profile?.portfolio : "",
          cCode: profile?.cCode ? profile?.cCode : 91,
        }}
        contactData={contactData}
      />
      {!profile?.coverPicture && !profile?.profilePicture ? (
        <div className="w-[100vw] h-[100vh] absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] backdrop-blur-md text-white text-3xl font-semibold flex-col flex justify-center items-center z-50">
          <InfinitySpin width="300" color="#fff" />
        </div>
      ) : null}
      {check !== true ? (
        <div className="w-[100vw] h-[100vh] absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] backdrop-blur-md text-white text-3xl font-semibold flex-col flex justify-center items-center z-50">
          {checkLoader ? (
            <InfinitySpin width="300" color="#fff" />
          ) : (
            <>
              <p className="text-center">
                Please Buy a card to activate profile
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  history("/shop");
                }}
                className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] px-6 font-semibold mobile:pt-2 mt-5 rounded-lg py-1.5 text-xl"
              >
                Shop Now
              </button>
            </>
          )}
        </div>
      ) : null}
      <div
        className={`text-white ${
          check !== true
            ? "overflow-hidden w-[100vw] h-[100vh]"
            : "overflow-y-auto w-full h-full"
        }`}
      >
        <div
          style={{
            backgroundImage: `url(${profile?.coverPicture})`,
          }}
          className="bg-blend-color-dodge h-[40vh] bg-no-repeat bg-cover bg-center flex justify-end flex-col"
        >
          <div className="w-[80%] flex items-end m-3 -mb-20">
            <img
              src={profile?.profilePicture}
              alt="user"
              className="w-[30vw] h-[30vw] object-cover object-center rounded-lg"
            />
            <div className="ml-3">
              <h1 className="text-[18px] font-semibold">{profile?.name}</h1>
              <h1 className="text-sm">{profile?.designation}</h1>
              <h1 className="text-sm">{profile?.company}</h1>
            </div>
          </div>
        </div>
        <div
          className="h-fit bg-fbg bg-cover pt-8 -mt-5"
          style={{
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
          }}
        >
          <div
            className="w-full h-full pt-20"
            style={{
              borderTopLeftRadius: "1rem",
              borderTopRightRadius: "1rem",
            }}
          >
            <div className="w-[16%] bg-white h-[2px] mx-auto rounded-md"></div>
            <button
              onClick={(e) => {
                history(`/user/edit/`);
              }}
              className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] w-[50%] m-auto block py-0.5 my-3 rounded-lg"
            >
              Edit Profile
            </button>
            <div className="w-[98%] mt-2 rounded-2xl flex items-center justify-evenly py-4 px-1">
              <ContactIcons
                img={call}
                modalIsOpen={openModal}
                setIsOpen={setOpenModal}
                setContactData={setContactData}
                data={{
                  name: "phone",
                  url: profile?.phone,
                  cCode: profile?.cCode ? profile?.cCode : 91,
                }}
              />
              <ContactIcons
                img={mail}
                modalIsOpen={openModal}
                setIsOpen={setOpenModal}
                setContactData={setContactData}
                data={{ name: "mail", url: profile?.email }}
              />
              <ContactIcons
                img={location}
                modalIsOpen={openModal}
                setIsOpen={setOpenModal}
                setContactData={setContactData}
                data={{ name: "location", url: profile?.location }}
              />
              <ContactIcons
                img={url}
                modalIsOpen={openModal}
                setIsOpen={setOpenModal}
                setContactData={setContactData}
                data={{ name: "portfolio", url: profile?.portfolio }}
              />
            </div>
            <div className="px-3 mx-auto">
              <div className="grid grid-cols-4 mt-1.5 mx-auto">
                {profile?.icons
                  ?.filter((e) => {
                    return e?.type?.toLowerCase().includes("social");
                  })
                  .map((e, i) => {
                    return (
                      <Icons
                        key={i}
                        modalIsOpen={modalIsOpen}
                        setIsOpen={setIsOpen}
                        iconData={iconData}
                        setIconData={setIconData}
                        img={
                          e?.name?.toLowerCase().includes("face")
                            ? facebook
                            : e?.name?.toLowerCase().includes("insta")
                            ? instagram
                            : e?.name?.toLowerCase().includes("what")
                            ? whatsapp
                            : e?.name?.toLowerCase().includes("you")
                            ? youtube
                            : e?.name?.toLowerCase().includes("twitter")
                            ? twitter
                            : e?.name?.toLowerCase().includes("linkedin")
                            ? linkedin
                            : e?.name?.toLowerCase().includes("call")
                            ? calling
                            : e?.name?.toLowerCase().includes("reviews")
                            ? reviews
                            : e?.name?.toLowerCase().includes("justdial")
                            ? justdial
                            : e?.name?.toLowerCase().includes("meesho")
                            ? meesho
                            : e?.name?.toLowerCase().includes("snapchat")
                            ? snapchat
                            : e?.name?.toLowerCase().includes("telegram")
                            ? telegram
                            : e?.name?.toLowerCase().includes("threads")
                            ? threads
                            : indiamart
                        }
                        data={e}
                      />
                    );
                  })}
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setType(false);
                  }}
                >
                  <Icons
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                    img={plus}
                    iconData={iconData}
                    setIconData={setIconData}
                    padding={true}
                  />
                </div>
              </div>
              <div className="grid grid-cols-4 mt-1.5 mx-auto">
                {profile?.icons
                  ?.filter((e) => {
                    return e?.type?.toLowerCase().includes("upi");
                  })
                  .map((e, i) => {
                    return (
                      <Icons
                        key={i}
                        modalIsOpen={modalIsOpen}
                        setIsOpen={setIsOpen}
                        iconData={iconData}
                        setIconData={setIconData}
                        img={
                          e?.name?.toLowerCase().includes("paytm")
                            ? paytm
                            : e?.name?.toLowerCase().includes("phonepe")
                            ? phonepe
                            : gpay
                        }
                        data={e}
                      />
                    );
                  })}
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setType(true);
                  }}
                >
                  <Icons
                    iconData={iconData}
                    setIconData={setIconData}
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                    img={plus}
                    padding={true}
                  />
                </div>
              </div>
            </div>
            <div
              className="border mx-5 py-2 px-3 rounded-lg flex cursor-pointer"
              onClick={(e) => {
                history(`/user/view-qr/`);
              }}
            >
              <div ref={qr} className="bg-white w-fit p-0.5 rounded-lg"></div>
              <p className="text-xl ml-3">
                Tap To
                <br />
                Open Your QR
              </p>
            </div>
            <div className="flex justify-end w-full mt-4 px-4">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`/${profile?._id}`, "_blank");
                }}
                className="bg-green-500 px-4 py-1 rounded-lg mr-3"
              >
                Preview
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  localStorage.setItem("token", "");
                  history("/");
                }}
                className="bg-red-500 px-4 py-1 rounded-lg"
              >
                Logout
              </button>
            </div>
            <div className="h-[13vh]"></div>
          </div>
          <Footer id={id} />
        </div>
      </div>
    </>
  );
};

const ContactIcons = ({
  img,
  modalIsOpen,
  setIsOpen,
  setContactData,
  data,
}) => {
  return (
    <div
      onClick={(e) => {
        if (data) {
          e.preventDefault();
          setIsOpen(!modalIsOpen);
          setContactData(data);
        }
      }}
      className={`bg-[rgba(255,255,255,0.2)] mobile:p-1 md:p-3 md:w-[4vw] mobile:w-[20vw] mobile:h-[20vw] md:h-[4vw] backdrop-blur-md rounded-full flex justify-center items-center cursor-pointer`}
    >
      <img src={img} alt={img} />
    </div>
  );
};

const Icons = ({ img, padding, modalIsOpen, setIsOpen, data, setIconData }) => {
  return (
    <div
      className={` ${
        padding ? "p-4 bg-[rgba(255,255,255,0.2)]" : "p-0"
      } rounded-lg cursor-pointer mb-3 mx-1.5`}
      onClick={(e) => {
        e.preventDefault();
        setIsOpen(!modalIsOpen);
        setIconData(data);
      }}
    >
      <img src={img} alt={img} />
    </div>
  );
};

export default Profile;
