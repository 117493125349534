import React from "react";
import logo from "../../images/tap karo logo.png";
import { useLocation, useNavigate } from "react-router";
import { AiFillHome, AiFillSetting, AiOutlineLogout } from "react-icons/ai";

const Sidebar = () => {
  const location = useLocation();
  const history = useNavigate();

  return (
    <div className="flex flex-col w-[15vw] h-[100vh] items-center bg-gradient-to-b from-lightBlue to-lightNavyBlue">
      <img
        src={logo}
        alt={logo}
        className="w-[50%] my-6 cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          history("/");
        }}
      />
      <div className="flex flex-col items-center text-lg w-full mt-6 text-white">
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1 flex items-center justify-center ${
            location.pathname === "/bussiness"
              ? "bg-[rgba(0,0,0,0.3)] font-semibold"
              : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            history("/bussiness");
          }}
        >
          <AiFillHome className="mr-1" />
          Home
        </p>
      </div>
      <div className="flex flex-col items-center text-lg w-full text-white">
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1 flex items-center justify-center ${
            location.pathname === "/bussiness/settings"
              ? "bg-[rgba(0,0,0,0.3)] font-semibold"
              : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            history("/bussiness/settings");
          }}
        >
          <AiFillSetting className="mr-1" />
          Settings
        </p>
      </div>
      <div className="flex flex-col items-center text-lg w-full text-white">
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1 flex items-center justify-center`}
          onClick={(e) => {
            e.preventDefault();
            localStorage.setItem("bussiness_token", "");
            history("/");
          }}
        >
          <AiOutlineLogout className="mr-1" />
          Logout
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
