import React from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const details = [
    {
      title: "Website Visitors",
      value:
        "Likewise all other operators, we collect non-personally-identifying information which analytics provide to us such as browser type, language preference, referring site, and the date and time of each visitor request. Our purpose for collecting non-personally identifying information is to better understand how visitors use this website. From time to time, we may release non-personally-identifying information in the aggregate. This website also collects potentially personally-identifying information like Internet Protocol (IP) addresses.",
    },
    {
      title: "Collecting (PII) Personally Identifying Information",
      value:
        "Certain visitors to this website choose to interact with it in ways that requires the website to gather personally-identifying information. The amount and type of information that the website gathers depends on the nature of the interaction. In each case, this website collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor’s interaction. The wesbite does not share any personally-identifying information to any channel in any way. Only visitor has consent to provide his/her information to the website for future website related updates or services.",
    },
    {
      title: "Protection of Certain (PII) Personally Identifying Information",
      value:
        "This website will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than the persons and affiliated organizations, as described above, this website discloses potentially personally-identifying and personally-identifying information only when required to do so by law, or when this website believes in good faith that disclosure is reasonably necessary to protect the property or rights of this website, third parties, or the public at large.",
    },
    {
      title: "Cookies",
      value:
        "This website uses cookies to help the website identify and track visitors, their usage of the website, and their website access preferences. Visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using this website, with the drawback that certain features of this website may not function properly without the aid of cookies.",
    },
    {
      title: "Privacy Policy Changes",
      value:
        "Although most changes are likely to be minor, this website may change its Privacy Policy from time to time, and in the website’s sole discretion. We encourage visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.",
    },
  ];
  return (
    <div className="overflow-x-hidden">
      <Nav />
      <div className="h-fit bg-navyBlue text-white mobile:px-[4vh] mobile:py-[13vh] md:px-[14vh]  md:py-[14vh]">
        <div className="md:w-[65%] mobile:w-full">
          <h1 className="text-3xl font-bold mb-3">Privacy Policy</h1>
          <div className="mobile:pl-1 md:pl-3">
            <p className="mb-1">
              1. We will never ask you for any of your personal details.
            </p>
            <p className="mb-1">
              2. We will never share any of your personal details if you provide
              us your information while visiting our site
            </p>
            <p className="mb-1">
              3. We avoid storing personal information on our servers unless
              required for the on-going project for which you shared your
              details.
            </p>
            {details.map((e, i) => {
              return <Block data={e} key={i} />;
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Block = ({ data }) => {
  return (
    <div className="py-3">
      <h1 className="font-semibold text-lg">{data?.title}</h1>
      <p className="text-gray-200">{data?.value}</p>
    </div>
  );
};

export default PrivacyPolicy;
