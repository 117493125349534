import React, { useEffect } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";

const TermsAndCondition = () => {
  const details = [
    {
      title: "Eligibility to use our site",
      value: `Use of the Site is available only to persons who can legally enter into contracts under 
        applicable laws. Persons who are "incompetent to contract", within the meaning of 
        the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible 
        to use the Site. “tapkaro.com” reserves the right to terminate your access to the Site 
        if it discovers that you are under the age of 18 years or suffers from any other 
        disability, as recognised under Indian Contract Act, 1872.`,
    },
    {
      title: "Membership",
      value: `Although it's not essential to have an account to shop with “tapkaro.com”, you can 
        shop as a guest. As a member, you agree to provide true, accurate, current, and 
        complete information about yourself as prompted by the site's registration form. 
        Registration where prohibited under any law shall be void. “tapkaro.com” reserves 
        the right to revoke or terminate your registration for any reason at any time, without 
        notice.`,
    },
    {
      title: "Protection of Certain (PII) Personally Identifying Information",
      value:
        "This website will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than the persons and affiliated organizations, as described above, this website discloses potentially personally-identifying and personally-identifying information only when required to do so by law, or when this website believes in good faith that disclosure is reasonably necessary to protect the property or rights of this website, third parties, or the public at large.",
    },
    {
      title: "Electronic Communications",
      value: `When you use the site or send emails or other data, information or communicate to 
        us, you agree and understand that you are communicating with us electronically and 
        give your consent to receive communications electronically from us periodically, 
        when required.`,
    },
    {
      title: "Reviews, Feedback, Submissions",
      value: `All reviews, comments, feedback, postcards, suggestions, ideas, and other 
        submissions disclosed, submitted or offered to “tapkaro.com” directly or otherwise 
        disclosed, submitted or offered in connection with your use of this Site (collectively 
        referred to "Comments") will remain “tapkaro.com” property. Such disclosure, 
        submission or offer of any comments shall constitute an assignment to “tapkaro.com” 
        of all worldwide rights, titles and interests in all copyrights and other intellectual 
        properties in the comments, thus, it exclusively owns all such rights, titles and 
        interests and shall not be limited in any way in its use, commercial or otherwise. 
        “tapkaro.com” will be entitled to use, reproduce, disclose, modify, adapt, create derivative works from, publish, display and distribute any comments you submit for any purpose whatsoever, without restriction and without compensating you in any 
        way. || “tapkaro.com” is and shall be under no obligation (1) to maintain any Comments 
        in confidence; or (2) to pay you any compensation for any Comments; or (3) to 
        respond to any Comments. You agree that any comments submitted by you to the 
        Site will not violate this policy or any right of any third party, including copyright, 
        trademark, privacy or other personal or proprietary right(s), and will not cause injury 
        to any person or entity. You further agree that no comments submitted by you to the 
        site will be libelous or otherwise unlawful, threatening, abusive or obscene material, 
        or contain software viruses, political campaigning, commercial solicitation, chain 
        letters, mass mailings or any form of "spam". “tapkaro.com” does not regularly review posted comments, but does reserve the right 
        (but not the obligation) to monitor and edit or remove any comment submitted to the 
        Site. You grant “tapkaro.com” the right to use the name that you submit in connection 
        with any of the posted comments. You agree not to use a false email address, 
        impersonate any person or entity, or otherwise mislead as to the origin of any 
        Comments you submit. You are and shall remain solely responsible for the content of 
        any comments you make and you agree to indemnify “tapkaro.com” and its affiliates 
        for all claims resulting from any Comments you submit, we take no responsibility and 
        assume no liability for any comments submitted by you or any third party.`,
    },
    {
      title: "Accuracy of Content/ Information of Products on the Web Site",
      value: `While “tapkaro.com” strives to provide accurate product and pricing information, 
      typographical errors may occur. In the event that a product is listed at an incorrect 
      price or with incorrect information due to an error in pricing or product information, 
      “tapkaro.com” shall have the right, on our sole discretion, to modify the price of the 
      products, or information of the products or to refuse or cancel any orders placed for 
      that product, unless the product has already been dispatched. In the event.`,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Nav />
      <div className="h-fit bg-navyBlue text-white mobile:px-[4vh] mobile:py-[13vh] md:px-[14vh]  md:py-[14vh]">
        <div className="md:w-[65%] mobile:w-full">
          <h1 className="text-3xl font-bold mb-3">Terms and Conditions</h1>
          <div className="mobile:pl-1 md:pl-3">
            <p className="mb-1">
              Thank you for accessing/shopping at tapkaro.com. This site is
              owned by In Out Green (hereinafter referred to as “tapkaro.com”).
              By accessing, shopping on this site, you indicate your
              unconditional acceptance of these terms & conditions. We reserve
              this right, in our sole discretion, to update or revise these
              terms & conditions. Continued use of the site following the
              posting of any changes to the ‘terms & conditions’, constitutes
              your acceptance of those changes. At “tapkaro.com”, we try our
              best to create a space where you can explore and shop for all your
              favourite things in a safe and secure environment. All products
              and information displayed on “- tapkaro.com” constitutes an
              "invitation to offer". “tapkaro.com” reserves the right to accept
              or reject your offer. Your order for purchase, constitutes your
              "offer" which shall be subject to the terms and conditions as
              listed below.
            </p>
            {details.map((e, i) => {
              return <Block data={e} key={i} index={i} />;
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Block = ({ data, index }) => {
  return (
    <div className="py-3">
      <h1 className="font-semibold text-lg">
        {index + 1}. {data?.title}
      </h1>
      {data?.value.split("||").map((e, i) => {
        return (
          <div key={i}>
            <p className="text-gray-200">{e}</p>
            {data?.value.split("||").length > 1 ? <br /> : null}
          </div>
        );
      })}
    </div>
  );
};

export default TermsAndCondition;
