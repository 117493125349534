import React, { useEffect } from "react";
import { FaRupeeSign } from "react-icons/fa";

import ellipse7 from "../../images/Ellipse 7.png";
import ellipse8 from "../../images/Ellipse 8.png";
import ellipse9 from "../../images/Ellipse 9.png";
import ellipse11 from "../../images/Ellipse 11.png";

import logo1 from "../../images/Final logos/AngelOne RGB Logo for TK.png";
import logo2 from "../../images/Final logos/KP_logo_WIth_R-removebg-preview.png";
import logo3 from "../../images/Final logos/advance_valves_logo-removebg-preview.png";
import logo4 from "../../images/Final logos/agarwal pam.png";
import logo5 from "../../images/Final logos/dhaani_creations_logo-removebg-preview.png";
import logo6 from "../../images/Final logos/exotic_goa_guides-removebg-preview.png";
import logo7 from "../../images/Final logos/fargo_travels_logo-removebg-preview.png";
import logo8 from "../../images/Final logos/flight_calls_logo-removebg-preview.png";
import logo9 from "../../images/Final logos/mr_multicuisine-removebg-preview.png";
import logo10 from "../../images/Final logos/the_smart_cube_logo-removebg-preview.png";
import logo11 from "../../images/Final logos/wellnesspoint logo.png";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { BsGlobeAmericas, BsFillPeopleFill, BsTree } from "react-icons/bs";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

const Testimonials = () => {
  const images = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
  ];

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.fromTo(
      "#testCircles",
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggerElement",
          start: "top 40%",
          end: "top 0%",
          // scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#testSqures",
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggerSqure",
          start: "top 65%",
          end: "top 30%",
          // scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      "#ellipse8",
      {
        width: 0,
        opacity: 0,
      },
      {
        width: 200,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggerElement",
          start: "top 40%",
          end: "top 0%",
          // scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#ellipse9",
      {
        width: 0,
        opacity: 0,
      },
      {
        width: 200,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggerSqure",
          start: "top 35%",
          end: "top 0%",
          // scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#ellipse11",
      {
        width: 0,
        opacity: 0,
      },
      {
        width: 200,
        opacity: 1,
        scrollTrigger: {
          trigger: "#scrollBanner",
          start: "top 65%",
          end: "top 10%",
          // scrub: true,
        },
      }
    );
  }, []);

  const reviews = [
    {
      name: "Rishab",
      img: "https://images.pexels.com/photos/4307693/pexels-photo-4307693.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "The best part like about this, Tap Karo card worked without downloading any application. ",
    },
    {
      name: "Ankur Pandey",
      img: "https://images.pexels.com/photos/9271168/pexels-photo-9271168.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "This is what I was looking for. This solved all my problems of sharing complete profile all at once.",
    },
    {
      name: "Vishwesh",
      img: "https://images.pexels.com/photos/12311514/pexels-photo-12311514.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "I do not save number by typing now. Just tap on phone and download cnotacts. That is so easy to connect now.",
    },
    {
      name: "Promila Bhardwaj",
      img: "https://images.pexels.com/photos/1844012/pexels-photo-1844012.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "My instagram Tap Karo card helped me to gain followers. That has become my things to carry along with now.",
    },
  ];

  return (
    <div
      id="triggerElement"
      className="h-fit bg-[#091628] relative text-white overflow-clip z-0"
    >
      <img
        src={ellipse7}
        alt={ellipse7}
        className="absolute top-[30%] right-0 w-[10%] -z-10"
      />
      <img
        id="ellipse8"
        src={ellipse8}
        alt={ellipse8}
        className="absolute -top-5 right-0 -z-10"
      />
      <img
        id="ellipse9"
        src={ellipse9}
        alt={ellipse9}
        className="absolute top-[30%] left-0 -z-10"
      />
      <img
        id="ellipse11"
        src={ellipse11}
        alt={ellipse11}
        className="absolute -bottom-12 right-0 -z-10"
      />
      <div
        id="testCircles"
        className="offScroll flex overflow-x-scroll justify-between z-10 pt-[8vw] md:px-[8vw] mobile:mx-[3vw] md:mx-[2.5vw]"
      >
        <RoundedCircle
          element={<FaRupeeSign size={window.innerWidth < 550 ? 50 : 60} />}
          number={"10K"}
          title={"Sold and Growing"}
        />
        <RoundedCircle
          element={<BsGlobeAmericas size={window.innerWidth < 550 ? 50 : 60} />}
          number={89}
          title={"Countries Using"}
        />
        <RoundedCircle
          element={
            <BsFillPeopleFill size={window.innerWidth < 550 ? 50 : 60} />
          }
          number={"1M"}
          title={"Connected"}
        />
        <RoundedCircle
          element={<BsTree size={window.innerWidth < 550 ? 50 : 60} />}
          number={1000}
          title={"Trees Saved"}
        />
      </div>
      {window.innerWidth < 550 ? (
        <div
          id="triggerSqure"
          className="flex flex-col justify-evenly mobile:mt-12 md:my-24"
        >
          <h1 className="text-center text-4xl mobile:pb-3 md:pb-0 font-semibold">
            Our Proud Tappers
          </h1>
          <div
            id="testSqures"
            className="flex md:items-center w-[100vw] mobile:h-[45vh] md:h-[40vh] mobile:mt-2 md:mt-5 md:mx-[10vw] md:justify-between mobile:mx-[5vw]"
          >
            <Carousel
              showArrows={true}
              showThumbs={false}
              autoPlay={true}
              infiniteLoop={true}
              interval={5000}
              swipeable={true}
              emulateTouch={true}
            >
              {reviews.map((e, i) => {
                return <TestMobile data={e} key={i} />;
              })}
            </Carousel>
          </div>
        </div>
      ) : (
        <div
          id="triggerSqure"
          className="h-[50vh] flex flex-col justify-evenly mobile:mt-12 md:my-24"
        >
          <h1 className="text-center text-4xl mobile:pb-3 md:pb-0 font-semibold">
            Our Proud Tappers
          </h1>
          <div
            id="testSqures"
            className="md:flex mobile:grid mt-3 mobile:grid-cols-2 md:gap-10 mobile:gap-4 justify-between mobile:px-4 md:px-[8vw] mobile:mx-0 md:mx-[2vw] h-[45vh] z-50"
          >
            {reviews.map((e, i) => {
              return <TestDesktop data={e} key={i} />;
            })}
          </div>
        </div>
      )}
      <div
        id="scrollBanner"
        className="bg-[rgba(10,10,10,0.2)] h-[20vh] backdrop-blur-md flex items-center mobile:mt-36 md:mt-0"
      >
        <Banner images={images} speed={20000} />
      </div>
    </div>
  );
};

const TestMobile = ({ data }) => {
  return (
    <div className="offScroll bg-gradient-to-tr from-[#fb00ff70] via-[#00b3b6] to-[#ffffff96] flex flex-col items-center justify-center md:w-[18vw] mobile:w-[90vw] border mobile:h-[45vh] mobile:px-1 md:px-0 md:h-full rounded-3xl mobile:opacity-100 cursor-pointer md:opacity-60 hover:opacity-100">
      <div className="md:w-[7vw] md:h-[7vw] mobile:w-[35vw] mobile:h-[35vw]">
        <img
          src={data?.img}
          alt="Default"
          className="rounded-full w-full h-full object-cover object-center"
        />
      </div>
      <h1 className="text-2xl mt-1 font-semibold text-center">{data?.name}</h1>
      <p className="text-center text-lg px-3">{data?.desc}</p>
    </div>
  );
};

const TestDesktop = ({ data }) => {
  return (
    <div className="transitionAddKro backdrop-blur-2xl bg-gradient-to-tr from-[#fb00ff70] via-[#00b3b6] to-[#ffffff96] md:w-[22%] h-[45vh] mobile:h-full rounded-3xl flex flex-col items-center mobile:opacity-100 md:opacity-60 hover:opacity-100 cursor-pointer md:py-3 mobile:py-3 z-30 overflow-y-scroll">
      <img
        src={data?.img}
        alt="Default"
        className="rounded-full mobile:w-[20vw] md:w-[7vw] mobile:h-[20vw] md:h-[7vw] object-cover object-center"
      />
      <h1 className="text-xl mt-1 font-semibold text-center">{data?.name}</h1>
      <p className="text-center px-3">{data?.desc}</p>
    </div>
  );
};

const Banner = ({ images, speed }) => {
  return (
    <div className={`inner mobile:h-[15vh] md:h-[10vh]`}>
      <div className="wrapper">
        <Section images={images} speed={speed} />
        <Section images={images} speed={speed} />
        <Section images={images} speed={speed} />
        <Section images={images} speed={speed} />
      </div>
    </div>
  );
};

const Section = ({ speed, images }) => {
  return (
    <section
      style={{ "--speed": `${speed}ms` }}
      className={`mobile:w-[600vw] md:w-[250vw]`}
    >
      {images.map((image, id) => (
        <div key={id} className={`md:mx-12 mobile:mx-4`}>
          <img
            src={image}
            alt={id}
            className="mobile:h-[13vh] md:h-[10vh] w-full object-cover object-center"
          />
        </div>
      ))}
    </section>
  );
};

const RoundedCircle = ({ element, number, title }) => {
  return (
    <div className="flex flex-col items-center mobile:mt-8 md:mt-0">
      <div className="bg-gradient-to-tr from-[#fb00ff70] via-[#00b3b6] to-[#ffffff96] md:w-[13.5vw] md:h-[13.5vw] mobile:w-[30vw] mobile:h-[30vw] rounded-full flex flex-col justify-center items-center mobile:opacity-100 md:opacity-60 hover:opacity-100 cursor-pointer mobile:mx-7 md:mx-0">
        {element}
        <p className="md:text-2xl mobile:text-xl font-medium mobile:mt-0.5 md:mt-2">
          {number} +
        </p>
      </div>
      <p className="mt-3 text-gray-200 text-center">{title}</p>
    </div>
  );
};

export default Testimonials;
