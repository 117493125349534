import React, { useEffect, useRef, useState } from "react";
import fileDownload from "js-file-download";
import Axios from "axios";
import location from "../../../images/contact/location.png";
import url from "../../../images/contact/url.png";
import mail from "../../../images/contact/mail.png";
import call from "../../../images/contact/call.png";
import facebook from "../../../images/icons/facebook.png";
import linkedin from "../../../images/icons/linkedin.png";
import instagram from "../../../images/icons/instagram.png";
import youtube from "../../../images/icons/youtube.png";
import whatsapp from "../../../images/icons/whatsapp.png";
import paytm from "../../../images/icons/paytm.png";
import gpay from "../../../images/icons/google-pay.png";
import phonepe from "../../../images/icons/phonepe.png";
import twitter from "../../../images/icons/twitter.png";
import { useNavigate, useParams } from "react-router";
import QRCodeStyling from "qr-code-styling";
import { AiOutlineDownload, AiOutlineUserAdd } from "react-icons/ai";
import AddConnection from "../../Components/AddConnection";
import { BASE_URI, PROFILE_URI } from "../../../utils";
import axios from "axios";
import obj from "../../../Components/QR";
import calling from "../../../images/icons/call.png";
import reviews from "../../../images/icons/google-reviews.png";
import indiamart from "../../../images/icons/indiamart.png";
import justdial from "../../../images/icons/justdial.png";
import meesho from "../../../images/icons/meesho.png";
import snapchat from "../../../images/icons/snapchat.png";
import telegram from "../../../images/icons/telegram.png";
import threads from "../../../images/icons/threads.png";
import Helmet from "react-helmet";
import ShowLocation from "../../Components/ShowLocation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewProfileMobile = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const history = useNavigate();
  const qr = useRef(null);
  const { id } = useParams();
  const [user, setUser] = useState();
  const [count, setcount] = useState(0);

  const fetchData = async () => {
    try {
      const resp = await axios.get("https://api64.ipify.org?format=json");
      let ip = resp.data.ip;
      if (ip !== null && ip !== undefined && ip.length > 1) {
        axios
          .post(`${BASE_URI}unauthorized-user/addTap/${id}`, {
            location: ip,
          })
          .then((res) => {
            setcount(1);
          })
          .catch((err) => {});
      } else {
        fetchData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    axios
      .get(`${PROFILE_URI}/${id}`)
      .then((res) => {
        setUser(res.data);
        if (res.data?.email) {
        } else {
          localStorage.setItem("id", id);
          history("/register");
        }
      })
      .catch((err) => {
        history("/");
      });
  }, [id]);

  const qrCode = new QRCodeStyling({
    width: 110,
    height: 110,
    ...obj,
    data: `${window.location.origin}/${id}`,
  });

  useEffect(() => {
    qrCode.update({
      data: `${window.location.origin}/${id}`,
    });
    if (!qr?.current?.innerHTML.includes("canvas")) {
      qrCode.append(qr.current);
    }
  }, [id]);

  const genrateVcfCard = () => {
    axios
      .post(`${BASE_URI}unauthorized-user/addProfileSave/${id}`)
      .then((res) => {})
      .catch((err) => {});

    // Details
    let uid = id;
    let firstName = user?.name?.includes(" ")
      ? user?.name.split(" ")[0]
      : user?.name;
    let lastName = user?.name?.includes(" ") ? user?.name.split(" ")[1] : "";
    let organization = user?.company;
    let role = user?.designation;
    let email = user?.email;

    let phone = user?.icons?.find((e) =>
      e?.name?.toLowerCase()?.includes("office")
    );

    let landline = user?.icons?.find((e) =>
      e?.name?.toLowerCase()?.includes("landline")
    );

    let cellPhone =
      "+" +
      (user?.cCode?.toString() ? user?.cCode?.toString() : "91") +
      user?.phone?.toString();

    let homePhone =
      "+" +
      (landline?.cCode?.toString() ? landline?.cCode?.toString() : "91") +
      +landline?.url;

    let workPhone =
      "+" +
      (user?.cCode?.toString() ? user?.cCode?.toString() : "91") +
      phone?.url;

    let url = `https://tapkaro.com/${id}`;
    let homeAddress = user?.location;

    let facebookIcon = user?.icons?.find((e) =>
      e?.name?.toLowerCase()?.includes("facebook")
    );
    let facebook = facebookIcon?.url;

    let linkedinIcon = user?.icons?.find((e) =>
      e?.name?.toLowerCase()?.includes("link")
    );
    let linkedin = linkedinIcon?.url;

    const vCardData = `BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${firstName}
N;CHARSET=UTF-8:${lastName};${firstName};;;
UID;CHARSET=UTF-8:${uid}
EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${email}
${
  !cellPhone?.includes(undefined) && !cellPhone.includes(NaN)
    ? `TEL;TYPE=CELL:${cellPhone}`
    : ""
}
${
  !homePhone?.includes(undefined) &&
  !cellPhone.includes(NaN) &&
  landline?.visibility
    ? `TEL;TYPE=HOME,VOICE:${homePhone}`
    : ""
}
${
  !workPhone?.includes(undefined) &&
  !cellPhone.includes(NaN) &&
  phone?.visibility
    ? `TEL;TYPE=WORK,VOICE:${workPhone}`
    : ""
}
${homeAddress ? `ADR;CHARSET=UTF-8;TYPE=HOME:${homeAddress}` : ""}
TITLE;CHARSET=UTF-8:${role}
ORG;CHARSET=UTF-8:${organization}
${user?.portfolio ? `URL;CHARSET=UTF-8:${user?.portfolio}` : url}
${
  facebook && facebookIcon.visibility
    ? `X-SOCIALPROFILE;TYPE=facebook:${facebook}`
    : ""
}
${
  linkedin && linkedinIcon.visibility
    ? `X-SOCIALPROFILE;TYPE=linkedin:${linkedin}`
    : ""
}
REV:${new Date()?.toISOString()}
END:VCARD`;

    try {
      const url =
        "data:text/x-vcard;charset=utf-8," + encodeURIComponent(vCardData);
      // window.location.href = url;
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `${firstName}.vcf`);
      link.click();
      toast.success("Vcf card downloaded successfully");
    } catch (error) {
      console.error("Error generating or opening vCard:", error);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AddConnection modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <Helmet>
        <title>Tap Karo Digital Business Card</title>
      </Helmet>
      <div className="w-[100vw] border border-black h-fit text-white overflow-y-scroll">
        <div
          style={{
            backgroundImage: `url(${user?.coverPicture})`,
          }}
          className="bg-blend-color-dodge h-[40vh] flex justify-end flex-col bg-no-repeat bg-cover bg-center"
        >
          <div className="w-[80%] flex items-end m-3 -mb-20">
            {/* -mb-14 */}
            <img
              src={user?.profilePicture}
              alt="user"
              className="w-[30vw] h-[30vw] object-cover object-center rounded-lg"
            />
            <div className="ml-3">
              <h1 className="text-[18px] font-bold">{user?.name}</h1>
              <h1 className="text-sm font-medium">{user?.designation}</h1>
              <h1 className="text-sm font-medium">{user?.company}</h1>
            </div>
          </div>
        </div>
        <div
          className="h-fit min-h-[67vh] bg-fbg bg-cover pt-8 -mt-5" // -mt-5
          style={{
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
          }}
        >
          <div
            style={{
              borderTopLeftRadius: "1rem",
              borderTopRightRadius: "1rem",
            }}
            className="w-full h-full py-3 pt-20"
          >
            <div className="w-[16%] bg-white h-[2px] mx-auto rounded-md"></div>
            <div className="flex items-center justify-center w-full">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  genrateVcfCard();
                }}
                className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] w-[40%] py-0.5 my-3 rounded-lg flex items-center justify-center text-sm"
              >
                Save Contact <AiOutlineDownload className="ml-2" size={18} />
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(!modalIsOpen);
                }}
                className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] ml-2 w-[40%] py-0.5 my-3 rounded-lg flex items-center justify-center text-sm"
              >
                Send Contact <AiOutlineUserAdd className="ml-2" size={18} />
              </button>
            </div>
            <div className="w-[98%] mt-2 rounded-2xl flex items-center justify-evenly py-4 px-1">
              <ContactIcons
                name={"call"}
                img={call}
                url={user?.phone}
                cCode={user?.cCode}
              />
              <ContactIcons name={"mail"} img={mail} url={user?.email} />
              <ContactIcons
                name={"location"}
                img={location}
                url={user?.location}
              />
              <ContactIcons name={"url"} img={url} url={user?.portfolio} />
            </div>
            <div className="px-3">
              <div className="grid grid-cols-4 mt-1.5">
                {user?.icons
                  ?.filter((e) => {
                    return (
                      e?.type?.toLowerCase().includes("social") &&
                      e?.visibility === true
                    );
                  })
                  .map((e) => {
                    return (
                      <Icons
                        img={
                          e?.name?.toLowerCase().includes("face")
                            ? facebook
                            : e?.name?.toLowerCase().includes("insta")
                            ? instagram
                            : e?.name?.toLowerCase().includes("what")
                            ? whatsapp
                            : e?.name?.toLowerCase().includes("you")
                            ? youtube
                            : e?.name?.toLowerCase().includes("twitter")
                            ? twitter
                            : e?.name?.toLowerCase().includes("linkedin")
                            ? linkedin
                            : e?.name?.toLowerCase().includes("call")
                            ? calling
                            : e?.name?.toLowerCase().includes("reviews")
                            ? reviews
                            : e?.name?.toLowerCase().includes("justdial")
                            ? justdial
                            : e?.name?.toLowerCase().includes("meesho")
                            ? meesho
                            : e?.name?.toLowerCase().includes("snapchat")
                            ? snapchat
                            : e?.name?.toLowerCase().includes("telegram")
                            ? telegram
                            : e?.name?.toLowerCase().includes("threads")
                            ? threads
                            : indiamart
                        }
                        data={e}
                      />
                    );
                  })}
              </div>
              <div className="grid grid-cols-4 mt-1.5">
                {user?.icons
                  ?.filter((e) => {
                    return (
                      e?.type?.toLowerCase().includes("upi") &&
                      e?.visibility === true
                    );
                  })
                  .map((e) => {
                    return (
                      <Icons
                        img={
                          e?.name?.toLowerCase().includes("paytm")
                            ? paytm
                            : e?.name?.toLowerCase().includes("phonepe")
                            ? phonepe
                            : gpay
                        }
                        data={e}
                      />
                    );
                  })}
              </div>
            </div>
            <div
              className="border mx-5 py-2 px-3 rounded-lg flex cursor-pointer"
              onClick={(e) => {
                history(`/qr/${id}`);
              }}
            >
              <div ref={qr} className="bg-white w-fit p-0.5 rounded-lg"></div>
              <p className="text-xl ml-3">
                Tap To
                <br />
                Scan This
                <br />
                QR
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ContactIcons = ({ img, url, name, cCode }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <ShowLocation
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        data={url}
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          if (name === "url") {
            window.open(url);
          } else if (name === "mail") {
            window.open(`mailto:${url}`);
          } else if (name === "call") {
            window.open(`tel:${"+" + cCode + url}`);
          } else {
            setIsOpen(true);
          }
        }}
        className={`bg-[rgba(255,255,255,0.2)] p-1 w-[16vw] h-[16vw] rounded-full flex justify-center items-center cursor-pointer`}
      >
        <img src={img} alt={img} />
      </div>
    </>
  );
};

const Icons = ({ img, data }) => {
  let { id } = useParams();
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        axios
          .get(`${BASE_URI}unauthorized-user/addSocialTap/${id}`, {
            token: localStorage.getItem("token"),
            name: data?.name,
          })
          .then((res) => {})
          .catch((err) => {});
        if (img.includes("whatsapp")) {
          window.open(`https://api.whatsapp.com/send?phone=${data?.url}`);
        } else if (img.includes("paytm")) {
          window.open("https://paytmapp.app.link/");
        } else if (img.includes("phonepe")) {
          window.open(
            "https://play.google.com/store/apps/details?id=com.phonepe.app&hl=en_IN&gl=US"
          );
        } else if (img.includes("call")) {
          window.open(`tel:${"+" + data?.cCode + data?.url}`);
        } else if (img.includes("google-pay")) {
          window.open(
            "https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user&hl=en&gl=US"
          );
        } else {
          window.open(data?.url);
        }
      }}
      className={`rounded-lg cursor-pointer mb-3 mx-1.5`}
    >
      <img src={img} alt={img} />
    </div>
  );
};

export default ViewProfileMobile;
