import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import QRCodeStyling from "qr-code-styling";
import obj from "../../../Components/QR";
import copy from "copy-to-clipboard";
import { AiOutlineCopy, AiOutlineWhatsApp } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewProfileQr = () => {
  const { id } = useParams();
  const qr = useRef(null);
  const history = useNavigate();

  const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,
    ...obj,
    data: `${window.location.origin}/${id}`,
  });

  useEffect(() => {
    qrCode.update({
      data: `${window.location.origin}/${id}`,
    });
    if (!qr?.current?.innerHTML.includes("canvas")) {
      qrCode.append(qr.current);
    }
  }, [id]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="w-[100vw] m-auto h-[100vh] text-white bg-fbg bg-cover">
        <div className="w-full h-full pt-2 px-4 flex justify-evenly items-center flex-col">
          <div className="w-full">
            <div
              ref={qr}
              className="bg-white p-1.5 rounded-lg m-auto w-fit"
            ></div>
            <div className="mt-4 flex items-center relative w-[65%] mx-auto">
              <input
                type="text"
                value={`${window.location.origin}/${id}`}
                className="block bg-transparent border text-sm border-white py-1.5 pl-2 w-full rounded-lg pr-9 outline-none"
              />
              <AiOutlineCopy
                size={26}
                className="absolute right-1 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  copy(`${window.location.origin}/${id}`);
                  toast.success("Copied success");
                }}
              />
            </div>
            <div className="w-full px-6 mt-6">
              <div
                onClick={(e) => {
                  window.open(
                    `whatsapp://send?text=${window.location.origin}/${id}`
                  );
                }}
                className="bg-white text-black flex items-center w-full px-3 mb-3 py-1 rounded-lg"
              >
                <AiOutlineWhatsApp size={23} color="#25D366" />
                <p className="text-lg ml-2 font-medium">
                  Share Profile Via Whatsapp
                </p>
              </div>
              {/* <div className="bg-white text-black flex items-center w-full px-3 mb-3 py-1 rounded-lg">
                <AiOutlineShareAlt size={23} />
                <p className="text-lg ml-2 font-medium">Share Profile Link</p>
              </div>
              <div className="bg-white text-black flex items-center w-full px-3 mb-3 py-1 rounded-lg">
                <AiFillMail size={23} />
                <p className="text-lg ml-2 font-medium">
                  Share Profile Via Email
                </p>
              </div>
              <div className="bg-white text-black flex items-center w-full px-3 mb-3 py-1 rounded-lg">
                <AiOutlineMail size={23} />
                <p className="text-lg ml-2 font-medium">
                  Share Profile Via Message
                </p>
              </div> */}
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <button
              onClick={(e) => {
                e.preventDefault();
                history("/login");
              }}
              className="w-[35%] bg-white text-lightBlue py-1 rounded-lg mr-2 font-medium"
            >
              Edit My Card
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                history("/shop");
              }}
              className="w-[35%] bg-lightBlue text-white py-1 rounded-lg ml-2 font-medium"
            >
              Buy Card
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProfileQr;
