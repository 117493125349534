import React, { useContext, useRef } from "react";
import { useParams } from "react-router";
import Footer from "../Components/Footer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import TapKaroContext from "../../context/Context";
import { useState } from "react";
import axios from "axios";
import { PROFILE_URI } from "../../utils";
import { useEffect } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  PointElement,
  Legend,
  BarElement,
  LineElement,
  Filler
);

const LinkTaps = () => {
  const { id } = useParams();
  const {
    profileTaps,
    connectionTaps,
    socialTaps,
    dailyTapsReport,
    getProfileTaps,
    getConnectionTaps,
    getSocialIconsReport,
  } = useContext(TapKaroContext);
  const [showTap, setShowTap] = useState("Taps");
  const [location, setLocation] = useState([]);
  const [monthlyOrDaily, setMonthlyOrDaily] = useState({
    taps: true,
    profile: true,
    links: true,
  });

  const getLocationReport = () => {
    axios
      .post(`${PROFILE_URI}/location`, {
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        setLocation(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getSocialIconsReport();
    getLocationReport();
  }, [monthlyOrDaily, showTap]);

  useEffect(() => {
    getProfileTaps();
    getLocationReport();
  }, [monthlyOrDaily, showTap]);

  useEffect(() => {
    getConnectionTaps();
    getLocationReport();
  }, [monthlyOrDaily, showTap]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  function fillArrayWithIncrement(startingValue, length) {
    const arr = Array.from({ length }, (_, index) => startingValue + index);
    return arr;
  }

  const resultArray = fillArrayWithIncrement(
    1,
    new Date(
      new Date(Date.now()).getFullYear(),
      new Date(Date.now()).getMonth() + 1,
      0
    ).getDate()
  );

  const data = {
    labels: monthlyOrDaily?.taps ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        label: "Website Visits",
        data: monthlyOrDaily?.taps
          ? [...dailyTapsReport?.taps]
          : [...profileTaps],
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const dataConnections = {
    labels: monthlyOrDaily?.profile ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        label: "Profile Saved",
        data: monthlyOrDaily?.profile
          ? [...dailyTapsReport?.profile]
          : [...connectionTaps],
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const dataSocialTap = {
    labels: monthlyOrDaily?.links ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        label: "Social icons Taps",
        data: monthlyOrDaily?.links
          ? [...dailyTapsReport?.link]
          : [...socialTaps],
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const getChartOptions = () => {
    return {
      scales: {
        x: {
          ticks: {
            color: "white", // X-axis label color
          },
        },
        y: {
          ticks: {
            color: "white", // Y-axis label color
            callback: function (value) {
              // Display only numeric and positive values on the Y-axis
              if (Number.isInteger(value) && value >= 0) {
                return value;
              }
            },
          },
        },
      },
      elements: {
        point: {
          radius: 2, // Set the desired dot size here
        },
      },
      ticks: {
        precision: 0,
      },
      plugins: {
        legend: {
          display: false, // Set the dataset label display to false
          labels: {
            color: "white", // Set the label color here (if you have other labels, not dataset label)
          },
        },
      },
    };
  };

  const dataLocation = {
    labels: location?.tapCity?.slice(0, 5),
    datasets: [
      {
        label: "Location Taps",
        data: location?.taps ? [...location?.taps]?.slice(0, 5) : [],
        borderColor: "#fff",
        backgroundColor: "white",
        borderRadius: 5,
      },
    ],
  };

  const getChartOptionsBar = () => {
    return {
      indexAxis: "y",
      scales: {
        x: {
          ticks: {
            color: "white", // X-axis label color
          },
        },
        y: {
          ticks: {
            color: "white", // Y-axis label color
            callback: function (value, index, values) {
              // Display only numeric and positive values on the Y-axis
              if (Number.isInteger(value) && value >= 0) {
                // Show the labels from the dataLocation part
                return dataLocation.labels[index];
              }
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false, // Set the dataset label display to false
          labels: {
            color: "white", // Set the label color here (if you have other labels, not dataset label)
          },
        },
      },
      ticks: {
        precision: 0,
      },
      datasets: {
        bar: {
          barThickness: 25, // Set the desired bar width here
        },
      },
    };
  };

  return (
    <div className="w-[100vw] m-auto h-[100vh] text-white bg-fbg bg-cover">
      <div className="w-full h-full pt-2 px-2">
        <div className="border border-gray-400 px-4 rounded-xl">
          <div className="flex w-full items-center justify-between mt-3">
            {["Taps", "Link Taps", "Profile Saved"].map((e) => {
              return (
                <p
                  className={`border px-3 py-0.5 ${
                    showTap === e ? "text-white border-white" : ""
                  } rounded-lg border-gray-500 hover:border-white text-gray-400 hover:text-white`}
                  key={e}
                  onClick={(element) => {
                    element.preventDefault();
                    setShowTap(e);
                  }}
                >
                  {e}
                </p>
              );
            })}
          </div>
          {showTap === "Taps" ? (
            <div className="mt-4">
              <div className="rounded-lg mt-2 px-2 py-5">
                <div className="flex items-center justify-end">
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      setMonthlyOrDaily({
                        ...monthlyOrDaily,
                        taps: !monthlyOrDaily.taps,
                      });
                    }}
                    className="text-sm border px-4 rounded-lg py-0.5"
                  >
                    {monthlyOrDaily?.taps ? "Monthly" : "Yearly"}
                  </p>
                </div>
                <Line data={data} options={getChartOptions()} />
              </div>
            </div>
          ) : showTap === "Link Taps" ? (
            <div className="mt-4">
              <div className="rounded-lg mt-2 px-2 py-5">
                <div className="flex items-center justify-end">
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      setMonthlyOrDaily({
                        ...monthlyOrDaily,
                        links: !monthlyOrDaily.links,
                      });
                    }}
                    className="text-sm border px-4 rounded-lg py-0.5"
                  >
                    {monthlyOrDaily?.links ? "Monthly" : "Yearly"}
                  </p>
                </div>
                <Line data={dataSocialTap} options={getChartOptions()} />
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <div className="rounded-lg mt-2 px-2 py-5">
                <div className="flex items-center justify-end">
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      setMonthlyOrDaily({
                        ...monthlyOrDaily,
                        profile: !monthlyOrDaily.profile,
                      });
                    }}
                    className="text-sm border px-4 rounded-lg py-0.5"
                  >
                    {monthlyOrDaily?.profile ? "Monthly" : "Yearly"}
                  </p>
                </div>
                <Line data={dataConnections} options={getChartOptions()} />
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="mt-10">
            <div className="border rounded-lg mt-6 border-gray-400 px-2 py-6">
              <Bar data={dataLocation} options={getChartOptionsBar()} />
            </div>
          </div>
        </div>
      </div>
      <Footer id={id} />
    </div>
  );
};

export default LinkTaps;
