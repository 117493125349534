import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";

import exclusive from "../../images/Assets Cards/Executive.png";
import premium from "../../images/Assets Cards/Premium.png";
import metal from "../../images/Assets Cards/metal.png";
import { useNavigate } from "react-router";

gsap.registerPlugin(ScrollTrigger);

export default function Scene() {
  const component = useRef();
  const slider = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let panels = gsap.utils.toArray(".panel");
      gsap.to(panels, {
        xPercent: -100 * (panels.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: slider.current,
          pin: true,
          scrub: 1,
          snap: 1 / (panels.length - 1),
          end: () => "+=" + slider.current.offsetWidth,
        },
      });
    }, component);
    return () => ctx.revert();
  });

  const cards = [
    {
      title: "Exclusive Card",
      desc: "Exclusive NFC Smart business card with QR Code printed, comes with customization of your name and company logo on the card.  Now build your network, exponentially.",
      points: [
        "PVC Cards",
        "Metallic Cards ( Embossed Name and Logo )",
        "Wooden Cards",
      ],
    },
    {
      title: "Premium Card",
      desc: "Premium NFC and QR enabled cards comes with complete package. Your logo, name, picture, social media customisation. We ship exactly the way you imagined.",
      points: ["Super Customised PVC Cards"],
    },
    {
      title: "Absolute Card",
      desc: "TAP KARO brand card with NFC and QR Code enabled. It is EVERGREEN card and made for everyone.",
      points: ["Awestruck PVC Design"],
    },
  ];

  return (
    <div className="App" ref={component}>
      <div ref={slider} className="w-[300vw] h-[100vh] flex flex-wrap">
        {cards.map((e, i) => {
          return <PinnedSection data={e} key={i} />;
        })}
      </div>
    </div>
  );
}

const PinnedSection = ({ data }) => {
  const history = useNavigate();
  return (
    <div
      className={`panel h-[100vh] bg-navyBlue text-white w-[100vw] flex md:flex-row mobile:flex-col items-center mobile:justify-center md:justify-evenly mobile:px-0 md:px-[4vw]`}
    >
      <div
        className={`mobile:h-[35vh] md:h-[100%] mobile:w-full md:w-[50%] md:object-contain mobile:object-cover flex justify-center items-center`}
      >
        <img
          src={
            data?.title?.toLowerCase().includes("exclusive")
              ? exclusive
              : data?.title?.toLowerCase().includes("absolute")
              ? metal
              : premium
          }
          alt={
            data?.title?.toLowerCase().includes("exclusive")
              ? exclusive
              : data?.title?.toLowerCase().includes("absolute")
              ? metal
              : premium
          }
          className="w-full block"
        />
      </div>
      <div className="text-white md:w-[45%] mobile:w-[90%] mobile:h-fit md:h-full flex flex-col justify-center md:items-start mobile:items-center mobile:pb-4 md:pb-0">
        <h1 className="mobile:text-4xl md:text-5xl md:mb-5 mobile:font-medium md:font-semibold mobile:mb-2 md:mt-0 mobile:mt-4">
          {data?.title}
        </h1>
        <p className="md:w-[80%] mobile:w-[90%] mobile:text-center md:text-start mobile:text-lg md:text-xl">
          {data?.desc}
        </p>
        <p className="md:mt-5 mobile:mt-2 md:text-base mobile:text-lg mb-3">
          AVAILABLE PRINT STYLE
        </p>
        {data?.points.map((e, i) => {
          return (
            <li
              key={i}
              className="md:text-base mobile:text-lg mobile:text-center md:text-start"
            >
              {e}
            </li>
          );
        })}
        <button
          className="btn mt-3"
          onClick={(e) => {
            e.preventDefault();
            history("/shop");
          }}
        >
          Buy Now
        </button>
      </div>
    </div>
  );
};
