import React, { useEffect, useRef, useState } from "react";
import bg from "../../images/Free_3,5x2,5in_Business_Card_6.png";
import { BASE_URI, BUSSINESS_URI } from "../../utils/index";
import { BsKeyFill } from "react-icons/bs";
import Nav from "../../Components/Nav";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import Captcha from "../../Components/Captcha";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BussinessPasswordReset = () => {
  const { id, token } = useParams();
  const [data, setData] = useState({
    password: "",
  });
  const [showPassword, setshowPassword] = useState(false);
  const history = useNavigate();
  useEffect(() => {
    axios
      .get(`${BUSSINESS_URI}/password-reset/${id}/${token}`)
      .then((res) => {
        if (res.status !== 200) {
          history("/bussiness/login");
        }
      })
      .catch((err) => {});
  }, [id, token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const captchaRef = useRef(null);

  return (
    <div className="overflow-x-hidden">
      <Nav />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bg-gradient-to-br from-navyBlue to-lightBlue w-[100vw] mobile:h-fit md:h-[100vh] flex md:flex-row mobile:flex-col items-center text-white">
        <img src={bg} alt={bg} className="h-full mobile:p-5 md:p-7" />
        <div className="md:ml-[4%] mobile:w-[80%] md:w-[35%] md:pb-0 mobile:pb-6">
          <h1 className="text-2xl font-semibold my-2">Reset Password</h1>
          <div className="flex items-center">
            <BsKeyFill size={24} className="absolute left-[13%]" />
            <input
              value={data.password}
              onChange={(e) => {
                setData({ ...data, password: e.target.value });
              }}
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              className="border my-2 outline-none bg-transparent py-1 mobile:pl-12 md:pl-10 pr-16 rounded-lg w-[100%] relative tracking-widest"
            />
            <p
              onClick={(e) => {
                e.preventDefault();
                setshowPassword(!showPassword);
              }}
              className="absolute right-[15%] cursor-pointer transition-all font-medium text-gray-400 hover:text-white"
            >
              {showPassword ? "Hide" : "Show"}
            </p>
          </div>
          <div className="flex items-center w-[100%] justify-center my-2">
            <Captcha captchaRef={captchaRef} />
          </div>
          <button
            onClick={async (e) => {
              e.preventDefault();
              let token = captchaRef.current.getValue();

              if (token) {
                let response = await Axios.post(`${BASE_URI}verify-token`, {
                  reCAPTCHA_TOKEN: token,
                });
                if (
                  response.status === 200 &&
                  response.data.verification_info.success === true
                ) {
                  axios
                    .post(
                      `${BUSSINESS_URI}/password-reset/reset/${id}/${token}`,
                      {
                        password: data.password,
                      }
                    )
                    .then((res) => {
                      if (res.status === 200) {
                        toast.success("Password reset successful");
                        history("/bussiness/login");
                      } else {
                        toast.warning(res.data.data);
                      }
                    })
                    .catch((err) => {
                      toast.error("Internal server error");
                    });
                  captchaRef.current.reset();
                } else {
                  captchaRef.current.reset();
                  toast.warning("User not verified");
                }
              } else {
                captchaRef.current.reset();
                toast.warning("User not verified");
              }
            }}
            className="bg-lightBlue w-full mt-1 py-1 rounded-lg font-medium text-white hover:bg-white hover:text-lightBlue"
          >
            Change Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default BussinessPasswordReset;
