import React, { useContext, useEffect, useRef, useState } from "react";
import facebook from "../../images/icons/facebook.png";
import linkedin from "../../images/icons/linkedin.png";
import instagram from "../../images/icons/instagram.png";
import youtube from "../../images/icons/youtube.png";
import whatsapp from "../../images/icons/whatsapp.png";
import plus from "../../images/icons/plus.png";
import paytm from "../../images/icons/paytm.png";
import gpay from "../../images/icons/google-pay.png";
import phonepe from "../../images/icons/phonepe.png";
import { useNavigate } from "react-router";
import QRCodeStyling from "qr-code-styling";
import obj from "../../Components/QR";
import { AiOutlineCopy, AiOutlineArrowLeft } from "react-icons/ai";
import { BiStats } from "react-icons/bi";
import copy from "copy-to-clipboard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

import location from "../../images/contact/location.png";
import url from "../../images/contact/url.png";
import mail from "../../images/contact/mail.png";
import call from "../../images/contact/call.png";
import ReactModal from "../../Mobile/Components/ReactModal";
import TapKaroContext from "../../context/Context";
import twitter from "../../images/icons/twitter.png";
import calling from "../../images/icons/call.png";
import reviews from "../../images/icons/google-reviews.png";
import indiamart from "../../images/icons/indiamart.png";
import justdial from "../../images/icons/justdial.png";
import meesho from "../../images/icons/meesho.png";
import snapchat from "../../images/icons/snapchat.png";
import telegram from "../../images/icons/telegram.png";
import threads from "../../images/icons/threads.png";
import ContactUpdate from "../../Mobile/Components/ContactUpdate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { PROFILE_URI } from "../../utils";
import { InfinitySpin } from "react-loader-spinner";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  PointElement,
  Legend,
  BarElement,
  LineElement,
  Filler
);

const getChartOptions = () => {
  return {
    scales: {
      x: {
        ticks: {
          color: "white", // X-axis label color
        },
      },
      y: {
        ticks: {
          color: "white", // Y-axis label color
          callback: function (value) {
            // Display only numeric and positive values on the Y-axis
            if (Number.isInteger(value) && value >= 0) {
              return value;
            }
          },
        },
      },
    },
    elements: {
      point: {
        radius: 2, // Set the desired dot size here
      },
    },
    ticks: {
      precision: 0,
    },
    plugins: {
      legend: {
        display: false, // Set the dataset label display to false
        labels: {
          color: "white", // Set the label color here (if you have other labels, not dataset label)
        },
      },
    },
  };
};

const ViewEditUser = () => {
  const history = useNavigate();
  const qr = useRef(null);
  const sideBar = useRef(null);
  const [monthlyOrDaily, setMonthlyOrDaily] = useState({
    taps: true,
    profile: true,
    links: true,
  });

  const {
    check,
    getProfile,
    profile,
    getProfileTaps,
    profileTaps,
    connectionTaps,
    getSocialIconsReport,
    getConnectionTaps,
    socialTaps,
    dailyTapsReport,
    checkLoader,
  } = useContext(TapKaroContext);

  const [username, setUsername] = useState(profile?.username);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getProfile();
    } else {
      history("/login");
    }
  }, []);

  const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,
    ...obj,
    data: `${window.location.origin}/${profile?.username}`,
  });

  useEffect(() => {
    getSocialIconsReport();
  }, [monthlyOrDaily, profile]);

  useEffect(() => {
    getProfileTaps();
  }, [monthlyOrDaily, profile]);

  useEffect(() => {
    getConnectionTaps();
  }, [monthlyOrDaily, profile]);

  useEffect(() => {
    qrCode.update({
      data: `${window.location.origin}/${profile?.username}`,
    });
    if (!qr?.current?.innerHTML.includes("canvas")) {
      qrCode.append(qr.current);
    }
  }, [profile, qr]);

  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  function fillArrayWithIncrement(startingValue, length) {
    const arr = Array.from({ length }, (_, index) => startingValue + index);
    return arr;
  }

  const resultArray = fillArrayWithIncrement(
    1,
    new Date(
      new Date(Date.now()).getFullYear(),
      new Date(Date.now()).getMonth() + 1,
      0
    ).getDate()
  );

  const dataWebsite = {
    labels: monthlyOrDaily?.taps ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        data: monthlyOrDaily?.taps
          ? [...dailyTapsReport?.taps]
          : [...profileTaps],
        label: "Website Visits",
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const dataConnections = {
    labels: monthlyOrDaily?.profile ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        label: "Profile Saved",
        data: monthlyOrDaily?.profile
          ? [...dailyTapsReport?.profile]
          : [...connectionTaps],
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const dataSocialTap = {
    labels: monthlyOrDaily?.links ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        label: "Social icons Taps",
        data: monthlyOrDaily?.links
          ? [...dailyTapsReport?.link]
          : [...socialTaps],
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [iconData, setIconData] = useState();
  const [contactData, setContactData] = useState();

  useEffect(() => {
    setUsername(profile?.username);
  }, [profile]);

  const [type, setType] = useState(false);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ReactModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        iconData={iconData}
        type={type}
      />
      <ContactUpdate
        modalIsOpen={openModal}
        setIsOpen={setOpenModal}
        iconData={{
          phone: profile?.phone ? profile?.phone : "",
          location: profile?.location ? profile?.location : "",
          portfolio: profile?.portfolio ? profile?.portfolio : "",
          cCode: profile?.cCode ? profile?.cCode : 91,
        }}
        contactData={contactData}
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          history("/");
        }}
        className="p-1 bg-white rounded-full absolute left-0 top-0 z-50 text-darkNavyBlue m-2 cursor-pointer"
      >
        <AiOutlineArrowLeft size={22} className="cursor-pointer" />
      </div>
      {check !== true ? (
        <div className="w-[100vw] h-[100vh] absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] backdrop-blur-md text-white text-4xl font-semibold flex-col flex justify-center items-center z-50">
          {checkLoader ? (
            <InfinitySpin width="300" color="#fff" />
          ) : (
            <>
              <p>Please Buy a card to activate profile</p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  history("/shop");
                }}
                className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] px-6 font-semibold mobile:pt-2 md:mt-5 rounded-lg py-1.5 text-xl"
              >
                Shop Now
              </button>
            </>
          )}
        </div>
      ) : null}
      <div className="bg-fbg w-[100vw] h-[100vh] bg-no-repeat bg-cover">
        <div className="backdrop-blur-md w-full h-full flex justify-between">
          <div className="w-[75vw]">
            <div
              style={{
                backgroundImage: `url(${profile?.coverPicture})`,
              }}
              className="h-[35%] bg-cover bg-no-repeat bg-bottom"
            ></div>
            <div className="h-[60%] px-3 text-white">
              <div className="flex items-start justify-between my-2.5 -mt-[35px]">
                <div className="w-[60%] flex items-end">
                  <img
                    src={profile?.profilePicture}
                    alt="user"
                    className="w-[8.5vw] h-[8.5vw] object-cover object-center rounded-lg"
                  />
                  <div className="ml-3">
                    <h1 className="text-2xl font-semibold">{profile?.name}</h1>
                    <div className="flex items-center justify-start my-0.5">
                      <input
                        type="text"
                        value={username}
                        className="outline-none bg-transparent border-b w-[10vw] px-0.5 border-b-gray-600"
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                      />
                      {profile?.username !== username ? (
                        <button
                          onClick={(e) => {
                            axios
                              .post(
                                `${PROFILE_URI}/edit/username/${profile?._id}`,
                                { username }
                              )
                              .then((res) => {
                                if (res.status === 203) {
                                  toast.warning("Username already exists");
                                } else {
                                  toast.success(
                                    "Username updated successfully"
                                  );
                                  getProfile();
                                }
                              })
                              .catch((err) => {});
                          }}
                          className="bg-lightBlue px-5 ml-2 rounded-lg"
                        >
                          Save
                        </button>
                      ) : null}
                    </div>
                    <h1 className="text-lg">{profile?.designation}</h1>
                    <h1 className="text-lg">{profile?.company}</h1>
                  </div>
                </div>
                <div className="mt-12">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      history(`/user/edit/`);
                    }}
                    className="bg-lightBlue px-5 py-0.5 mr-2.5 rounded-lg"
                  >
                    Edit Profile
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      history("/user/connections/");
                    }}
                    className="bg-lightBlue px-5 py-0.5 mr-2.5 rounded-lg"
                  >
                    My Connections
                  </button>
                </div>
              </div>
              <div className="mt-7 px-2">
                <div>
                  <div className="flex items-center flex-wrap my-2">
                    {profile?.icons
                      ?.filter((e) => {
                        return e?.type?.toLowerCase().includes("social");
                      })
                      .map((e, i) => {
                        return (
                          <Icons
                            key={i}
                            modalIsOpen={modalIsOpen}
                            setIsOpen={setIsOpen}
                            iconData={iconData}
                            setIconData={setIconData}
                            img={
                              e?.name?.toLowerCase().includes("face")
                                ? facebook
                                : e?.name?.toLowerCase().includes("insta")
                                ? instagram
                                : e?.name?.toLowerCase().includes("what")
                                ? whatsapp
                                : e?.name?.toLowerCase().includes("you")
                                ? youtube
                                : e?.name?.toLowerCase().includes("twitter")
                                ? twitter
                                : e?.name?.toLowerCase().includes("linkedin")
                                ? linkedin
                                : e?.name?.toLowerCase().includes("call")
                                ? calling
                                : e?.name?.toLowerCase().includes("reviews")
                                ? reviews
                                : e?.name?.toLowerCase().includes("justdial")
                                ? justdial
                                : e?.name?.toLowerCase().includes("meesho")
                                ? meesho
                                : e?.name?.toLowerCase().includes("snapchat")
                                ? snapchat
                                : e?.name?.toLowerCase().includes("telegram")
                                ? telegram
                                : e?.name?.toLowerCase().includes("threads")
                                ? threads
                                : indiamart
                            }
                            data={e}
                          />
                        );
                      })}
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setType(false);
                      }}
                    >
                      <Icons
                        modalIsOpen={modalIsOpen}
                        setIsOpen={setIsOpen}
                        img={plus}
                        padding={true}
                        iconData={iconData}
                        setIconData={setIconData}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex items-center flex-wrap my-2">
                    {profile?.icons
                      ?.filter((e) => {
                        return e?.type?.toLowerCase().includes("upi");
                      })
                      .map((e, i) => {
                        return (
                          <Icons
                            key={i}
                            modalIsOpen={modalIsOpen}
                            setIsOpen={setIsOpen}
                            iconData={iconData}
                            setIconData={setIconData}
                            img={
                              e?.name?.toLowerCase().includes("paytm")
                                ? paytm
                                : e?.name?.toLowerCase().includes("phonepe")
                                ? phonepe
                                : gpay
                            }
                            data={e}
                          />
                        );
                      })}
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setType(true);
                      }}
                    >
                      <Icons
                        modalIsOpen={modalIsOpen}
                        iconData={iconData}
                        setIconData={setIconData}
                        setIsOpen={setIsOpen}
                        img={plus}
                        padding={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-rbg h-full w-[25vw] flex flex-col items-center py-4 text-white px-4 overflow-y-auto">
            <div className="flex justify-end w-full mb-4">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`/${profile?._id}`, "_blank");
                }}
                className="bg-green-500 px-4 py-1 rounded-lg mr-3"
              >
                Preview
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  localStorage.setItem("token", "");
                  history("/");
                }}
                className="bg-red-500 px-4 py-1 rounded-lg"
              >
                Logout
              </button>
            </div>
            <div ref={qr} className="bg-white p-0.5 rounded-lg"></div>
            <div className="mt-4 flex items-center relative w-[90%]">
              <input
                type="text"
                value={`${window.location.origin}/${profile?.username}`}
                className="block bg-transparent border border-white py-1.5 pl-2 w-full rounded-lg pr-9 outline-none"
              />
              <AiOutlineCopy
                size={28}
                onClick={(e) => {
                  e.preventDefault();
                  copy(`${window.location.origin}/${profile?.username}`);
                  toast.success("Details copied");
                }}
                className="absolute right-1 cursor-pointer"
              />
            </div>
            <div className="bg-[rgba(0,0,0,0.5)] w-[98%] my-5 rounded-2xl flex items-center justify-evenly py-4 px-1">
              <ContactIcons
                img={call}
                modalIsOpen={openModal}
                setIsOpen={setOpenModal}
                setContactData={setContactData}
                data={{
                  name: "phone",
                  url: profile?.phone,
                  cCode: profile?.cCode ? profile?.cCode : 91,
                }}
              />
              <ContactIcons
                img={mail}
                modalIsOpen={openModal}
                setIsOpen={setOpenModal}
                setContactData={setContactData}
                data={{ name: "mail", url: profile?.email }}
              />
              <ContactIcons
                img={location}
                modalIsOpen={openModal}
                setIsOpen={setOpenModal}
                setContactData={setContactData}
                data={{ name: "location", url: profile?.location }}
              />
              <ContactIcons
                img={url}
                modalIsOpen={openModal}
                setIsOpen={setOpenModal}
                setContactData={setContactData}
                data={{ name: "portfolio", url: profile?.portfolio }}
              />
            </div>
            <div className="w-[95%]">
              <div className="flex justify-between items-center">
                <p className="font-semibold">Taps Insights</p>
                <div className="flex items-center">
                  <BiStats
                    onClick={(e) => {
                      e.preventDefault();
                      sideBar.current.scrollIntoView({ behavior: "smooth" });
                    }}
                    size={30}
                    className="mr-2 cursor-pointer"
                  />
                </div>
              </div>
              <div className="mt-7 h-fit">
                <div className="flex items-center justify-between mb-1">
                  <p>Profile Taps :</p>
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      setMonthlyOrDaily({
                        ...monthlyOrDaily,
                        taps: !monthlyOrDaily.taps,
                      });
                    }}
                    className="text-sm border px-4 rounded-lg py-0.5 cursor-pointer"
                  >
                    {monthlyOrDaily?.taps ? "Monthly" : "Yearly"}
                  </p>
                </div>
                <Line data={dataWebsite} options={getChartOptions()} />
              </div>
              <div className="mt-7 h-fit">
                <div className="flex items-center justify-between mb-1">
                  <p>Profile Saved :</p>
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      setMonthlyOrDaily({
                        ...monthlyOrDaily,
                        profile: !monthlyOrDaily.profile,
                      });
                    }}
                    className="text-sm border px-4 rounded-lg py-0.5 cursor-pointer"
                  >
                    {monthlyOrDaily?.profile ? "Monthly" : "Yearly"}
                  </p>
                </div>
                <Line data={dataConnections} options={getChartOptions()} />
              </div>
              <div className="mt-7 h-fit" ref={sideBar}>
                <div className="flex items-center justify-between mb-1">
                  <p>Social Link Taps :</p>
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      setMonthlyOrDaily({
                        ...monthlyOrDaily,
                        links: !monthlyOrDaily.links,
                      });
                    }}
                    className="text-sm border px-4 rounded-lg py-0.5 cursor-pointer"
                  >
                    {monthlyOrDaily?.links ? "Monthly" : "Yearly"}
                  </p>
                </div>
                <Line data={dataSocialTap} options={getChartOptions()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ContactIcons = ({
  img,
  modalIsOpen,
  setIsOpen,
  setContactData,
  data,
}) => {
  return (
    <div
      onClick={(e) => {
        if (data) {
          e.preventDefault();
          setIsOpen(!modalIsOpen);
          setContactData(data);
        }
      }}
      className={`bg-[rgba(255,255,255,0.2)] p-3 w-[4vw] h-[4vw] backdrop-blur-md rounded-full flex justify-center items-center cursor-pointer`}
    >
      <img src={img} alt={img} />
    </div>
  );
};

const Icons = ({ img, padding, modalIsOpen, setIsOpen, setIconData, data }) => {
  return (
    <div
      className={`${
        padding ? "p-3 bg-[rgba(255,255,255,0.2)] w-[12%]" : "p-0 w-[6.5%]"
      } backdrop-blur-md rounded-lg mr-4 cursor-pointer mb-3`}
      onClick={(e) => {
        e.preventDefault();
        setIsOpen(!modalIsOpen);
        setIconData(data);
      }}
    >
      <img src={img} alt={img} />
    </div>
  );
};

export default ViewEditUser;
