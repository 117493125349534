import React from "react";
import logo from "../../images/tap karo logo.png";
import { useLocation, useNavigate } from "react-router";

const Sidebar = () => {
  const location = useLocation();
  const history = useNavigate();

  return (
    <div className="flex flex-col w-[20vw] items-center">
      <img
        src={logo}
        alt={logo}
        className="w-[50%] my-6 cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          history("/");
        }}
      />
      <div className="flex flex-col items-center text-xl font-semibold w-full">
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1 ${
            location.pathname === "/admin" ? "bg-black text-white" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            history("/admin");
          }}
        >
          Dashboard
        </p>
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1 ${
            location.pathname.includes("announce") ? "bg-black text-white" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            history("/admin/announce");
          }}
        >
          Announce Tokens
        </p>
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1 ${
            location.pathname.includes("orders") ? "bg-black text-white" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            history("/admin/orders");
          }}
        >
          Order
        </p>
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1 ${
            location.pathname.includes("bussiness") ? "bg-black text-white" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            history("/admin/bussiness");
          }}
        >
          Bussiness
        </p>
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1 ${
            location.pathname.includes("cards") ? "bg-black text-white" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            history("/admin/cards");
          }}
        >
          Cards
        </p>
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1 ${
            location.pathname.includes("offers") ? "bg-black text-white" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            history("/admin/offers");
          }}
        >
          Offers
        </p>
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1 ${
            location.pathname.includes("pop") ? "bg-black text-white" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            history("/admin/pop-ups");
          }}
        >
          Pop ups
        </p>
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1 ${
            location.pathname.includes("query") ? "bg-black text-white" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            history("/admin/query");
          }}
        >
          Queries
        </p>
        <p
          className={`mb-3 cursor-pointer w-[55%] rounded-xl text-center py-1`}
          onClick={(e) => {
            e.preventDefault();
            localStorage.setItem("admin_token", "");
            history("/");
          }}
        >
          Logout
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
