import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Topbar from "../Components/Topbar";

import { AiOutlineFilter, AiOutlineSearch } from "react-icons/ai";
import TapKaroContext from "../../context/Context";
import { BUSSINESS_URI } from "../../utils";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Bussiness = () => {
  const [showFilter, setShowFilter] = useState(false);
  const context = useContext(TapKaroContext);

  useEffect(() => {
    context.getBussinesses();
  }, [context]);

  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[80vw] pt-6 px-6">
        <Topbar title={"Bussiness"} />
        <div>
          <div className="flex justify-end">
            <div className="flex items-center relative">
              <AiOutlineSearch size={30} />
              <input
                type="text"
                placeholder="Enter order number or name"
                className="border outline-none py-1 px-3 border-black rounded-lg mx-2 w-[20vw]"
                value={context.bussinessConfig.search}
                onChange={(e) => {
                  context.setBussinessConfig({
                    ...context.bussinessConfig,
                    search: e.target.value,
                  });
                }}
              />
              <AiOutlineFilter
                size={30}
                onClick={(e) => {
                  e.preventDefault();
                  setShowFilter(!showFilter);
                }}
                className="cursor-pointer"
              />
              {showFilter ? (
                <div className="w-[40%] absolute right-[5%] top-[90%] rounded-md bg-black p-3">
                  <p value="Active" className="text-center py-0.5 Active">
                    Active
                  </p>
                  <p value="Disable" className="text-center py-0.5 Disable">
                    Disable
                  </p>
                  <p
                    value="Deactivate"
                    className="text-center py-0.5 Deactivate"
                  >
                    Deactivate
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="mt-4 h-[75vh] overflow-y-scroll">
            {context.bussinesses.map((e, i) => {
              return <Block data={e} key={i} />;
            })}
            
            {context?.bussinessConfig.size * context?.bussinessConfig.page <=
            context?.bussinessConfig.totalOrders ? (
              <button
                className="text-white bg-black px-4 py-0.5 rounded-lg"
                onClick={(e) => {
                  e.preventDefault();
                  context?.setBussinessConfig({
                    ...context?.bussinessConfig,
                    page: context.bussinessConfig.page + 1,
                  });
                  context?.getBussinesses();
                }}
              >
                Load More
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const Block = ({ data }) => {
  const [status, setStatus] = useState(data?.status);
  const history = useNavigate();
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          history(`/admin/bussiness/${data?._id}`);
        }}
        className="w-[98%] bg-adminBlue cursor-pointer text-white mb-3 rounded-xl px-6 py-3 flex items-center justify-between m-auto"
      >
        <div className="text-lg">
          <p className="font-bold text-xl">{data?.name}</p>
          <p className="font-semibold">
            Address:
            <span className="font-normal">
              {" "}
              {data.address +
                ", " +
                data.street +
                ", " +
                data.city +
                ", " +
                data.state +
                ", " +
                data.country +
                " " +
                data.pinCode}
            </span>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <select
            className={`outline-none px-6 py-1 rounded-lg ${status}`}
            value={status}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              setStatus(e.target.value);
              axios
                .put(`${BUSSINESS_URI}/updateStatus`, {
                  id: data._id,
                  status: e.target.value,
                })
                .then((response) => {
                  toast.success("Status changed successfully");
                })
                .catch((err) => {
                  toast.error("Internal server error");
                });
            }}
          >
            <option value="Active" className="Active">
              Active
            </option>
            <option value="Disable" className="Disable">
              Disable
            </option>
            <option value="Deactivate" className="Deactivate">
              Deactivate
            </option>
          </select>
          <p className="bg-white text-black px-8 mt-2 py-0.5 rounded-lg">
            {data?.cards} Cards
          </p>
        </div>
      </div>
    </>
  );
};

export default Bussiness;
