import React, { useEffect, useRef, useState } from "react";

import logo from "../images/tap karo logo.png";

import { BASE_URI, LOGIN_URI } from "../utils/index";
import { BsKeyFill } from "react-icons/bs";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import Captcha from "../Components/Captcha";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PasswordReset = () => {
  const { id, token } = useParams();
  const [data, setData] = useState({
    password: "",
  });
  const [showPassword, setshowPassword] = useState(false);
  const history = useNavigate();
  useEffect(() => {
    axios
      .get(`${LOGIN_URI}/password-reset/${id}/${token}`)
      .then((res) => {
        if (res.status !== 200) {
          history("/Login");
        }
      })
      .catch((err) => {});
  }, [id, token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const captchaRef = useRef(null);

  return (
    <div className="overflow-x-hidden">
      <Nav />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="h-[100vh] w-full bg-navyBlue flex justify-center items-center">
        <div className="border flex flex-col border-gray-700 mobile:py-3 md:py-4 items-center text-white rounded-3xl backdrop-blur-xl mobile:w-[90%] md:w-[25%]">
          <img src={logo} alt={logo} className="w-[30%]" />
          <h1 className="text-2xl m-3">Reset Password</h1>
          <div className="mobile:w-[85%] md:w-[80%] m-auto">
            <div className="flex items-center">
              <BsKeyFill size={24} className="absolute left-[13%]" />
              <input
                value={data.password}
                onChange={(e) => {
                  setData({ ...data, password: e.target.value });
                }}
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                className="border my-2 outline-none bg-transparent py-1 mobile:pl-12 md:pl-10 pr-16 rounded-lg w-[100%] relative tracking-widest"
              />
              <p
                onClick={(e) => {
                  e.preventDefault();
                  setshowPassword(!showPassword);
                }}
                className="absolute right-[15%] cursor-pointer transition-all font-medium text-gray-400 hover:text-white"
              >
                {showPassword ? "Hide" : "Show"}
              </p>
            </div>
            <div className="flex items-center w-[100%] justify-center my-2">
              <Captcha captchaRef={captchaRef} />
            </div>
            <button
              onClick={async (e) => {
                e.preventDefault();
                let token = captchaRef.current.getValue();

                if (token) {
                  let response = await Axios.post(`${BASE_URI}verify-token`, {
                    reCAPTCHA_TOKEN: token,
                  });
                  if (
                    response.status === 200 &&
                    response.data.verification_info.success === true
                  ) {
                    axios
                      .post(
                        `${LOGIN_URI}/password-reset/reset/${id}/${token}`,
                        {
                          password: data.password,
                        }
                      )
                      .then((res) => {
                        if (res.status === 200) {
                          toast.success("Password reset successful");
                          history("/Login");
                        }
                      })
                      .catch((err) => {
                        toast.error("Internal server error");
                      });
                    captchaRef.current.reset();
                  } else {
                    captchaRef.current.reset();
                    toast.warning("User not verified");
                    history("/");
                  }
                } else {
                  captchaRef.current.reset();
                  toast.warning("User not verified");
                }
              }}
              className="bg-lightBlue w-full mt-1 py-1 rounded-lg font-medium text-white hover:bg-white hover:text-lightBlue"
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordReset;
