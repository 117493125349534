import React, { useContext, useState } from "react";
import bg from "../../images/Free_3,5x2,5in_Business_Card_6.png";
import { useNavigate } from "react-router";
import TapKaroContext from "../../context/Context";
import axios from "axios";
import Axios from "axios";
import { BASE_URI, BUSSINESS_URI } from "../../utils";
import { useRef } from "react";
import Captcha from "../../Components/Captcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineArrowLeft } from "react-icons/ai";

const BussinessRegister = () => {
  const captchaRef = useRef(null);
  const history = useNavigate();
  const { bussinessRegister, setBussinessRegister } =
    useContext(TapKaroContext);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          history("/");
        }}
        className="p-1 bg-white rounded-full absolute left-0 top-0 z-50 text-darkNavyBlue m-3 cursor-pointer"
      >
        <AiOutlineArrowLeft size={22} className="cursor-pointer" />
      </div>
      <div className="bg-gradient-to-br from-navyBlue to-lightBlue w-[100vw] mobile:h-fit md:h-[100vh] flex md:flex-row mobile:flex-col items-center text-white">
        <img src={bg} alt={bg} className="h-full mobile:p-5 md:p-7" />
        <div className="md:ml-[4%] mobile:w-[80%] md:w-[27%] md:pb-0 mobile:pb-6">
          <h1 className="text-2xl font-semibold mb-8">
            Create Bussiness Account
          </h1>
          <label htmlFor="name">Full Name</label>
          <input
            type="text"
            value={bussinessRegister.name}
            required={true}
            onChange={(e) => {
              setBussinessRegister({
                ...bussinessRegister,
                name: e.target.value,
              });
            }}
            id="name"
            className="border w-full border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg mb-3"
          />
          <label htmlFor="company">Company Name</label>
          <input
            type="text"
            value={bussinessRegister.company}
            required={true}
            onChange={(e) => {
              setBussinessRegister({
                ...bussinessRegister,
                company: e.target.value,
              });
            }}
            id="company"
            className="border w-full border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg mb-3"
          />
          <label htmlFor="email">Email Address</label>
          <input
            required={true}
            type="email"
            value={bussinessRegister.email}
            onChange={(e) => {
              setBussinessRegister({
                ...bussinessRegister,
                email: e.target.value,
              });
            }}
            id="email"
            className="border w-full border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg mb-3"
          />
          <label htmlFor="Phone">Phone</label>
          <input
            required={true}
            type="number"
            value={bussinessRegister.phone}
            onChange={(e) => {
              setBussinessRegister({
                ...bussinessRegister,
                phone: e.target.value,
              });
            }}
            id="Phone"
            className="border w-full border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg mb-3"
          />
          <label htmlFor="password">Password</label>
          <div className="flex items-center w-full relative">
            <input
              type={showPassword ? "text" : "password"}
              value={bussinessRegister.password}
              onChange={(e) => {
                setBussinessRegister({
                  ...bussinessRegister,
                  password: e.target.value,
                });
              }}
              id="password"
              className="border w-full border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg"
            />
            <p
              className="absolute right-2 pt-1 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? "Hide" : "Show"}
            </p>
          </div>
          <div className="flex items-center w-[100%] justify-center my-2">
            <Captcha captchaRef={captchaRef} />
          </div>
          <button
            onClick={async (e) => {
              e.preventDefault();
              let token = captchaRef.current.getValue();

              if (token) {
                let response = await Axios.post(`${BASE_URI}verify-token`, {
                  reCAPTCHA_TOKEN: token,
                });
                if (
                  response.status === 200 &&
                  response.data.verification_info.success === true
                ) {
                  axios
                    .post(`${BUSSINESS_URI}/verify`, {
                      email: bussinessRegister?.email,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        setBussinessRegister({
                          ...bussinessRegister,
                          otp: res?.data?.otp,
                        });
                        history("/bussiness/verification");
                        toast.success("Check otp on email");
                      } else {
                        toast.warning(res.data.data);
                      }
                    })
                    .catch((err) => {
                      toast.error("Internal server error");
                    });
                  captchaRef.current.reset();
                } else {
                  captchaRef.current.reset();
                  toast.warning("User not verified");
                }
              } else {
                captchaRef.current.reset();
                toast.warning("User not verified");
              }
            }}
            className="w-full bg-lightBlue py-1.5 rounded-lg font-medium mt-3"
          >
            Verify Now
          </button>
          <p className="mt-2 text-sm">
            Have an account?{" "}
            <span
              className="font-semibold cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                history("/bussiness/login");
              }}
            >
              Login
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default BussinessRegister;
