import React from "react";
import Modal from "react-modal";
import bg from "../../images/user/fbg.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import copy from "copy-to-clipboard";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth < 550 ? "80vw" : "20vw",
    borderRadius: "0.5rem",
    backgroundImage: `url(${bg})`,
    padding: 0,
    backgroundPosition: "center",
  },
};

const ShowLocation = ({ setIsOpen, modalIsOpen, data }) => {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="text-white px-3 py-4 flex flex-col justify-center items-center">
          <h1 className="text-2xl font-bold">Location</h1>
          <p
            className="text-center cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              copy(data);
              toast.success("Details copied successfully");
            }}
          >
            {data}
          </p>
          <button
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${data}`
              );
            }}
            className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] mobile:px-4 md:text-base md:px-4 rounded-lg py-0.5 mt-2"
          >
            Open Location
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ShowLocation;
