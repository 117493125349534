import React, { useEffect } from "react";
import ShopNow from "../Components/ShopNow";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import img from "../images/Group 1194.png";
import { useNavigate } from "react-router";

const Failure = () => {
  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Nav />
      <ShopNow />
      <div className="bg-navyBlue w-full mobile:h-[100vh] md:h-[130vh] flex flex-col items-center justify-center -mt-[3.5vw] text-white">
        <img src={img} alt={img} className="mobile:w-full md:w-[30%]" />
        <h1 className="text-4xl py-2 font-bold md:mt-0 mobile:mt-3">OOPS !!</h1>
        <p>Something Went Wrong</p>
        <p>Your Payment was not Completed !!</p>
        <button
          onClick={(e) => {
            e.preventDefault();
            history("/cart");
          }}
          className="bg-red-800 font-semibold px-4 py-1 rounded-lg mt-2 cursor-pointer"
        >
          Try Again
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default Failure;
