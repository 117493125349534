import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../images/tap karo logo.png";
import { Power2, gsap } from "gsap";
import { useNavigate } from "react-router";
import { BiLogOut, BiMenuAltRight } from "react-icons/bi";
import {
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import TapKaroContext from "../context/Context";

const Nav = ({ feature, home, footer }) => {
  const nav = useRef(null);
  const history = useNavigate();
  const [showNav, setShowNav] = useState(false);
  const { profile, getProfile } = useContext(TapKaroContext);
  const [profileDrop, setProfileDrop] = useState(false);
  const [profileMobileDrop, setProfileMobileDrop] = useState(false);
  const { cartData, setCartData } = useContext(TapKaroContext);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCartData(JSON.parse(localStorage.getItem("cart")));
    } else {
      setCartData([]);
    }
  }, [localStorage]);

  useEffect(() => {
    gsap.fromTo(
      nav.current,
      {
        y: -100,
        width: 0,
        opacity: 0,
      },
      {
        y: 0,
        width: "100%",
        opacity: 1,
        duration: 1.5,
        ease: Power2.easeInOut,
      }
    );
  }, []);

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <div
        ref={nav}
        className={`mobile:px-3 md:px-[5vw] z-40 flex items-center justify-between text-white md:w-full mobile:absolute md:fixed top-0 mobile:py-2.5 md:py-1 backdrop-blur-0 md:backdrop-blur-md`}
      >
        <img
          src={logo}
          alt={logo}
          className="md:w-[10vw] mobile:w-[35vw] cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            history("/");
            home?.current?.scrollIntoView({ behavior: "smooth" });
          }}
        />
        <BiMenuAltRight
          size={35}
          className="md:hidden mobile:block"
          onClick={(e) => {
            e.preventDefault();
            setShowNav(!showNav);
          }}
        />
        <div className="md:flex items-center justify-between md:w-[50vw] mobile:hidden">
          <p
            className="cursor-pointer font-medium"
            onClick={(e) => {
              history("/shop");
            }}
          >
            Shop
          </p>
          <p
            className="cursor-pointer font-medium"
            onClick={(e) => {
              e.preventDefault();
              history("/");
              feature?.current?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Features
          </p>
          <p
            className="cursor-pointer font-medium"
            onClick={(e) => {
              e.preventDefault();
              history("/");
              footer?.current?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Contact Us
          </p>
          {cartData?.length > 0 ? (
            <p
              className="cursor-pointer font-medium relative"
              onClick={(e) => {
                e.preventDefault();
                history("/cart");
              }}
            >
              <span className="absolute top-[-1vw] right-[-1vw] bg-red-500 w-[1.35vw] h-[1.35vw] flex items-center justify-center rounded-full text-sm">
                {cartData?.length}
              </span>
              <AiOutlineShoppingCart size={30} />
            </p>
          ) : null}

          {profileDrop && (
            <div className="absolute top-10 right-24 mt-2 w-36 backdrop-blur-2xl bg-[#25374c] opacity-95 text-white rounded-md shadow-lg z-10">
              <div className="py-1">
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    history("/user");
                    setProfileDrop(false);
                  }}
                  className="cursor-pointer flex justify-center items-center text-white text-center py-0.5 hover:text-gray-300"
                >
                  <AiOutlineEdit className="mr-1" />
                  Edit Profile
                </p>
                <p
                  onClick={(e) => {
                    localStorage.setItem("token", "");
                    e.preventDefault();
                    getProfile();
                    setProfileDrop(false);
                  }}
                  className="cursor-pointer flex items-center justify-center text-white text-center py-0.5 hover:text-gray-300"
                >
                  <BiLogOut className="mr-1" />
                  Logout
                </p>
              </div>
            </div>
          )}
          {localStorage.getItem("token")?.length > 0 &&
          profile?.profilePicture ? (
            <div
              onClick={(e) => {
                e.preventDefault();
                setProfileDrop(!profileDrop);
              }}
            >
              <img
                src={profile?.profilePicture}
                alt={profile?.profilePicture}
                className="w-[3.5vw] h-[3.5vw] object-cover object-center rounded-full cursor-pointer shadow-md shadow-gray-700"
              />
            </div>
          ) : (
            <div className="flex items-center">
              <button
                className="btn mr-3"
                onClick={(e) => {
                  e.preventDefault();
                  history("/login");
                  setShowNav(false);
                }}
              >
                Log In
              </button>
              <button
                className="btn"
                onClick={(e) => {
                  e.preventDefault();
                  history("/bussiness/login");
                  setShowNav(false);
                }}
              >
                Business Log In
              </button>
            </div>
          )}
        </div>
      </div>
      {showNav ? (
        <div className="z-50 flex flex-col py-[40vw] text-lg text-white items-center justify-between top-0 left-0 bg-[rgba(0,0,0,0.9)] absolute h-[100vh] w-[100vw]">
          <div className="relative">
            {/* <button
                className="flex items-center text-white hover:text-gray-200 px-3 py-2 rounded-md focus:outline-none"
                onClick={() => {
                  setShowMobileDrop(!showMobileDrop);
                }}
              >
                <span>Shop</span>
                <svg
                  className={`${
                    !showMobileDrop
                      ? "text-gray-300 rotate-180"
                      : "text-gray-300"
                  } w-4 h-4 ml-1`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 8a1 1 0 0 1 1.707-.707l6 6a1 1 0 0 1-1.414 1.414L7 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414l6-6z"
                  />
                </svg>
              </button>
              {showMobileDrop && (
                <div className="absolute right-0 mt-2 w-48 backdrop-blur-2xl bg-[#25374c] opacity-95 text-white rounded-md shadow-lg z-10">
                  <div className="py-1">
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        history("/shop");
                        setShowMobileDrop(false);
                        setShowNav(false);
                        setIsDropdownOpen(false);
                      }}
                      className="cursor-pointer text-white text-center py-0.5 hover:text-gray-300"
                    >
                      Classic Smart Cards
                    </p>
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        history("/shop");
                        setShowMobileDrop(false);
                        setShowNav(false);
                        setIsDropdownOpen(false);
                      }}
                      className="cursor-pointer text-white text-center py-0.5 hover:text-gray-300"
                    >
                      Premium Smart Cards
                    </p>
                  </div>
                </div>
              )} */}
          </div>
          <p
            className="cursor-pointer font-medium"
            onClick={(e) => {
              e.preventDefault();
              setShowNav(false);
              history("/shop");
            }}
          >
            Shop
          </p>
          <p
            className="cursor-pointer font-medium"
            onClick={(e) => {
              e.preventDefault();
              setShowNav(false);
              history("/");
              feature?.current?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Features
          </p>
          <p
            className="cursor-pointer font-medium"
            onClick={(e) => {
              e.preventDefault();
              history("/");
              setShowNav(false);
              footer?.current?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Contact Us
          </p>
          {cartData?.length > 0 ? (
            <p
              className="cursor-pointer font-medium relative"
              onClick={(e) => {
                e.preventDefault();
                history("/cart");
              }}
            >
              <span className="absolute mobile:top-[-3vw] md:top-[-1vw] mobile:right-[-3vw] md:right-[-1vw] bg-red-500 mobile:w-[5.5vw] md:w-[1.35vw] mobile:h-[5.5vw] md:h-[1.35vw] flex items-center justify-center rounded-full text-sm">
                {cartData?.length}
              </span>
              <AiOutlineShoppingCart size={30} />
            </p>
          ) : null}
          {profileMobileDrop && (
            <div className="absolute bottom-36 right-28 mt-2 w-36 backdrop-blur-2xl bg-[#25374c] opacity-95 text-white rounded-md shadow-lg z-10">
              <div className="py-1">
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    history("/user");
                    setProfileMobileDrop(false);
                  }}
                  className="cursor-pointer flex justify-center items-center text-white text-center py-0.5 hover:text-gray-300"
                >
                  <AiOutlineEdit className="mr-1" />
                  Edit Profile
                </p>
                <p
                  onClick={(e) => {
                    localStorage.setItem("token", "");
                    getProfile();
                    setProfileMobileDrop(false);
                    setShowNav(false);
                    e.preventDefault();
                  }}
                  className="cursor-pointer flex items-center justify-center text-white text-center py-0.5 hover:text-gray-300"
                >
                  <BiLogOut className="mr-1" />
                  Logout
                </p>
              </div>
            </div>
          )}
          {localStorage.getItem("token")?.length > 0 &&
          profile?.profilePicture ? (
            <div
              onClick={(e) => {
                e.preventDefault();
                setProfileMobileDrop(!profileMobileDrop);
              }}
            >
              <img
                src={profile?.profilePicture}
                alt={profile?.profilePicture}
                className="w-[13vw] h-[13vw] object-cover object-center rounded-full cursor-pointer"
              />
            </div>
          ) : (
            <div className="flex flex-col w-[35%]">
              <button
                className="btn mb-4"
                onClick={(e) => {
                  e.preventDefault();
                  history("/login");
                  setShowNav(false);
                }}
              >
                Log In
              </button>
              <button
                className="btn"
                onClick={(e) => {
                  e.preventDefault();
                  history("/bussiness/login");
                  setShowNav(false);
                }}
              >
                Business Log In
              </button>
            </div>
          )}
          <AiOutlineClose
            size={30}
            onClick={(e) => {
              setShowNav(false);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default Nav;
