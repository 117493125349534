import React, { useEffect } from "react";
import ShopNow from "../Components/ShopNow";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { BsCurrencyRupee } from "react-icons/bs";
import { ORDER_URI } from "../utils";
import { Player } from "@lottiefiles/react-lottie-player";

const PayCheck = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${ORDER_URI}/getOrder/${id}`)
      .then((res) => {
        if (res?.data?._id) {
          setOrderDetails(res.data);
          axios
            .post(`${ORDER_URI}/payment`, {
              amount: res?.data?.amount,
              order: id,
            })
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem("cart", JSON.stringify([]));
                history("/payment-success");
              } else if (res.status === 203) {
                history("/payment-fail");
              }
            })
            .catch((err) => {
              toast.error("Internal server error");
            });
        } else {
          history("/cart");
        }
      })
      .catch((err) => {
        toast.error("Internal server error");
      });
  }, []);

  return (
    <div className="overflow-x-hidden">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Nav />
      <ShopNow />
      <div className="bg-navyBlue w-full mobile:h-[100vh] md:h-[130vh] flex flex-col items-center justify-center -mt-[3.5vw] text-white">
        {/* <img src={img} alt={img} className="mobile:w-[70%] md:w-[25%]" />
         */}
        <div className="mobile:w-[50%] md:w-[20%] mx-auto">
          <Player
            src="https://assets1.lottiefiles.com/packages/lf20_myejiggj.json"
            className="player"
            loop
            autoplay
            speed={10}
          />
        </div>
        <h1 className="mobile:text-2xl md:text-4xl py-3 mt-1 font-bold">
          Your Order is Processing!
        </h1>
        <div className="flex items-center justify-between mobile:w-[80%] md:w-[25%]">
          <p>Amount Paid </p>
          <p className="flex items-center">
            <BsCurrencyRupee />
            {orderDetails?.amount} /-
          </p>
        </div>
        <button
          onClick={(e) => {
            history("/");
          }}
          className="bg-[#4fae6f] font-semibold px-4 py-1 rounded-lg mobile:mt-6 md:mt-2 cursor-pointer"
        >
          Home Page
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default PayCheck;
