import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Topbar from "../Components/Topbar";
import axios from "axios";
import { ORDER_URI } from "../../utils/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AiOutlineFilter, AiOutlineSearch } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router";
import TapKaroContext from "../../context/Context";

const Orders = () => {
  const [showFilter, setShowFilter] = useState(false);
  const context = useContext(TapKaroContext);

  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[80vw] pt-6 px-6">
        <Topbar title={"Orders"} />
        <div>
          <div className="flex justify-end">
            <div className="flex items-center relative">
              <AiOutlineSearch size={30} />
              <input
                type="text"
                value={context.search}
                onChange={(e) => {
                  context.setSearch(e.target.value);
                }}
                placeholder="Enter order number or name"
                className="border outline-none py-1 px-3 border-black rounded-lg mx-2 w-[20vw]"
              />
              <AiOutlineFilter
                size={30}
                onClick={(e) => {
                  e.preventDefault();
                  setShowFilter(!showFilter);
                }}
                className="cursor-pointer"
              />
              {showFilter ? (
                <div className="w-[40%] absolute right-[5%] top-[90%] rounded-md bg-black p-3">
                  <p
                    value="UnderDesign"
                    className="text-center py-0.5 UnderDesign"
                    onClick={(e) => {
                      e.preventDefault();
                      context.setFilter("UnderDesign");
                    }}
                  >
                    Under Design
                  </p>
                  <p
                    value="NewOrder"
                    className="text-center py-0.5 NewOrder"
                    onClick={(e) => {
                      e.preventDefault();
                      context.setFilter("NewOrder");
                    }}
                  >
                    New Order
                  </p>
                  <p
                    value="Completed"
                    className="text-center py-0.5 Completed"
                    onClick={(e) => {
                      e.preventDefault();
                      context.setFilter("Completed");
                    }}
                  >
                    Completed
                  </p>
                  <p
                    value="PaymentFailed"
                    className="text-center py-0.5 PaymentFailed"
                    onClick={(e) => {
                      e.preventDefault();
                      context.setFilter("PaymentFailed");
                    }}
                  >
                    Payment Failed
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="mt-3 h-[75vh] overflow-y-scroll">
            {context?.orders.map((e, i) => {
              return <Block data={e} key={i} />;
            })}
            {context?.size * context?.page <= context?.totalOrders ? (
              <button
                className="text-white bg-black px-4 py-0.5 rounded-lg"
                onClick={(e) => {
                  e.preventDefault();
                  context?.setPage(context?.page + 1);
                  context?.getOrders();
                }}
              >
                Load More
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const Block = ({ data }) => {
  const history = useNavigate();
  const [status, setStatus] = useState(data.status);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          history(`/admin/orders/${data._id}`);
        }}
        className="w-[98%] bg-adminlightBlue mb-2 rounded-xl px-6 py-2 flex items-center justify-between m-auto cursor-pointer"
      >
        <div className="text-lg">
          <p className="font-bold">Order no : #{data.orderId}</p>
          <p>
            {data.fName +
              " " +
              (data.mName ? data.mName : "") +
              " " +
              data.lName}
          </p>
          <p>
            <span className="font-bold">Address: </span>
            {data.address +
              ", " +
              data.street +
              ", " +
              data.city +
              ", " +
              data.state +
              ", " +
              data.country +
              " " +
              data.pinCode}
          </p>
        </div>
        <select
          className={`outline-none px-6 py-1 rounded-lg ${status}`}
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
            axios
              .put(`${ORDER_URI}/updateStatus`, {
                id: data._id,
                status: e.target.value,
              })
              .then((response) => {
                toast.success("Status updated successfully");
              })
              .catch((err) => {
                toast.error("Error updating status");
              });
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <option value="UnderDesign" className="UnderDesign">
            Under Design
          </option>
          <option value="NewOrder" className="NewOrder">
            New Order
          </option>
          <option value="Completed" className="Completed">
            Completed
          </option>
          <option value="PaymentFailed" className="PaymentFailed">
            Payment Failed
          </option>
        </select>
      </div>
    </>
  );
};

export default Orders;
