import React, { useEffect, useRef, useState } from "react";

import logo from "../images/tap karo logo.png";
import { BASE_URI, LOGIN_URI, PROFILE_URI } from "../utils/index";

import { MdEmail } from "react-icons/md";
import { BsKeyFill } from "react-icons/bs";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { AiFillLock, AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router";
import axios from "axios";
import Axios from "axios";
import Captcha from "../Components/Captcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const history = useNavigate();
  const captchaRef = useRef(null);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    otp: "",
  });

  useEffect(() => {
    if (localStorage.getItem("id")) {
      axios
        .get(`${PROFILE_URI}/${localStorage.getItem("id")}`)
        .then((res) => {
          setData({ name: res?.data?.name, email: res?.data?.email });
        })
        .catch((err) => {});
    }
  }, []);

  const [showPassword, setshowPassword] = useState(false);
  const [otp, setOtp] = useState();
  const [showOtpBox, setShowOtpBox] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Nav />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="h-[100vh] w-full bg-navyBlue flex justify-center items-center">
        <div className="border flex flex-col border-gray-700 mobile:py-3 md:py-4 items-center text-white rounded-3xl backdrop-blur-xl mobile:w-[90%] md:w-[25%]">
          <img src={logo} alt={logo} className="w-[30%]" />
          <h1 className="text-2xl mt-3 mb-1">Register Now</h1>
          <div className="mobile:w-[85%] md:w-[80%] m-auto">
            <div className="flex items-center">
              <AiOutlineUser size={22} className="absolute left-[13%]" />
              <input
                type="text"
                required={true}
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
                placeholder="Name"
                className="border my-2 outline-none bg-transparent py-1 px-3 rounded-lg w-[100%] relative mobile:pl-12 md:pl-10 tracking-widest"
              />
            </div>
            <div className="flex items-center">
              <MdEmail size={22} className="absolute left-[13%]" />
              <input
                type="text"
                required={true}
                value={data.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
                placeholder="Email address"
                className="border my-2 outline-none bg-transparent py-1 px-3 rounded-lg w-[100%] relative mobile:pl-12 md:pl-10 tracking-widest"
              />
            </div>
            <div className="flex items-center">
              <BsKeyFill size={24} className="absolute left-[13%]" />
              <input
                value={data.password}
                required={true}
                onChange={(e) => {
                  setData({ ...data, password: e.target.value });
                }}
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                className="border my-2 outline-none bg-transparent py-1 mobile:pl-12 md:pl-10 pr-16 rounded-lg w-[100%] relative tracking-widest"
              />
              <p
                onClick={(e) => {
                  e.preventDefault();
                  setshowPassword(!showPassword);
                }}
                className="absolute right-[15%] cursor-pointer transition-all font-medium text-gray-400 hover:text-white"
              >
                {showPassword ? "Hide" : "Show"}
              </p>
            </div>
            <div className="flex items-center w-[100%] justify-center my-2">
              <Captcha captchaRef={captchaRef} />
            </div>
            {showOtpBox ? (
              <>
                <div className="flex items-center">
                  <AiFillLock size={24} className="absolute left-[13%]" />
                  <input
                    value={data.otp}
                    onChange={(e) => {
                      setData({ ...data, otp: e.target.value });
                    }}
                    placeholder="OTP"
                    type={"number"}
                    className="border my-2 outline-none bg-transparent py-1 mobile:pl-12 md:pl-10 pr-2 rounded-lg w-[100%] relative tracking-widest"
                  />
                </div>
                <p className="text-end text-sm text-gray-300 float-right mb-1">
                  Check your inbox. If it doesn’t appear within a few minutes,
                  check your spam folder.
                </p>
              </>
            ) : null}
            {!showOtpBox ? (
              <button
                onClick={async (e) => {
                  e.preventDefault();
                  let token = captchaRef.current.getValue();

                  if (token) {
                    let response = await Axios.post(`${BASE_URI}verify-token`, {
                      reCAPTCHA_TOKEN: token,
                    });
                    if (
                      response.status === 200 &&
                      response.data.verification_info.success === true
                    ) {
                      if (data.email) {
                        axios
                          .post(`${LOGIN_URI}/otp-verification`, {
                            email: data.email,
                          })
                          .then((res) => {
                            if (res.data.otp) {
                              setOtp(res.data.otp);
                              setShowOtpBox(!showOtpBox);
                            } else {
                              toast.success(res.data.data);
                            }
                          })
                          .catch((err) => {
                            toast.error("Internal server error");
                          });
                      } else {
                        toast.warning("Please Enter Email Address");
                      }
                    }
                  } else {
                    toast.warning("User not verified");
                  }
                }}
                className="bg-lightBlue w-full mt-1 py-1 rounded-lg font-medium text-white hover:bg-white hover:text-lightBlue"
              >
                Verify Email
              </button>
            ) : (
              <button
                onClick={async (e) => {
                  e.preventDefault();
                  if (!data.email || !data.password || !data.name) {
                    toast.warning("Please Fill all the details");
                  } else if (data.otp !== otp) {
                    toast.warning("OTP is not correct");
                  } else if (
                    data.email &&
                    data.password &&
                    data.name &&
                    data.otp === otp
                  ) {
                    axios
                      .post(`${LOGIN_URI}/signup`, {
                        ...data,
                        id: localStorage.getItem("id"),
                      })
                      .then((res) => {
                        if (res.status === 200) {
                          toast.success("Registered Successfully");
                          setData({
                            name: "",
                            email: "",
                            password: "",
                            otp: "",
                          });
                          history("/Login");
                          localStorage.setItem("id", "");
                          captchaRef.current.reset();
                        } else {
                          toast.warning(res.data.data);
                          captchaRef.current.reset();
                        }
                      })
                      .catch((err) => {
                        toast.error("Internal server error");

                        captchaRef.current.reset();
                      });
                  }
                }}
                className="bg-lightBlue w-full mt-1 py-1 rounded-lg font-medium text-white hover:bg-white hover:text-lightBlue"
              >
                Log In
              </button>
            )}
            <p
              className="text-sm text-center mt-2 cursor-pointer text-gray-300 hover:text-white"
              onClick={(e) => {
                e.preventDefault();
                history("/Login");
              }}
            >
              Already Have an account?
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
