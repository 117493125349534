import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import bg from "../../images/user/fbg.jpg";
import { PROFILE_URI } from "../../utils";
import axios from "axios";
import TapKaroContext from "../../context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { data as codes } from "../../data/countryCode.js";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth < 550 ? "80vw" : "20vw",
    borderRadius: "0.5rem",
    backgroundImage: `url(${bg})`,
    padding: 0,
    backgroundPosition: "center",
  },
};

const ReactModal = ({ setIsOpen, modalIsOpen, iconData, type }) => {
  const [icons, setIcons] = useState([
    "facebook",
    "instagram",
    "whatsapp",
    "youtube",
    "twitter",
    "linkedin",
    "call-1",
    "call-2",
    "call-3",
    "call-4",
    "call-5",
    "google-reviews",
    "indiamart",
    "justdial",
    "meesho",
    "snapchat",
    "telegram",
    "threads",
  ]);

  useEffect(() => {
    if (type === true) {
      setIcons(["paytm", "phonepe", "google pay"]);
      setData({ ...data, name: "paytm" });
    } else {
      setIcons([
        "facebook",
        "instagram",
        "whatsapp",
        "youtube",
        "twitter",
        "linkedin",
        "Landline Call",
        "Office Call",
        "call-1",
        "call-2",
        "call-3",
        "google-reviews",
        "indiamart",
        "justdial",
        "meesho",
        "snapchat",
        "telegram",
        "threads",
      ]);
      setData({ ...data, name: "facebook" });
    }
  }, [type]);

  const { getProfile } = useContext(TapKaroContext);
  const [data, setData] = useState({
    name: "facebook",
    url: "",
    visibility: false,
    cCode: 91,
  });
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (iconData) {
      let icon = icons.find((e) => {
        return e.toLowerCase().includes(iconData?.name?.toLowerCase());
      });
      setData({
        name: icon,
        url: iconData?.url,
        cCode: iconData?.cCode ? iconData?.cCode : 91,
        visibility: iconData?.visibility,
      });
    } else {
      setData({
        name: "facebook",
        url: "",
        cCode: iconData?.cCode ? iconData?.cCode : 91,
      });
    }
  }, [iconData]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="text-white bg-transparent h-full w-full px-5 p-4">
          <label htmlFor="social">
            Add {icons.includes("paytm") ? "UPI" : "Social media"} :
          </label>
          <select
            id="social"
            value={data?.name}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
            disabled={iconData?.name}
            className="outline-none block my-1 bg-transparent w-full px-2 border-b pb-1 border-b-gray-400 mb-6 capitalize"
          >
            {icons.map((e, i) => {
              return (
                <option key={i} value={e} className="text-navyBlue capitalize">
                  {e}
                </option>
              );
            })}
          </select>
          <label htmlFor="input">
            {data?.name === "whatsapp" ||
            data?.name?.toLowerCase().includes("call")
              ? "Enter Number"
              : icons.includes("paytm")
              ? "Link"
              : "Profile Link"}{" "}
            :
          </label>
          <div className="flex items-center">
            {data?.name?.toLowerCase().includes("call") ? (
              <select
                value={data?.cCode}
                className="border bg-transparent mb-1.5 py-0.5 w-[28%] mr-2 text-sm h-full rounded-lg px-0.5 outline-none"
                onChange={(e) => {
                  setData({ ...data, cCode: e.target.value });
                }}
              >
                {codes?.map((e, i) => {
                  return (
                    <option
                      value={e?.code}
                      key={i}
                      className="text-darkNavyBlue"
                    >
                      {e?.country}-{e?.code}
                    </option>
                  );
                })}
              </select>
            ) : null}
            <input
              type={
                data?.name === "whatsapp" ||
                data?.name?.toLowerCase().includes("call")
                  ? "number"
                  : "text"
              }
              value={data.url}
              onChange={(e) => {
                setData({ ...data, url: e.target.value });
              }}
              id="input"
              placeholder={
                data?.name === "whatsapp" ||
                data?.name?.toLowerCase().includes("call")
                  ? "Enter number"
                  : "Enter text"
              }
              className="outline-none block px-2 my-1 bg-transparent w-full border-b border-b-gray-400 mb-3"
            />
          </div>
          {iconData ? (
            <>
              <label htmlFor="input">Visibility :</label>
              <input
                class="mr-2 mt-[0.3rem] float-right h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                type="checkbox"
                role="switch"
                id="flexSwitchChecked"
                checked={data.visibility}
                onChange={(e) => {
                  setData({ ...data, visibility: !data.visibility });
                }}
              />
            </>
          ) : null}
          {data?.name === "whatsapp" ||
          data?.name?.toLowerCase().includes("call") ? null : (
            <p className="text-sm text-right">
              Please add url with https://test.com/user
            </p>
          )}
          <button
            onClick={(e) => {
              e.preventDefault();
              if (data?.url) {
                if (iconData) {
                  if (iconData?.name.toLowerCase().includes("call")) {
                    if (data?.url.length !== 10) {
                      toast.warn("Phone number length must be 10 characters");
                    } else {
                      axios
                        .post(`${PROFILE_URI}/user/updateIcon`, {
                          name: iconData?.name,
                          url: data?.url ? data?.url : iconData?.url,
                          cCode: data?.cCode ? data?.cCode : iconData?.cCode,
                          visibility: data?.visibility,
                          token: localStorage.getItem("token"),
                        })
                        .then((res) => {
                          getProfile();
                          setIsOpen(!modalIsOpen);
                        })
                        .catch((err) => {
                          toast.error("Internal server error");
                        });
                    }
                  } else {
                    axios
                      .post(`${PROFILE_URI}/user/updateIcon`, {
                        name: iconData?.name,
                        url: data?.url ? data?.url : iconData?.url,
                        cCode: data?.cCode ? data?.cCode : iconData?.cCode,
                        visibility: data?.visibility,
                        token: localStorage.getItem("token"),
                      })
                      .then((res) => {
                        getProfile();
                        setIsOpen(!modalIsOpen);
                      })
                      .catch((err) => {
                        toast.error("Internal server error");
                      });
                  }
                } else {
                  if (data?.name?.toLowerCase().includes("call")) {
                    if (data?.url.length !== 10) {
                      toast.warn("Phone number length must be 10 characters");
                    } else {
                      axios
                        .post(`${PROFILE_URI}/user/addIcon`, {
                          ...data,
                          token: localStorage.getItem("token"),
                        })
                        .then((res) => {
                          if (res.status === 203) {
                            toast.warning(`${data?.name} already exists`);
                          } else if (res.status === 200) {
                            getProfile();
                            setIsOpen(!modalIsOpen);
                          }
                        })
                        .catch((err) => {
                          toast.error("Internal server error");
                        });
                    }
                  } else {
                    axios
                      .post(`${PROFILE_URI}/user/addIcon`, {
                        ...data,
                        token: localStorage.getItem("token"),
                      })
                      .then((res) => {
                        if (res.status === 203) {
                          toast.warning(`${data?.name} already exists`);
                        } else if (res.status === 200) {
                          getProfile();
                          setIsOpen(!modalIsOpen);
                        }
                      })
                      .catch((err) => {
                        toast.error("Internal server error");
                      });
                  }
                }
              } else {
                toast.warning("Please add the url");
              }
            }}
            className="bg-lightBlue block mx-auto mt-3 px-4 py-1 rounded-lg"
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ReactModal;
