import React, { useContext, useEffect, useState } from "react";
import Footer from "../Components/Footer";
import TapKaroContext from "../../context/Context";
import { MdCall } from "react-icons/md";

const Connections = () => {
  const { profile, getProfile } = useContext(TapKaroContext);

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="w-[100vw] m-auto h-[100vh] text-white bg-fbg bg-cover">
      <div className="w-full h-full pt-2 px-4">
        <h1 className="text-2xl font-bold mb-3">Connections</h1>
        <div className="h-[82vh] overflow-y-auto">
          {profile?.connections.map((e, i) => {
            return <Block user={e} key={i} />;
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Block = ({ user }) => {
  return (
    <div className="bg-gradient-to-b from-[#fb00ff91] via-[#00dade7e] to-[#ffffff65] w-full mb-3 p-1.5 rounded-lg flex items-center justify-between">
      <div className="flex items-center">
        <img
          src={
            "https://res.cloudinary.com/dpbsogbtr/image/upload/v1689919391/ps6lbdb0seizwwtdcfui.png"
          }
          alt="user"
          className="w-[15vw] rounded-full object-cover object-center"
        />
        <div className="ml-3">
          <h1 className="font-semibold text-lg">{user?.name}</h1>
          <p className="font-light -mt-1">{user?.phone}</p>
          <p className="font-light -mt-1">{user?.designation}</p>
        </div>
      </div>
      {user?.phone ? (
        <div>
          <a href={`tel:${user?.phone}`}>
            <MdCall size={25} className="" />
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default Connections;
