import React, { useEffect, useState } from "react";
import image from "../../images/shop now graphic.png";
import image2 from "../../images/Business graphic.png";
import image3 from "../../images/Rectangle 13.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useNavigate } from "react-router";
import ConnectPopUp from "../../Components/ConnectPopUp";

const Shop = ({ feature }) => {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.fromTo(
      "#left",
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#top",
          start: window.innerWidth < 550 ? "top 90%" : "top 50%",
          end: window.innerWidth < 550 ? "top 50%" : "top 10%",
          // scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#right",
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#top",
          start: window.innerWidth < 550 ? "top 90%" : "top 50%",
          end: window.innerWidth < 550 ? "top 50%" : "top 10%",
          // scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#leftCard",
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#topCard",
          start: "top 50%",
          end: "top 10%",
          // scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#rightCard",
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#topCard",
          start: "top 50%",
          end: "top 10%",
          // scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#leftFeature",
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#topFeature",
          start: "top 50%",
          end: "top 10%",
          // scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#rightFeature",
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#topFeature",
          start: "top 50%",
          end: "top 10%",
          // scrub: true,
        },
      }
    );
  }, []);

  const history = useNavigate();
  const [isBussiness, setIsBussiness] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <>
      <ConnectPopUp
        isBussiness={isBussiness}
        setIsBussiness={setIsBussiness}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
      />
      <div className="md:h-fit mobile:h-fit mobile:py-7 md:py-0 w-full bg-darkNavyBlue mobile:px-[4vw] md:px-[6vw]">
        <div
          id="top"
          className="md:h-[100vh] mobile:h-fit flex mobile:flex-col md:flex-row items-center justify-between text-white md:pt-10 mobile:py-24"
        >
          <div
            id="left"
            className="md:block mobile:flex mobile:flex-col mobile:items-center md:w-[40%] mobile:w-full"
          >
            <h1 className="mobile:text-3xl md:text-4xl font-bold md:text-start mobile:text-center">
              Create Team Cards by TAP KARO
            </h1>
            <p className="md:pr-8 mobile:pr-0 py-4 md:text-start mobile:text-center text-xl mobile:px-4 md:px-0">
              <li className="">Create Cards for New Hires</li>{" "}
              <li className="">Enable / Disable any Card</li>{" "}
              <li className="">More Leads & More Sales</li>
            </p>
            <button
              className="btn"
              onClick={(e) => {
                e.preventDefault();
                setIsBussiness(true);
                setIsOpen(!modalIsOpen);
              }}
            >
              Book a Demo
            </button>
          </div>
          <img
            id="right"
            src={image2}
            alt={image2}
            className="md:-mr-7 md:mt-0 mobile:mt-4 md:w-[70%] mobile:w-full"
          />
        </div>
        <div
          id="topCard"
          className="h-[100vh] md:pb-0 mobile:pb-24 flex md:flex-row mobile:flex-col justify-evenly mobile:mt-6 md:mt-0 items-center"
        >
          <img
            id="leftCard"
            src={image3}
            alt={image3}
            className="md:w-[80%] mobile:w-full mobile:object-cover md:object-contain mobile:h-fit md:h-[80%] rounded-2xl mobile:mb-3 md:mb-0"
          />
          <div
            id="rightCard"
            className="text-white md:block mobile:flex mobile:flex-col mobile:items-center md:w-full mobile:px-5 md:px-0"
          >
            <h1 className="mobile:text-5xl md:text-6xl font-extrabold mobile:text-center md:text-end md:mb-0 mobile:mb-4">
              Shop Now
            </h1>
            <div className="mobile:pt-1 text-lg md:py-5 md:pl-16 mobile:pl-0 md:text-end mobile:text-center mobile:mb-2 md:mb-0">
              <p>TAP KARO NFC & QR Cards</p>
              <p>Standard Customisation </p>
              <p>Metal Cards with Embossed Name</p>
              <p>Social Influencer Cards</p>
              <p>Instagram / Youtube / Linkedin Cards</p>
            </div>
            <button
              className="btn float-right"
              onClick={(e) => {
                e.preventDefault();
                history("/shop");
              }}
            >
              Explore Now
            </button>
          </div>
        </div>
        <div
          id="topFeature"
          ref={feature}
          className="md:h-[100vh] mobile:h-fit flex mobile:flex-col md:flex-row items-center justify-between text-white md:pt-10 mobile:py-24 mobile:px-0 md:px-1"
        >
          <div
            id="leftFeature"
            className="md:block mobile:flex mobile:flex-col mobile:items-center md:w-[40%] mobile:w-full md:pl-5 mobile:pl-0"
          >
            <h1 className="text-4xl font-bold md:text-start mobile:text-center">
              TAP KARO Features
            </h1>
            <p className="md:pr-8 mobile:pr-0 py-4 md:text-start mobile:text-center text-xl mobile:px-4 md:px-0">
              <li>A state of the art profile to share</li>
              <li>Update & share all social media links</li>
              <li>Show QR code & share details</li>
              <li>Share and get connected</li>
              <li>Insightful data of your networks</li>
            </p>
          </div>
          <img
            id="rightFeature"
            src={image}
            alt={image}
            className="md:-mr-7 md:mt-0 mobile:mt-4 md:w-[50%] mobile:w-full"
          />
        </div>
      </div>
    </>
  );
};

export default Shop;
