import React, { useContext, useEffect, useState } from "react";
import facebook from "../../images/icons/facebook.png";
import linkedin from "../../images/icons/linkedin.png";
import instagram from "../../images/icons/instagram.png";
import youtube from "../../images/icons/youtube.png";
import whatsapp from "../../images/icons/whatsapp.png";
import plus from "../../images/icons/plus.png";
import paytm from "../../images/icons/paytm.png";
import gpay from "../../images/icons/google-pay.png";
import phonepe from "../../images/icons/phonepe.png";
import { useNavigate } from "react-router";
import ReactModal from "../../Mobile/Components/ReactModal";
import TapKaroContext from "../../context/Context";
import twitter from "../../images/icons/twitter.png";
import calling from "../../images/icons/call.png";
import reviews from "../../images/icons/google-reviews.png";
import indiamart from "../../images/icons/indiamart.png";
import justdial from "../../images/icons/justdial.png";
import meesho from "../../images/icons/meesho.png";
import snapchat from "../../images/icons/snapchat.png";
import telegram from "../../images/icons/telegram.png";
import threads from "../../images/icons/threads.png";
import { MdCall } from "react-icons/md";
import { AiOutlineArrowLeft } from "react-icons/ai";

const ViewConnections = () => {
  const history = useNavigate();
  const { getProfile, profile } = useContext(TapKaroContext);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getProfile();
    } else {
      history("/login");
    }
  }, []);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [iconData, setIconData] = useState();
  const [type, setType] = useState(false);

  return (
    <>
      <ReactModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        iconData={iconData}
        type={type}
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          history("/user");
        }}
        className="p-1 bg-white rounded-full absolute left-0 top-0 z-50 text-darkNavyBlue m-2 cursor-pointer"
      >
        <AiOutlineArrowLeft size={22} className="cursor-pointer" />
      </div>
      <div className="bg-fbg w-[100vw] h-[100vh] bg-no-repeat bg-cover">
        <div className="backdrop-blur-md w-full h-full flex justify-between">
          <div className="w-[75vw]">
            <div
              style={{
                backgroundImage: `url(${profile?.coverPicture})`,
              }}
              className="h-[35%] bg-cover bg-no-repeat bg-bottom"
            ></div>
            <div className="h-[60%] px-3 text-white">
              <div className="flex items-start justify-between my-2.5 -mt-[35px]">
                <div className="w-[60%] flex items-end">
                  <img
                    src={profile?.profilePicture}
                    alt="user"
                    className="w-[8.5vw] h-[8.5vw] object-cover object-center rounded-lg"
                  />
                  <div className="ml-3">
                    <h1 className="text-2xl font-semibold">{profile?.name}</h1>
                    <h1 className="text-lg">{profile?.designation}</h1>
                    <h1 className="text-lg">{profile?.company}</h1>
                  </div>
                </div>
                <div className="mt-12">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      history(`/user/edit/`);
                    }}
                    className="bg-lightBlue px-5 py-0.5 mr-2.5 rounded-lg"
                  >
                    Edit Profile
                  </button>
                  <button className="bg-lightBlue px-5 py-0.5 mr-2.5 rounded-lg">
                    My Connections
                  </button>
                </div>
              </div>
              <div className="mt-7 px-2">
                <div>
                  <div className="flex items-center flex-wrap my-2">
                    {profile?.icons
                      ?.filter((e) => {
                        return e?.type?.toLowerCase().includes("social");
                      })
                      .map((e, i) => {
                        return (
                          <Icons
                            key={i}
                            modalIsOpen={modalIsOpen}
                            setIsOpen={setIsOpen}
                            iconData={iconData}
                            setIconData={setIconData}
                            img={
                              e?.name?.toLowerCase().includes("face")
                                ? facebook
                                : e?.name?.toLowerCase().includes("insta")
                                ? instagram
                                : e?.name?.toLowerCase().includes("what")
                                ? whatsapp
                                : e?.name?.toLowerCase().includes("you")
                                ? youtube
                                : e?.name?.toLowerCase().includes("twitter")
                                ? twitter
                                : e?.name?.toLowerCase().includes("linkedin")
                                ? linkedin
                                : e?.name?.toLowerCase().includes("call")
                                ? calling
                                : e?.name?.toLowerCase().includes("reviews")
                                ? reviews
                                : e?.name?.toLowerCase().includes("justdial")
                                ? justdial
                                : e?.name?.toLowerCase().includes("meesho")
                                ? meesho
                                : e?.name?.toLowerCase().includes("snapchat")
                                ? snapchat
                                : e?.name?.toLowerCase().includes("telegram")
                                ? telegram
                                : e?.name?.toLowerCase().includes("threads")
                                ? threads
                                : indiamart
                            }
                            data={e}
                          />
                        );
                      })}
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setType(false);
                      }}
                    >
                      <Icons
                        modalIsOpen={modalIsOpen}
                        setIsOpen={setIsOpen}
                        img={plus}
                        padding={true}
                        iconData={iconData}
                        setIconData={setIconData}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex items-center flex-wrap my-2">
                    {profile?.icons
                      ?.filter((e) => {
                        return e?.type?.toLowerCase().includes("upi");
                      })
                      .map((e, i) => {
                        return (
                          <Icons
                            key={i}
                            modalIsOpen={modalIsOpen}
                            setIsOpen={setIsOpen}
                            iconData={iconData}
                            setIconData={setIconData}
                            img={
                              e?.name?.toLowerCase().includes("paytm")
                                ? paytm
                                : e?.name?.toLowerCase().includes("phonepe")
                                ? phonepe
                                : gpay
                            }
                            data={e}
                          />
                        );
                      })}
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setType(true);
                      }}
                    >
                      <Icons
                        modalIsOpen={modalIsOpen}
                        iconData={iconData}
                        setIconData={setIconData}
                        setIsOpen={setIsOpen}
                        img={plus}
                        padding={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-rbg h-full w-[25vw] flex flex-col items-center justify-center text-white px-4">
            <div className="w-full h-full pt-2 px-4">
              <h1 className="text-2xl font-bold mb-3">Connections</h1>
              <div className="h-[82vh] overflow-y-auto">
                {profile?.connections.map((e, i) => {
                  return <Block user={e} key={i} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Block = ({ user }) => {
  return (
    <div className="bg-gradient-to-b from-[#fb00ff91] via-[#00dade7e] to-[#ffffff65] w-full mb-3 px-3 py-1.5 rounded-lg flex items-center justify-between">
      <div className="flex items-center">
        <img
          src={
            "https://res.cloudinary.com/dpbsogbtr/image/upload/v1689919391/ps6lbdb0seizwwtdcfui.png"
          }
          alt="user"
          className="w-[4.5vw] h-[4.5vw] object-cover object-center rounded-lg"
        />
        <div className="ml-3">
          <h1 className="font-semibold text-lg">{user?.name}</h1>
          <p className="font-light -mt-1">{user?.phone}</p>
          <p className="font-light -mt-1">{user?.designation}</p>
        </div>
      </div>
      {user?.phone ? (
        <div>
          <a href={`tel:${user?.phone}`}>
            <MdCall size={25} className="" />
          </a>
        </div>
      ) : null}
    </div>
  );
};

const Icons = ({ img, padding, modalIsOpen, setIsOpen, setIconData, data }) => {
  return (
    <div
      className={`${
        padding ? "p-3 bg-[rgba(255,255,255,0.2)] w-[12%]" : "p-0 w-[6.5%]"
      } backdrop-blur-md rounded-lg mr-4 cursor-pointer mb-3`}
      onClick={(e) => {
        e.preventDefault();
        setIsOpen(!modalIsOpen);
        setIconData(data);
      }}
    >
      <img src={img} alt={img} />
    </div>
  );
};

export default ViewConnections;
