import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Footer from "../Components/Footer";
import { AiOutlineEdit } from "react-icons/ai";
import TapKaroContext from "../../context/Context";
import { PROFILE_URI } from "../../utils";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InfinitySpin } from "react-loader-spinner";

const EditProfile = () => {
  const history = useNavigate();
  const { profile, getProfile } = useContext(TapKaroContext);
  const [data, setData] = useState({
    name: "",
    designation: "",
    company: "",
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getProfile();
      setData({
        name: profile?.name,
        designation: profile?.designation,
        company: profile?.company,
      });
    } else {
      history("/login");
    }
  }, []);

  const { id } = useParams();
  const [backgroundImg, setBackgroundImg] = useState();
  const [profileImg, setProfileImg] = useState();
  const [username, setUsername] = useState(profile?.username);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setUsername(profile?.username);
  }, []);

  return (
    <div className="w-[100vw] m-auto border border-black h-[100vh] text-white">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        style={{
          backgroundImage: `url(${
            backgroundImg ? backgroundImg : profile?.coverPicture
          })`,
        }}
        className="relative bg-cover bg-blend-color-dodge h-[20vh] bg-bottom flex justify-end flex-col items-center"
      >
        <label htmlFor="fileCover" className="underline cursor-pointer">
          <input
            type="file"
            id="fileCover"
            className="border inline-block px-2 py-2 cursor-pointer"
            style={{ display: "none" }}
            onChange={(e) => {
              setLoader(true);
              const formData = new FormData();
              formData.append("file", e.target.files[0]);
              formData.append("upload_preset", "upload_photo");
              formData.append("cloud_name", "dpbsogbtr");

              fetch("https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload", {
                method: "POST",
                body: formData,
              })
                .then((res) => res.json())
                .then((res) => {
                  setBackgroundImg(res.url);
                  setLoader(false);
                })
                .catch((err) => {
                  setLoader(false);
                });
            }}
          />
          <AiOutlineEdit
            size={28}
            color="black"
            className="bg-white p-1 rounded-full absolute top-0 right-0 m-2"
          />
        </label>
        <img
          src={profileImg ? profileImg : profile?.profilePicture}
          alt="user"
          className="w-[26vw] h-[26vw] object-cover object-center rounded-lg z-0 -mb-10"
        />
        <label htmlFor="fileProfile" className="underline cursor-pointer">
          <input
            type="file"
            id="fileProfile"
            className="border inline-block px-2 py-2 cursor-pointer"
            style={{ display: "none" }}
            onChange={(e) => {
              setLoader(true);
              const formData = new FormData();
              formData.append("file", e.target.files[0]);
              formData.append("upload_preset", "upload_photo");
              formData.append("cloud_name", "dpbsogbtr");

              fetch("https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload", {
                method: "POST",
                body: formData,
              })
                .then((res) => res.json())
                .then((res) => {
                  setProfileImg(res.url);
                  setLoader(false);
                })
                .catch((err) => {
                  setLoader(false);
                });
            }}
          />
          <AiOutlineEdit
            size={25}
            color="black"
            className="bg-white p-1 rounded-full absolute -bottom-[38%] ml-10 z-10"
          />
        </label>
      </div>
      <div className="h-[78vh] bg-fbg bg-cover">
        {loader ? (
          <div className="w-full flex justify-center items-center h-full">
            <InfinitySpin width="300" color="#fff" />
          </div>
        ) : (
          <div className="w-full h-full pt-12 px-4">
            <h1 className="text-2xl font-semibold mb-3 text-center">
              Edit Profile
            </h1>
            <div className="flex items-center justify-center mb-2 mx-auto">
              <input
                type="text"
                value={username}
                className="outline-none bg-transparent text-xl pb-1 text-center border-b w-[50%] px-0.5 border-b-gray-600"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              {profile?.username !== username ? (
                <button
                  onClick={(e) => {
                    axios
                      .post(`${PROFILE_URI}/edit/username/${profile?._id}`, {
                        username,
                      })
                      .then((res) => {
                        if (res.status === 203) {
                          toast.warning("Username already exists");
                        } else {
                          toast.success("Username updated successfully");
                          getProfile();
                        }
                      })
                      .catch((err) => {
                      });
                  }}
                  className="bg-lightBlue px-5 ml-2 rounded-lg"
                >
                  Save
                </button>
              ) : null}
            </div>
            <input
              type="text"
              value={data.name}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
              className="mb-4 text-center pb-1 bg-transparent border-b-gray-500 border-b outline-none w-[70%] block m-auto text-lg"
              placeholder="Name"
            />
            <input
              type="text"
              value={data.designation}
              onChange={(e) => {
                setData({ ...data, designation: e.target.value });
              }}
              className="mb-4 text-center pb-1 bg-transparent border-b-gray-500 border-b outline-none w-[70%] block m-auto text-lg"
              placeholder="Designation"
            />
            <input
              type="text"
              value={data.company}
              onChange={(e) => {
                setData({ ...data, company: e.target.value });
              }}
              className="mb-4 text-center pb-1 bg-transparent border-b-gray-500 border-b outline-none w-[70%] block m-auto text-lg"
              placeholder="Company"
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                axios
                  .post(`${PROFILE_URI}/user/edit`, {
                    token: localStorage.getItem("token"),
                    name: data?.name ? data?.name : profile?.name,
                    designation: data?.designation
                      ? data?.designation
                      : profile?.designation,
                    company: data?.company ? data?.company : profile?.company,
                    profilePicture: profileImg
                      ? profileImg
                      : profile?.profilePicture,
                    coverPicture: backgroundImg
                      ? backgroundImg
                      : profile?.coverPicture,
                  })
                  .then((res) => {
                    history("/user");
                    getProfile();
                  })
                  .catch((err) => {});
              }}
              className="bg-lightBlue m-auto block px-6 py-1 my-3 rounded-lg"
            >
              Save Updates
            </button>
            <p className="text-sm text-end w-[50%] float-right">
              Suggested Banner Image Size 1026 X 218 Pixels
            </p>
          </div>
        )}
        <Footer id={id} />
      </div>
    </div>
  );
};

export default EditProfile;
