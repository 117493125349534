import React, { useContext, useEffect, useRef } from "react";
import Footer from "../Components/Footer";
import QRCodeStyling from "qr-code-styling";
import obj from "../../Components/QR";
import { AiOutlineCopy, AiOutlineWhatsApp } from "react-icons/ai";
import TapKaroContext from "../../context/Context";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewQr = () => {
  const qr = useRef(null);
  const history = useNavigate();
  const { profile, getProfile } = useContext(TapKaroContext);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getProfile();
    } else {
      history("/login");
    }
  }, []);

  const qrCode = new QRCodeStyling({
    width: 150,
    height: 150,
    ...obj,
    data: `${window.location.origin}/${profile?.username}`,
  });

  useEffect(() => {
    qrCode.update({
      data: `${window.location.origin}/${profile?.username}`,
    });
    if (!qr?.current?.innerHTML.includes("canvas")) {
      qrCode.append(qr.current);
    }
  }, [profile]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="w-[100vw] m-auto h-[100vh] text-white bg-fbg bg-cover">
        <div className="w-full h-full pt-2 px-4 flex justify-center items-center flex-col">
          <div ref={qr} className="bg-white p-1.5 rounded-lg"></div>
          <div className="mt-4 flex items-center relative w-[65%]">
            <input
              type="text"
              value={`${window.location.origin}/${profile?.username}`}
              className="block bg-transparent border text-sm border-white py-1.5 pl-2 w-full rounded-lg pr-9 outline-none"
            />
            <AiOutlineCopy
              onClick={(e) => {
                e.preventDefault();
                copy(`${window.location.origin}/${profile?.username}`);
                toast.success("Copied success");
              }}
              size={26}
              className="absolute right-1 cursor-pointer"
            />
          </div>
          <div className="w-full px-4 mt-8">
            {/* <div className="bg-white text-black flex items-center w-full px-3 mb-3 py-1 rounded-lg">
            <AiOutlineShareAlt size={23} />
            <p className="text-lg ml-2 font-medium">Share Profile Link</p>
          </div> */}
            <div
              onClick={(e) => {
                window.open(
                  `whatsapp://send?text=${window.location.origin}/${profile?._id}`
                );
              }}
              className="bg-white text-black flex items-center w-full px-3 mb-3 py-1 rounded-lg"
            >
              <AiOutlineWhatsApp size={23} color="#25D366" />
              <p className="text-lg ml-2 font-medium">
                Share Profile Via Whatsapp
              </p>
            </div>
            {/* <div className="bg-white text-black flex items-center w-full px-3 mb-3 py-1 rounded-lg">
            <AiOutlineMail size={23} />
            <p className="text-lg ml-2 font-medium">
              Share Profile Via Message
            </p>
          </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ViewQr;
