import React, { useRef, useState } from "react";
import Modal from "react-modal";
import bg from "../images/user/fbg.jpg";
import Axios from "axios";
import { useNavigate } from "react-router";
import axios from "axios";
import { BASE_URI, QUERY_URI } from "../utils";
import Captcha from "./Captcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "0.5rem",
    backgroundImage: `url(${bg})`,
    padding: 0,
    backgroundPosition: "center",
    width: window.innerWidth < 550 ? "80vw" : "25vw",
    zIndex: 100,
  },
};

const ConnectPopUp = ({
  setIsOpen,
  modalIsOpen,
  isInfuencers,
  setIsBussiness,
}) => {
  const captchaRef = useRef(null);
  const history = useNavigate();
  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="flex flex-col items-center text-white py-4 px-2 z-50 overflow-x-hidden">
          <h1 className="text-2xl font-semibold mb-3">Connect with us</h1>
          <div className="flex flex-col items-center">
            <Input
              type="text"
              value={data.name}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
              placeholder="Name"
            />
            <Input
              type="number"
              value={data.mobile}
              onChange={(e) => {
                setData({ ...data, mobile: e.target.value });
              }}
              placeholder="Mobile"
            />
            <Input
              type="email"
              value={data.email}
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
              placeholder="Email"
            />
            <Input
              type="text"
              value={data.message}
              onChange={(e) => {
                setData({ ...data, message: e.target.value });
              }}
              placeholder="Message"
            />
          </div>
          <div className="flex items-center mobile:w-fit md:w-[100%] justify-center my-2">
            <Captcha captchaRef={captchaRef} />
          </div>
          <button
            onClick={async (e) => {
              e.preventDefault();
              let token = captchaRef.current.getValue();

              if (token) {
                let response = await Axios.post(`${BASE_URI}verify-token`, {
                  reCAPTCHA_TOKEN: token,
                });
                if (
                  response.status === 200 &&
                  response.data.verification_info.success === true
                ) {
                  axios
                    .post(`${QUERY_URI}/`, {
                      ...data,
                      flag: setIsBussiness
                        ? "Bussiness"
                        : isInfuencers
                        ? "Influencers"
                        : "Partners",
                    })
                    .then((res) => {
                      toast.success("Query added successfully");
                      setData({
                        name: "",
                        email: "",
                        mobile: "",
                        message: "",
                      });
                      setIsBussiness(false);
                    })
                    .catch((err) => {
                      // toast.error("Internal server error");
                    });
                  captchaRef.current.reset();
                  closeModal();
                } else {
                  captchaRef.current.reset();
                  toast.warning("User not verified");
                }
              } else {
                captchaRef.current.reset();
                toast.warning("User not verified");
              }
            }}
            className="btn"
          >
            Connect
          </button>
        </div>
      </Modal>
    </div>
  );
};

const Input = ({ type, placeholder, value, onChange }) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="bg-transparent border outline-none px-3 mb-3 py-1 rounded-lg"
    />
  );
};

export default ConnectPopUp;
