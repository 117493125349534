import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Components/Sidebar";
import { useLocation, useNavigate, useParams } from "react-router";
import QRCodeStyling from "qr-code-styling";
import { AiOutlineArrowLeft } from "react-icons/ai";
import axios from "axios";
import { ORDER_URI, PROFILE_URI } from "../../utils";
import copy from "copy-to-clipboard";
import obj from "../../Components/QR";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import TapKaroContext from "../../context/Context";

const ViewOrder = () => {
  const { id } = useParams();
  const [order, setOrder] = useState();
  const [showGenerateTokens, setShowGenerateTokens] = useState(false);
  const { getOrders } = useContext(TapKaroContext);

  const getOrder = () => {
    try {
      axios
        .get(`${ORDER_URI}/getOrder/${id}`)
        .then((response) => {
          setOrder(response.data);
        })
        .catch((err) => {
          if (err.status !== 200) {
            history("/admin/orders");
          }
        });
    } catch (error) {}
  };

  useEffect(() => {
    getOrder();
  }, []);

  const history = useNavigate();
  const [status, setStatus] = useState();

  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  const checkGenerateToken = () => {
    order?.cardDetails?.map((e) => {
      if (e?.quantity !== e?.token?.length) {
        setShowGenerateTokens(true);
      } else {
        setShowGenerateTokens(false);
      }
    });
  };

  useEffect(() => {
    checkGenerateToken();
  }, [order]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="w-[80vw] pt-6 px-6 overflow-y-scroll">
        <div className="flex items-center justify-between">
          <AiOutlineArrowLeft
            size={35}
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              history("/admin/orders");
            }}
          />
          <div className="flex flex-col">
            <button
              onClick={(e) => {
                e.preventDefault();
                copy(
                  `${
                    order?.fName +
                    " " +
                    (order?.mName ? order?.mName + " " : "") +
                    order?.lName +
                    "  " +
                    order?.designation +
                    "  " +
                    order?.user
                  }`
                );
              }}
              className="px-4 py-0.5 bg-gray-200 rounded-lg"
            >
              Copy Details
            </button>
            {showGenerateTokens ? (
              <button
                className="px-4 py-0.5 rounded-lg bg-adminGreen my-2"
                onClick={(e) => {
                  e.preventDefault();
                  axios
                    .get(`${ORDER_URI}/genrate-token/${id}`)
                    .then((response) => {
                      toast.success("Token Generate Success");
                      getOrder();
                      checkGenerateToken();
                    })
                    .catch((err) => {
                      toast.error("Internal server error");
                    });
                }}
              >
                Genrate Token
              </button>
            ) : null}
            <select
              className={`outline-none px-4 py-0.5 rounded-lg mt-2 ${
                status ? status : order?.status
              }`}
              value={status ? status : order?.status}
              onChange={(e) => {
                setStatus(e.target.value);
                axios
                  .put(`${ORDER_URI}/updateStatus`, {
                    id,
                    status: e.target.value,
                  })
                  .then((response) => {
                    toast.success("Status updated successfully");
                    getOrder();
                  })
                  .catch((err) => {
                    toast.error("Internal server error");
                  });
              }}
            >
              <option value="UnderDesign" className="UnderDesign">
                Under Design
              </option>
              <option value="NewOrder" className="NewOrder">
                New Order
              </option>
              <option value="Completed" className="Completed">
                Completed
              </option>
              <option value="PaymentFailed" className="PaymentFailed">
                Payment Failed
              </option>
            </select>
            <button
              onClick={(e) => {
                e.preventDefault();
                axios
                  .post(`${ORDER_URI}/delete/${id}`)
                  .then((response) => {
                    if (response.status == 200) {
                      toast.success("Deleted successfully");
                      getOrders();
                      history("/admin/orders");
                    }
                  })
                  .catch((er) => {
                    toast.warn("Deleted successfully");
                  });
              }}
              className="px-4 py-0.5 PaymentFailed rounded-lg mt-2"
            >
              Delete Order
            </button>
          </div>
        </div>
        <div className="ml-2">
          <div>
            <p className="text-lg">
              <span className="font-semibold">
                First Name (To be printed):{" "}
              </span>
              {order?.fName}
            </p>
            <p className="text-lg">
              <span className="font-semibold">
                Middle Name (To be printed):{" "}
              </span>
              {order?.mName}
            </p>
            <p className="text-lg">
              <span className="font-semibold">Last Name (To be printed): </span>
              {order?.lName}
            </p>
            <p className="text-lg">
              <span className="font-semibold">Phone : </span>
              {"+" + order?.cCode?.toString() + order?.phone.toString()}
            </p>
            <p className="text-lg">
              <span className="font-semibold">Designation: </span>
              {order?.designation}
            </p>
          </div>
          <div>
            <p className="text-lg">
              <span className="font-semibold">Address: </span>
              {order?.address}
            </p>
            <p className="text-lg">
              <span className="font-semibold">Street: </span>
              {order?.street}
              <span className="font-semibold ml-3">City Line: </span>
              {order?.city}
            </p>
            <p className="text-lg">
              <span className="font-semibold">State: </span>
              {order?.state}
              <span className="font-semibold ml-3">Country: </span>
              {order?.country}
            </p>
            <p className="text-lg">
              <span className="font-semibold">Pin Code: </span>
              {order?.pinCode}
            </p>
          </div>
        </div>
        <div className="flex items-start">
          <div className="mt-3 mr-10">
            <h1 className="text-2xl font-semibold mb-2">Order Details:</h1>
            <div className="ml-2">
              {order?.cardDetails.map((e, i) => {
                return <OrderBlock data={e} key={i} />;
              })}
            </div>
          </div>
          <div className="mt-3">
            <h1 className="text-2xl font-semibold mb-2">Payment History:</h1>
            <div className="ml-2">
              {order?.payment.map((e, i) => {
                return <PaymentBlock data={e} key={i} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderBlock = ({ data }) => {
  return (
    <div className="mb-4">
      <LineBlock title={"Card Name"} value={data?.title} />
      <LineBlock title={"Quantity"} value={data?.quantity} />
      <LineBlock title={"Type"} value={data?.type} />
      {data?.type !== "Standard" ? (
        <>
          <LineBlock title={"Name"} value={data?.customization?.name} />
          <LineBlock title={"Email"} value={data?.customization?.email} />
          <LineBlock title={"Phone"} value={data?.customization?.phone} />
          <LineBlock title={"Company"} value={data?.customization?.company} />
          <LineBlock
            title={"Designation"}
            value={data?.customization?.designation}
          />
          <p className="text-lg">
            <span className="font-semibold">Logo: </span>
            <img
              src={data?.customization?.logo}
              alt={data?.customization}
              className="w-[5vw]"
            />
          </p>
        </>
      ) : null}
      <p className="text-lg">
        <span className="font-semibold">Tokens: </span>
      </p>
      <div>
        {data?.token?.map((e, i) => {
          return <Token order={e} key={e} i={i + 1} />;
        })}
      </div>
    </div>
  );
};

const Token = ({ order, i }) => {
  const ref = useRef(null);

  const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,
    ...obj,
    data: `${window.location.origin}/${order}`,
  });

  useEffect(() => {
    qrCode.update({
      data: `${window.location.origin}/${order}`,
    });
    if (!ref?.current?.innerHTML.includes("canvas")) {
      qrCode.append(ref.current);
    }
  }, [order]);

  return (
    <div className="flex items-start mb-2">
      <p className="my-1 font-bold">{i}.</p>
      <div className="flex">
        {/* <div ref={ref}></div> */}
        <div className={`mx-auto flex flex-col items-center`}>
          <div ref={ref} className={`mt-1 rounded-xl`}></div>
          <p className="text-sm mt-1">{order}</p>
        </div>
        <div className="mt-4 text-sm ml-3">
          <p className="border px-2 py-0.5 rounded-lg border-black overflow-hidden">
            {window.location.origin}/{order}
          </p>
          <div className="flex items-center">
            <button
              className="w-[40%] mr-4 px-4 py-0.5 rounded-lg bg-adminGreen my-2 "
              onClick={() => {
                qrCode.download({
                  extension: "png",
                });
              }}
            >
              Download QR
            </button>
            <button
              className="w-[40%] px-4 py-0.5 rounded-lg bg-adminGreen my-2 "
              onClick={() => {
                copy(`${window.location.origin}/${order}`);
              }}
            >
              Copy Url
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const LineBlock = ({ title, value }) => {
  return (
    <p className="text-lg">
      <span className="font-semibold">{title}: </span>
      {value}
    </p>
  );
};

const PaymentBlock = ({ data }) => {
  return (
    <div className="mb-4">
      <p className="text-lg">
        <span className="font-semibold">Amount Received: </span>
        Rs. {data.amount}
      </p>
      <p className="text-lg">
        <span className="font-semibold">Payment Method: </span>
        {data.method}
      </p>
      <p className="text-lg">
        <span className="font-semibold">Payment Date: </span>
        {new Date(data.date).toString().slice(0, 16)}
      </p>
    </div>
  );
};

export default ViewOrder;
