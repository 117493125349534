import React from "react";
import { CiRoute } from "react-icons/ci";
import { CgInsights } from "react-icons/cg";
import { AiFillHome } from "react-icons/ai";
import { useNavigate } from "react-router";

const Footer = () => {
  const history = useNavigate();
  return (
    <div
      style={{
        borderTopLeftRadius: "2rem",
        borderTopRightRadius: "2rem",
      }}
      className="fixed w-[100vw] bg-black m-auto bottom-0 bg-gradient-to-b from-[#0f78ce] to-[#fb00ff4f] h-[8.5vh]"
    >
      <div className="w-[96vw] flex items-center justify-evenly">
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            history(`/user/connections/`);
          }}
        >
          <CiRoute size={30} />
          <p className="text-sm">Connections</p>
        </div>
        <div
          className="cursor-pointer"
          onClick={(e) => {
            history(`/user/`);
          }}
        >
          <AiFillHome
            size={58}
            className="bg-gray-400 rounded-full p-2 object-cover -mt-6 bg-gradient-to-b from-[#0f78ce] to-[#fb00ff4f]"
          />
        </div>
        <div
          onClick={(e) => {
            history(`/user/link-taps/`);
          }}
          className="flex flex-col items-center cursor-pointer"
        >
          <CgInsights size={30} />
          <p className="text-sm">Insights</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
