import React, { useContext, useEffect, useState } from "react";
import TapKaroContext from "../context/Context";
import axios from "axios";
import { ORDER_URI, PROFILE_URI } from "../utils";
import { cashfreeProd } from "cashfree-pg-sdk-javascript";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { data as codes } from "../data/countryCode.js";
import { getCountries, getStates } from "country-state-picker";

const Checkout = ({ amount }) => {
  const { data, setData } = useContext(TapKaroContext);
  const [countries, setCountries] = useState([]);
  const [findState, setFindState] = useState("in");
  const [states, setStates] = useState([]);

  useEffect(() => {
    setData({ ...data });
  }, []);

  useEffect(() => {
    axios
      .post(`${PROFILE_URI}/user`, { token: localStorage.getItem("token") })
      .then((res) => {
        setData({
          userId: res?.data?._id,
          fName: res?.data?.name.includes(" ")
            ? res?.data?.name.split(" ")[0]
            : res?.data?.name,
          lName: res?.data?.name.includes(" ")
            ? res?.data?.name.split(" ")[1]
            : "",
          email: res?.data?.email,
          phone: res?.data?.phone,
          designation: res?.data?.designation,
          address: res?.data?.address,
          street: res?.data?.street,
          country: res?.data?.country,
          city: res?.data?.city,
          state: res?.data?.state,
          pinCode: res?.data?.pinCode,
        });
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    // setCountries(Country.getAllCountries());
    setCountries(getCountries());
  }, []);

  useEffect(() => {
    setStates(getStates(findState));
  }, [findState]);

  const submitButton = async (e) => {
    e.preventDefault();
    if (
      data?.fName &&
      data?.address &&
      data?.state &&
      data?.email &&
      data?.phone &&
      data?.pinCode
    ) {
      axios
        .post(`${ORDER_URI}/`, {
          ...data,
          cardDetails: JSON.parse(localStorage.getItem("cart")),
          amount,
        })
        .then((res) => {
          console.log(res);
          let cashfree = new cashfreeProd.Cashfree(
            res?.data?.payment_session_id
          );
          cashfree.redirect();
          const cfCheckout = cashfree.elements();
          cfCheckout.elements({
            type: "upi-collect",
          });
          cfCheckout.pay("upi-collect");
        })
        .catch((err) => {});
    } else {
      toast.warning("Please fill all the details");
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex flex-col items-center text-white pt-4 pb-1">
        <div className="grid grid-cols-2 gap-3">
          <Input
            type="text"
            placeholder="First Name"
            value={data.fName}
            onChange={(e) => {
              setData({ ...data, fName: e.target.value });
            }}
          />
          <Input
            type="text"
            placeholder="Last Name"
            value={data.lName}
            onChange={(e) => {
              setData({ ...data, lName: e.target.value });
            }}
          />
          <Input
            type="text"
            placeholder="Email"
            value={data.email}
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
            }}
          />
          <div className="overflow-hidden flex items-center justify-between">
            <select
              value={data?.cCode}
              className="border bg-transparent w-[28%] text-sm h-full rounded-lg py-1 px-0.5 outline-none"
              onChange={(e) => {
                setData({ ...data, cCode: e.target.value });
              }}
            >
              {codes?.map((e, i) => {
                return (
                  <option value={e?.code} key={i} className="text-darkNavyBlue">
                    {e?.country}-{e?.code}
                  </option>
                );
              })}
            </select>
            <input
              type="number"
              placeholder="Phone"
              className="border bg-transparent w-[68%] h-full rounded-lg py-1 px-3 outline-none"
              value={data?.phone}
              onChange={(e) => {
                setData({ ...data, phone: e.target.value });
              }}
            />
          </div>
          <Input
            type="text"
            placeholder="Address"
            value={data.address}
            onChange={(e) => {
              setData({ ...data, address: e.target.value });
            }}
          />
          <Input
            type="text"
            placeholder="Street"
            value={data.street}
            onChange={(e) => {
              setData({ ...data, street: e.target.value });
            }}
          />
          <Input
            type="text"
            placeholder="City"
            value={data.city}
            onChange={(e) => {
              setData({ ...data, city: e.target.value });
            }}
          />
          <select
            className="bg-transparent border outline-none px-3 py-1 rounded-lg"
            value={data.country + "-" + findState}
            onChange={(e) => {
              setData({ ...data, country: e.target.value.split("-")[0] });
              setFindState(e.target.value.split("-")[1]);
            }}
          >
            {countries?.map((e, i) => {
              return (
                <option
                  key={i}
                  value={e?.name + "-" + e?.code}
                  className="bg-darkNavyBlue"
                >
                  {e?.name}
                </option>
              );
            })}
          </select>
          <select
            className="bg-transparent border outline-none px-3 py-1 rounded-lg"
            value={data.state}
            onChange={(e) => {
              setData({ ...data, state: e.target.value });
            }}
          >
            {states?.map((e, i) => {
              return (
                <option key={i} value={e} className="bg-darkNavyBlue">
                  {e}
                </option>
              );
            })}
          </select>
          <Input
            type="number"
            placeholder="Pin Code"
            value={data.pinCode}
            onChange={(e) => {
              setData({ ...data, pinCode: e.target.value });
            }}
          />
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            submitButton(e);
          }}
          className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] m-auto w-full mt-3 text-lg font-semibold py-0.5 rounded-lg"
        >
          Ready To Pay
        </button>
      </div>
    </div>
  );
};

const Input = ({ type, placeholder, value, onChange }) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="bg-transparent border outline-none px-3 py-1 rounded-lg"
    />
  );
};

export default Checkout;
