import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useEffect } from "react";
import TapKaroContext from "../context/Context";

const ShopNow = () => {
  const history = useNavigate();
  const { cartData, setCartData } = useContext(TapKaroContext);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCartData(JSON.parse(localStorage.getItem("cart")));
    } else {
      setCartData([]);
    }
  }, [localStorage]);

  return cartData?.length > 0 ? (
    <button
      onClick={(e) => {
        e.preventDefault();
        history("/cart");
      }}
      className="fixed bottom-10 bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] mobile:px-4 md:text-base mobile:text-lg md:px-4 rounded-full py-4 text-white z-10 right-10"
    >
      <AiOutlineShoppingCart size={32} />
    </button>
  ) : null;
};

export default ShopNow;
