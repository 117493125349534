import React, { useEffect } from "react";
import ShopNow from "../Components/ShopNow";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import img from "../images/mobile-payments/pana.png";
import { useNavigate } from "react-router";

const Success = () => {
  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Nav />
      <ShopNow />
      <div className="bg-navyBlue w-full mobile:h-[100vh] md:h-[130vh] flex flex-col items-center justify-center -mt-[3.5vw] text-white">
        <img src={img} alt={img} className="mobile:w-[70%] md:w-[25%]" />
        <h1 className="mobile:text-3xl md:text-4xl py-3 mt-1 font-bold md:mt-0 mobile:mt-4">
          Payment Successful !
        </h1>
        <p className="mobile:w-[85%] md:w-[40%] text-center">
          Welcome to the community. We are shipping your TAP KARO card, the
          soonest. One of our top designer is reaching out, if your order is
          personalised.
        </p>
        <button
          onClick={(e) => {
            history("/");
          }}
          className="bg-[#4fae6f] font-semibold px-4 py-1 rounded-lg mt-2 cursor-pointer"
        >
          Explore
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default Success;
