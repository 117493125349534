import React, { useEffect } from "react";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="overflow-x-hidden">
      <Nav />
      <div className="h-fit bg-navyBlue text-white mobile:px-[4vh] mobile:py-[13vh] md:px-[14vh]  md:py-[14vh]">
        <div className="md:w-[65%] mobile:w-full">
          <h1 className="text-3xl font-semibold">Get to know us!</h1>
          <br />
          <p>
            TAP KARO was established in 06/21 at Noida. Tap Karo, a product of
            In Out Green was formed by Siddharth Soni and he has been actively
            engaged in the same field since 2014. We are the leading retailers /
            wholesaler/ manufacturer for IoT products since the 2021.
          </p>
          <br />
          <p>
            At present, we have services across India. We focus to help our
            customers by saving time and money as we offer proper Product /
            Service descriptions and extremely competitive & unmatchable prices.
          </p>
          <br />
          <p>
            When you are buying from TAP KARO, you can be rest assured that you
            are buying from a Trusted Name.
          </p>
          <br />
          <p>
            GST - <span className="font-semibold">09BHEPS2791A2Z9</span>
          </p>
          <br />
          <p>
            Trade Name - <span className="font-semibold">TAP KARO</span>
          </p>
          <br />
          <p>In Out Green</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
