import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import { useLocation, useNavigate, useParams } from "react-router";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BUSSINESS_URI } from "../../utils";
import axios from "axios";
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InfinitySpin } from "react-loader-spinner";

const ViewBussiness = () => {
  const { id } = useParams();
  const [bussiness, setBussiness] = useState();
  const [cards, setCards] = useState();
  const [tokens, setTokens] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let token = bussiness?.bussinessOrders?.map((e) => {
      return e.tokens;
    });
    if (token) {
      setTokens([].concat(...token));
    }
  }, [bussiness]);

  const getBussiness = () => {
    try {
      axios
        .get(`${BUSSINESS_URI}/getOne/${id}`)
        .then((response) => {
          setBussiness(response.data);
        })
        .catch((err) => {
          if (err.status !== 200) {
            history("/admin/bussiness");
          }
        });
    } catch (error) {}
  };

  useEffect(() => {
    getBussiness();
  }, []);

  const history = useNavigate();
  const [status, setStatus] = useState(bussiness?.status);

  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loader ? (
        <div className="w-full flex items-center justify-center h-[80vw]">
          <InfinitySpin width="300" color="#000" />
        </div>
      ) : (
        <div className="w-[80vw] pt-6 px-6 overflow-y-scroll">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <AiOutlineArrowLeft
                size={30}
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  history("/admin/bussiness");
                }}
              />
              <h1 className="text-2xl font-bold ml-3">{bussiness?.company}</h1>
            </div>
            <h1 className="text-3xl font-bold">{bussiness?.name}</h1>
          </div>
          <div className="flex justify-between">
            <div>
              <p className="text-lg">
                <span className="font-semibold">Email: </span>
                {bussiness?.email}
              </p>
              <p className="text-lg">
                <span className="font-semibold">Phone: </span>
                {bussiness?.phone}
              </p>
              <p className="text-lg flex items-center">
                <span className="font-semibold mr-1">Order Status:</span>
                {bussiness?.cards} Cards
              </p>
              <p className="text-lg">
                <span className="font-semibold">Previous Order: </span>
              </p>
              <p className="text-lg">
                <span className="font-semibold">Genrate Tokens: </span>
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  history(`/admin/bussiness/${id}/view-tokens`);
                }}
                disabled={bussiness?.noOfCards < 1}
                className={`outline-none my-1.5 py-0.5 rounded-lg bg-gray-300 block w-[13vw] text-center`}
              >
                View Tokens
              </button>
              <CSVLink
                data={tokens ? tokens : []}
                filename="Export"
                disabled={bussiness?.noOfCards < 1}
                className={`outline-none my-1.5 py-0.5 rounded-lg bg-gray-300 block w-[13vw] text-center`}
              >
                Download Tokens
              </CSVLink>
            </div>
            <div className="flex flex-col items-center w-[18%]">
              {" "}
              <select
                className={`outline-none px-6 py-0.5 rounded-lg ${
                  status ? status : bussiness?.status
                } w-full text-center`}
                value={status ? status : bussiness?.status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  axios
                    .put(`${BUSSINESS_URI}/updateStatus`, {
                      id,
                      status: e.target.value,
                    })
                    .then((response) => {
                      getBussiness();
                    })
                    .catch((err) => {});
                }}
              >
                <option value="Active" className="Active">
                  Active
                </option>
                <option value="Disable" className="Disable">
                  Disable
                </option>
                <option value="Deactivate" className="Deactivate">
                  Deactivate
                </option>
              </select>
              <p
                className={`outline-none px-9 my-1.5 py-0.5 rounded-lg bg-gray-300 w-full text-center`}
              >
                {bussiness?.cards} Cards
              </p>
              {bussiness?.bussinessOrders[0]?.tokens?.length !==
              bussiness?.bussinessOrders[0]?.cards ? (
                <>
                  {" "}
                  <input
                    type="number"
                    value={cards}
                    onChange={(e) => setCards(e.target.value)}
                    className="border outline-none border-black w-full text-center rounded-lg py-0.5"
                    placeholder="Enter Token Value"
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setLoader(true);
                      axios
                        .post(`${BUSSINESS_URI}/addCards/${id}`, { cards })
                        .then((res) => {
                          axios
                            .post(`${BUSSINESS_URI}/genrateTokens/${id}`)
                            .then((res) => {
                              setLoader(false);
                              toast.success("Tokens genrate successful");
                              getBussiness();
                            })
                            .catch((err) => {
                              setLoader(false);
                              toast.error("Internal server error");
                            });
                        })
                        .catch((err) => {
                          setLoader(false);
                          toast.error("Internal server error");
                        });
                    }}
                    className={`outline-none px-9 my-1.5 py-0.5 rounded-lg bg-adminGreen w-full text-center`}
                  >
                    Genrate Tokens
                  </button>
                </>
              ) : null}
            </div>
          </div>
          <div className="mt-2">
            <p className="text-lg">
              <span className="font-semibold">Address: </span>
              {bussiness?.address}
            </p>
            <p className="text-lg">
              <span className="font-semibold">Street: </span>
              {bussiness?.street}
              <span className="font-semibold ml-3">City Line: </span>
              {bussiness?.city}
            </p>
            <p className="text-lg">
              <span className="font-semibold">State: </span>
              {bussiness?.state}
              <span className="font-semibold ml-3">Country: </span>
              {bussiness?.country}
            </p>
            <p className="text-lg">
              <span className="font-semibold">Pin Code: </span>
              {bussiness?.pinCode}
            </p>
          </div>
          <div className="mt-3 flex">
            <div>
              <h1 className="text-2xl font-semibold mb-2">Payment History:</h1>
              <div className="ml-2">
                {bussiness?.payments?.map((e, i) => {
                  return <PaymentBlock data={e} key={i} />;
                })}
              </div>
            </div>
            <div className="ml-[15%]">
              <h1 className="text-2xl font-semibold mb-2">Order History:</h1>
              <div className="ml-2">
                {bussiness?.bussinessOrders?.map((e, i) => {
                  return <OrderBlock data={e} key={i} index={i} />;
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const PaymentBlock = ({ data }) => {
  return (
    <div className="mb-4">
      <p className="text-lg">
        <span className="font-semibold">Amount Received: </span>
        Rs. {data?.amount}
      </p>
      <p className="text-lg">
        <span className="font-semibold">Payment Method: </span>
        {data?.method}
      </p>
      <p className="text-lg">
        <span className="font-semibold">Payment Date: </span>
        {new Date(data?.date).toString().slice(0, 16)}
      </p>
    </div>
  );
};

const OrderBlock = ({ data, index }) => {
  return (
    <div className="mb-4">
      <p className="text-lg">
        <span className="font-semibold">Order Number: </span>
        #000{index + 1}
      </p>
      <p className="text-lg">
        <span className="font-semibold">Order: </span>
        {data?.cards} Cards
      </p>
      <p className="text-lg">
        <span className="font-semibold">Order Date: </span>
        {new Date(data?.date).toString().slice(0, 16)}
      </p>
    </div>
  );
};

export default ViewBussiness;
