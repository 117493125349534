import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Topbar from "../Components/Topbar";

import TapKaroContext from "../../context/Context";
import { AiOutlineDelete } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { OFFER_URI } from "../../utils/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Offers = () => {
  const history = useNavigate();
  const context = useContext(TapKaroContext);
  const [data, setData] = useState({
    code: "",
    discount: "",
    endDate: "",
  });

  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  useEffect(() => {
    context.getCards();
  }, []);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="w-[80vw] pt-6 px-6">
        <Topbar title={"Discounts and Offers"} />
        <div>
          <div className="mt-3">
            <h1 className="font-bold text-2xl mb-1.5">New Offer</h1>
            <div className="flex justify-start items-center">
              <input
                type="text"
                value={data.code}
                onChange={(e) => {
                  setData({ ...data, code: e.target.value });
                }}
                placeholder="Offer Code"
                className="block w-[25%] border mr-4 outline-none px-3 py-1 rounded-md"
              />
              <input
                type="number"
                value={data.discount}
                onChange={(e) => {
                  setData({ ...data, discount: e.target.value });
                }}
                placeholder="Offer Percentage"
                className="block w-[25%] border mx-4 outline-none px-3 py-1 rounded-md"
              />
              <input
                type="date"
                value={data.endDate}
                onChange={(e) => {
                  setData({ ...data, endDate: e.target.value });
                }}
                placeholder="Offer Percentage"
                className="block w-[25%] border ml-4 outline-none px-3 py-1 rounded-md"
              />
              <button
                onClick={(e) => {
                  axios
                    .post(`${OFFER_URI}/`, data)
                    .then((response) => {
                      context.getOffers();
                      toast.success("Offer added successfully");
                      setData({ code: "", discount: "", endDate: "" });
                    })
                    .catch((err) => {
                      toast.error("Internal server error");
                    });
                }}
                className="bg-black text-white px-5 py-1.5 font-bold rounded-md ml-3"
              >
                + Add
              </button>
            </div>
          </div>
          <div className="mt-5 h-[65vh] overflow-y-scroll">
            <h1 className="text-xl font-bold mb-2">Ongoing Offers</h1>
            {context?.offers.map((e, i) => {
              return <Block data={e} key={i} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Block = ({ data }) => {
  const context = useContext(TapKaroContext);

  return (
    <div className="w-[98%] bg-adminBlue mb-2.5 rounded-xl px-5 py-2 flex items-center justify-between text-white">
      <div>
        <h1 className={"text-xl font-bold"}>{data?.code}</h1>
        <p className="text-lg">{data?.discount}% Off</p>
      </div>
      <div>
        <p>
          Offer Start Date :-{" "}
          {new Date(data?.startDate).toString().slice(0, 16)}
        </p>
        <p>
          Offer Expiry Date :- {new Date(data?.endDate).toString().slice(0, 16)}
        </p>
      </div>
      <div>
        <AiOutlineDelete
          onClick={(e) => {
            e.preventDefault();
            axios
              .delete(`${OFFER_URI}/${data?._id}`)
              .then((response) => {
                toast.success("Offer deleted successfully");
                context.getOffers();
              })
              .catch((err) => {
                toast.error("Internal server error");
              });
          }}
          size={30}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

export default Offers;
