import React, { useEffect, useRef } from "react";
import gsap, { Bounce, Power2 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import threeBalls from "../../images/3Balls.png";
import mobile from "../../images/mobile.png";
import card from "../../images/tapkaro card.png";

import ellipse13 from "../../images/Ellipse 13.png";
import ellipse14 from "../../images/Ellipse 14.png";
import ellipse15 from "../../images/Ellipse 15.png";
import logo from "../../images/rotatedlogo.png";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router";

const LandingPage = ({ home }) => {
  const ref = useRef(null);
  const balls = useRef(null);
  const mobileref = useRef(null);

  const leftHome = useRef(null);
  const rightHome = useRef(null);

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    if (window.innerWidth > 550) {
      gsap.fromTo(
        ref.current,
        { y: 0, x: 0, width: 400 },
        {
          x: -300,
          y:
            window.innerHeight === 625
              ? 460
              : window.innerHeight > 625
              ? window.innerHeight - 625 + 460
              : 625 - window.innerHeight + 460,
          width: 300,
          scrollBehavior: "smooth",
          scrollTrigger: {
            trigger: balls.current,
            scrub: true,
            start: "top 90%",
            end: "top 50%",
            ease: Power2.easeInOut,
          },
          ease: Power2.easeInOut,
        }
      );
    }
  }, []);

  useEffect(() => {
    gsap.fromTo(
      mobileref.current,
      {
        opacity: 0,
        y: 300,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#content",
          start: "top 75%",
          end: "top 70%",
        },
      }
    );
  }, []);

  const history = useNavigate();

  // Home Page animations
  useEffect(() => {
    gsap.fromTo(
      leftHome.current,
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        ease: Power2.easeInOut,
        duration: 1.5,
      }
    );
  }, []);

  return (
    <div className="h-full" ref={home}>
      <div className="md:h-[100vh] mobile:h-fit w-full bg-landingpage-bg bg-cover flex flex-col md:justify-center mobile:justify-between items-center mobile:px-0 md:px-[6vw]">
        <div
          id="homeTrigger"
          className="flex md:flex-row mobile:flex-col text-white items-center justify-between md:px-0 mobile:px-4 mobile:pt-20 md:pt-[20vh] h-full mobile:py-14 md:py-0"
        >
          <div
            ref={leftHome}
            className="md:w-[45vw] mobile:w-full mobile:px-3 md:mt-0 mobile:mt-6 md:px-0"
          >
            <h1 className="mobile:text-[43.5px] md:text-7xl mobile:pb-0 md:pb-1 font-bold">
              <Typewriter
                options={{
                  strings: ["Tap Karo", "Scan Karo", "Connect Karo"],
                  autoStart: true,
                  loop: true,
                  delay: 2,
                }}
              />
            </h1>
            <p className="py-3 text-gray-400 text-xl">Grow Your Network</p>
            <button
              onClick={(e) => {
                e.preventDefault();
                history("/shop");
              }}
              className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] mobile:px-6 md:px-10 font-semibold mobile:pt-2 md:mt-5 rounded-lg mobile:py-1 md:py-3 mobile:text-lg md:text-xl"
            >
              Buy Now
            </button>
          </div>
          <div
            ref={rightHome}
            className="md:w-[50%] mobile:w-[100%] md:mt-0 mobile:mt-[10vh] mobile:relative md:static"
          >
            <img
              ref={ref}
              src={card}
              alt={card}
              className="z-1 absolute md:w-[25%] mobile:w-[75%] rounded-3xl backdrop-blur-xl md:top-[35%] mobile:top-10 mobile:left-10 md:left-[60%] opacity-50 border"
            />
            <img src={threeBalls} alt={threeBalls} className="w-full" />
          </div>
        </div>
      </div>
      <div
        className="w-full h-[100vh] bg-navyBlue flex items-end justify-between pb-12 px-[6vw] md:flex mobile:hidden"
        ref={balls}
      >
        <div className="absolute left-0 top-[78vh]">
          <img src={ellipse13} className="w-[70%]" alt={ellipse13} />
        </div>
        <div className="absolute right-0 w-[10vw] top-[120vh]">
          <img src={ellipse14} className="w-[100%]" alt={ellipse14} />
        </div>
        <div className="absolute w-[25vw] top-[150vh] left-[43%] ">
          <img src={ellipse15} className="w-[100%]" alt={ellipse14} />
        </div>
        <div className="flex items-end" id="content">
          <div className="text-white w-[45vw]">
            <h1 className="text-6xl font-bold mb-6 leading-normal">
              Use Tap Karo in Three Simple Steps
            </h1>
            <li className="text-2xl mb-1.5">Order Your Tap Karo Card Now</li>
            <li className="text-2xl mb-1.5">Create Your Profile </li>
            <li className="text-2xl mb-1.5">Tap Karo to Connect to Network</li>
          </div>
        </div>
        <img
          src={mobile}
          ref={mobileref}
          alt={mobile}
          className="h-[75vh] rounded-3xl z-1 -ml-28"
        />
        <div className="w-[7.6vw] -mr-3">
          <img src={logo} alt={logo} />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
