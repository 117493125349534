import React, { useEffect, useState } from "react";

import logo from "../../images/tap karo logo.png";

import { ADMIN_URI, LOGIN_URI } from "../../utils/index";
import { BsKeyFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PasswordReset = () => {
  const { id, token } = useParams();
  const [data, setData] = useState({
    password: "",
  });
  const [showPassword, setshowPassword] = useState(false);
  const history = useNavigate();
  useEffect(() => {
    axios
      .get(`${ADMIN_URI}/login/password-reset/${id}/${token}`)
      .then((res) => {
        if (res.status !== 200) {
          history("/Login");
        }
      })
      .catch((err) => {});
  }, [id, token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="overflow-x-hidden">
        <div className="h-[100vh] w-full bg-white flex justify-center items-center">
          <div className="border flex flex-col border-gray-700 mobile:py-3 md:py-4 items-center text-navyBlue rounded-3xl backdrop-blur-xl mobile:w-[90%] md:w-[25%]">
            <img src={logo} alt={logo} className="w-[32%]" />
            <h1 className="text-2xl m-3">Reset Password</h1>
            <div className="mobile:w-[85%] md:w-[80%] m-auto">
              <div className="flex items-center">
                <BsKeyFill size={24} className="absolute left-[13%]" />
                <input
                  value={data.password}
                  onChange={(e) => {
                    setData({ ...data, password: e.target.value });
                  }}
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  className="border my-2 outline-none bg-transparent py-1 mobile:pl-12 md:pl-10 pr-16 rounded-lg w-[100%] relative tracking-widest"
                />
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    setshowPassword(!showPassword);
                  }}
                  className="absolute right-[15%] cursor-pointer transition-all font-medium text-gray-400 hover:text-white"
                >
                  {showPassword ? "Hide" : "Show"}
                </p>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  axios
                    .post(
                      `${ADMIN_URI}/login/password-reset/reset/${id}/${token}`,
                      {
                        password: data.password,
                      }
                    )
                    .then((res) => {
                      if (res.status === 200) {
                        toast.success("Password changed successfully");
                        history("/admin/login");
                      }
                    })
                    .catch((err) => {
                      toast.error("Internal server error");
                    });
                }}
                className="bg-lightBlue w-full mt-1 py-1 rounded-lg font-medium text-white hover:bg-white hover:text-lightBlue"
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
