import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import card from "../../images/tapkaro card.png";
import { AiFillDelete } from "react-icons/ai";
import { BiShare } from "react-icons/bi";

import location from "../../images/contact/location.png";
import url from "../../images/contact/url.png";
import mail from "../../images/contact/mail.png";
import call from "../../images/contact/call.png";
import TapKaroContext from "../../context/Context";
import { useNavigate } from "react-router";
import axios from "axios";
import { BUSSINESS_URI, PROFILE_URI } from "../../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  PointElement,
  Legend,
  BarElement,
  LineElement,
  Filler
);

const BussinessProfile = () => {
  const history = useNavigate();
  const [profileTaps, setProfileTaps] = useState([]);
  const [socialTaps, setSocialTaps] = useState([]);
  const [connectionTaps, setConnectionTaps] = useState([]);
  const [data, setData] = useState({
    name: "",
    designation: "",
    phone: "",
  });
  const { viewEmployee } = useContext(TapKaroContext);
  const [dailyTapsReport, setDailyTapsReport] = useState({
    taps: [],
    profile: [],
    links: [],
  });
  const [monthlyOrDaily, setMonthlyOrDaily] = useState({
    taps: true,
    profile: true,
    links: true,
  });

  useEffect(() => {
    if (!viewEmployee?._id) {
      history("/bussiness");
    }
  }, []);

  useEffect(() => {
    setData({
      name: viewEmployee?.name,
      designation: viewEmployee.designation,
      phone: viewEmployee.phone,
    });
  }, []);

  useEffect(() => {
    if (viewEmployee?._id) {
      axios
        .get(`${PROFILE_URI}/taps/${viewEmployee?._id}`)
        .then((res) => {
          setProfileTaps(res.data.taps);
          setDailyTapsReport({ ...dailyTapsReport, taps: res.data.days });
        })
        .catch((err) => {});
      axios
        .get(`${PROFILE_URI}/connections/${viewEmployee?._id}`)
        .then((res) => {
          setConnectionTaps(res.data.taps);
          setDailyTapsReport({ ...dailyTapsReport, profile: res.data.days });
        })
        .catch((err) => {});
      axios
        .get(`${PROFILE_URI}/social-taps/${viewEmployee?._id}`)
        .then((res) => {
          setSocialTaps(res.data.taps);
          setDailyTapsReport({ ...dailyTapsReport, links: res.data.days });
        })
        .catch((err) => {});
    }
  }, [monthlyOrDaily]);

  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  function fillArrayWithIncrement(startingValue, length) {
    const arr = Array.from({ length }, (_, index) => startingValue + index);
    return arr;
  }

  const resultArray = fillArrayWithIncrement(
    1,
    new Date(
      new Date(Date.now()).getFullYear(),
      new Date(Date.now()).getMonth() + 1,
      0
    ).getDate()
  );

  const dataWebsite = {
    labels: monthlyOrDaily?.taps ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        data: monthlyOrDaily?.taps
          ? [...dailyTapsReport?.taps]
          : [...profileTaps],
        label: "Website Visits",
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const dataConnections = {
    labels: monthlyOrDaily?.profile ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        label: "Profile Saved",
        data: monthlyOrDaily?.profile
          ? [...dailyTapsReport?.profile]
          : [...connectionTaps],
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const dataSocials = {
    labels: monthlyOrDaily?.links ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        label: "Social icons Taps",
        data: monthlyOrDaily?.links
          ? [...dailyTapsReport?.links]
          : [...socialTaps],
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const getChartOptions = () => {
    return {
      scales: {
        x: {
          ticks: {
            color: "white", // X-axis label color
          },
        },
        y: {
          ticks: {
            color: "white", // Y-axis label color
            callback: function (value) {
              // Display only numeric and positive values on the Y-axis
              if (Number.isInteger(value) && value >= 0) {
                return value;
              }
            },
          },
        },
      },
      elements: {
        point: {
          radius: 2, // Set the desired dot size here
        },
      },
      ticks: {
        precision: 0,
      },
      plugins: {
        legend: {
          display: false, // Set the dataset label display to false
          labels: {
            color: "white", // Set the label color here (if you have other labels, not dataset label)
          },
        },
      },
    };
  };

  return (
    <div className="w-[100vw] flex h-[100vh] text-white">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="w-[85vw] bg-navyBlue h-full">
        <div className="flex pt-[1.5%]">
          <div className="ml-[5%] w-[40%]">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  alt="user"
                  src={viewEmployee?.profilePicture}
                  className="w-[5vw] h-[5vw] object-cover object-center rounded-full mr-4"
                />
                <div>
                  <h1 className="text-xl font-semibold">
                    {viewEmployee?.name}
                  </h1>
                  <p className="text-gray-300">{viewEmployee?.designation}</p>
                </div>
              </div>
              <div
                className={`px-10 py-2 ${
                  viewEmployee?.status === "Active"
                    ? "bg-green-800 text-green-200"
                    : "bg-red-500 text-red-200"
                } rounded-full`}
              >
                {viewEmployee?.status}
              </div>
            </div>
            <div className="w-[70%] mt-2 mx-auto flex items-center justify-evenly py-4">
              <ContactIcons img={call} />
              <ContactIcons img={mail} />
              <ContactIcons img={location} />
              <ContactIcons img={url} />
            </div>
            <div className="w-full bg-gray-900 rounded-3xl h-fit mt-3 py-4 px-8">
              <input
                type="text"
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
                placeholder="Name"
                className="block bg-transparent w-full border-b outline-none mb-2 p-1.5 border-b-gray-400"
              />
              <input
                type="text"
                value={data.designation}
                onChange={(e) => {
                  setData({ ...data, designation: e.target.value });
                }}
                placeholder="Designation"
                className="block bg-transparent w-full border-b outline-none mb-2 p-1.5 border-b-gray-400"
              />
              <input
                type="number"
                value={data.phone}
                onChange={(e) => {
                  setData({ ...data, phone: e.target.value });
                }}
                placeholder="Phone no."
                className="block bg-transparent w-full border-b outline-none mb-2 p-1.5 border-b-gray-400"
              />
              <div className="mt-4 float-right">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    axios
                      .post(`${PROFILE_URI}/edit/${viewEmployee?._id}`, {
                        name: data?.name ? data?.name : viewEmployee?.name,
                        designation: data?.designation
                          ? data?.designation
                          : viewEmployee?.designation,
                        phone: data?.phone ? data?.phone : viewEmployee?.phone,
                      })
                      .then((res) => {
                        toast.success("Details updated successfully");
                        history("/bussiness");
                      })
                      .catch((err) => {
                        toast.error("Internal server error");
                      });
                  }}
                  className="text-gray-200 bg-gray-700 px-8 py-1.5 rounded-lg"
                >
                  Update
                </button>
              </div>
              <div className="clear-both"></div>
            </div>
          </div>
          <div className="w-[25%] ml-[5%]">
            <img src={card} alt={card} className="rounded-3xl w-full" />
            <div className="flex items-center justify-between mt-2 px-2">
              {viewEmployee?.status === "Active" ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    axios
                      .put(`${BUSSINESS_URI}/updateTokenStatus`, {
                        ids: [viewEmployee?._id],
                        status: "Inactive",
                      })
                      .then((res) => {
                        if (res.data.modifiedCount) {
                          history("/bussiness");
                          toast.success("Status updated successfully");
                        }
                      })
                      .catch((err) => {});
                  }}
                  className="bg-red-500 text-red-200 px-3 py-1 rounded-lg flex items-center justify-center justify-items-end"
                >
                  Make Inactive
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    axios
                      .put(`${BUSSINESS_URI}/updateTokenStatus`, {
                        ids: [viewEmployee?._id],
                        status: "Active",
                      })
                      .then((res) => {
                        if (res.data.modifiedCount) {
                          history("/bussiness");
                          toast.success("Status updated successfully");
                        }
                      })
                      .catch((err) => {});
                  }}
                  className="bg-green-800 text-green-200 px-3 justify-items-end py-1 rounded-lg flex items-center justify-center"
                >
                  Make Active
                </button>
              )}
            </div>
            <div className="mt-5">
              <div className="flex items-center justify-between mb-1">
                <p>Profile Taps :</p>
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    setMonthlyOrDaily({
                      ...monthlyOrDaily,
                      taps: !monthlyOrDaily.taps,
                    });
                  }}
                  className="text-sm border px-4 rounded-lg py-0.5 cursor-pointer"
                >
                  {monthlyOrDaily?.taps ? "Monthly" : "Yearly"}
                </p>
              </div>
              <Line data={dataWebsite} options={getChartOptions()} />
            </div>
          </div>
        </div>
        <div className="flex items-center pl-[5%]">
          <div className="mt-2">
            <div className="flex items-center justify-between mb-1">
              <p>Profile Saved :</p>
              <p
                onClick={(e) => {
                  e.preventDefault();
                  setMonthlyOrDaily({
                    ...monthlyOrDaily,
                    profile: !monthlyOrDaily.profile,
                  });
                }}
                className="text-sm border px-4 rounded-lg py-0.5 cursor-pointer"
              >
                {monthlyOrDaily?.profile ? "Monthly" : "Yearly"}
              </p>
            </div>
            <Line data={dataConnections} options={getChartOptions()} />
          </div>
          <div className="mt-2 ml-5">
            <div className="flex items-center justify-between mb-1">
              <p>Social Link Taps :</p>
              <p
                onClick={(e) => {
                  e.preventDefault();
                  setMonthlyOrDaily({
                    ...monthlyOrDaily,
                    links: !monthlyOrDaily.links,
                  });
                }}
                className="text-sm border px-4 rounded-lg py-0.5 cursor-pointer"
              >
                {monthlyOrDaily?.links ? "Monthly" : "Yearly"}
              </p>
            </div>
            <Line data={dataSocials} options={getChartOptions()} />
          </div>
          <div className="mt-2 ml-5 border border-gray-600 rounded-lg w-[15%] h-[25vh] flex flex-col justify-center items-center px-1.5 overflow-y-auto">
            {viewEmployee?.connections?.length > 0 ? (
              viewEmployee?.connections.map((e) => {
                return (
                  <div className=" w-full my-1 border border-gray-800 text-gray-300 py-1 px-1.5 rounded-md">
                    <p>{e?.name}</p>
                    <p className="text-end">{e?.phone}</p>
                  </div>
                );
              })
            ) : (
              <p className="text-gray-300">No Connections</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactIcons = ({ img }) => {
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
      }}
      className={`bg-gray-900 p-3.5 w-[4.5vw] h-[4.5vw] rounded-full flex justify-center items-center cursor-pointer`}
    >
      <img src={img} alt={img} />
    </div>
  );
};

export default BussinessProfile;
