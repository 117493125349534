import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import { useNavigate } from "react-router";
import TapKaroContext from "../../context/Context";
import axios from "axios";
import { BUSSINESS_URI, PROFILE_URI } from "../../utils";
import Pagination from "../../Admin/Screen/Pagination";
import { useMemo } from "react";
import { BsCheck2All } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BussinessHome = () => {
  const [tokens, setTokens] = useState([]);
  const history = useNavigate();
  const {
    bussiness,
    getOneBussiness,
    bussinessSelectedEmp,
    setBussinessSelectedEmp,
  } = useContext(TapKaroContext);

  useEffect(() => {
    getOneBussiness();
    if (localStorage.getItem("bussiness_token")?.length === 0) {
      history("/bussiness/login");
    }
  }, []);

  useEffect(() => {
    let token = bussiness?.bussinessOrders?.map((e) => {
      return e.tokens;
    });
    if (token) {
      setTokens([].concat(...token));
    }
  }, [bussiness]);

  let PageSize = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return []
      .concat(...tokens)
      .reverse()
      ?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, tokens]);

  return (
    <>
      {bussiness?.status !== "Active" ? (
        <div className="w-[100vw] h-[100vh] absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] backdrop-blur-sm text-white mobile:text-2xl md:text-3xl font-medium flex items-center justify-center mobile:leading-normal md:leading-relaxed mobile:tracking-normal md:tracking-wide">
          <p className="text-center w-[70%]">
            Your business is not verified. Please send your business
            requirements to{" "}
            <span
              onClick={(e) => {
                e.preventDefault();
                window.open("mailto:care@tapkaro.com");
              }}
              className="underline"
            >
              care@tapkaro.com
            </span>{" "}
            or reach out at {window.innerWidth < 550 ? <br /> : null}
            <span
              className="underline"
              onClick={(e) => {
                e.preventDefault();
                window.open("tel:+919810816367");
              }}
            >
              +91 98108 16367
            </span>
            {window.innerWidth < 550 ? <br /> : null}
             to activate.
          </p>
        </div>
      ) : null}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="w-[100vw] flex h-[100vh] text-white">
        <Sidebar />
        <div className="w-[85vw]">
          <div className="h-[12%] bg-lightNavyBlue justify-between flex items-center w-full px-7">
            <div>
              <h1 className="text-2xl font-medium">Dashboard</h1>
              <p className="text-gray-200">Manage cards in your organization</p>
            </div>
            <div className="flex items-center">
              <div className="text-end">
                <h1 className="text-xl font-medium">
                  Hello {bussiness?.name?.split(" ")[0]}!
                </h1>
                <p className="text-gray-200">Enjoy making new connections</p>
              </div>
              <div>
                <img
                  alt="user"
                  src={bussiness?.profile}
                  className="w-[4vw] h-[4vw] rounded-full object-cover object-center ml-3"
                />
              </div>
            </div>
          </div>
          <div className="bg-navyBlue h-[88%] p-7">
            <div className="mb-1 flex items-center justify-between w-full">
              <h1 className="text-lg">Your Employee - {bussiness?.cards}</h1>
              {bussinessSelectedEmp.length > 0 ? (
                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      axios
                        .put(`${BUSSINESS_URI}/updateTokenStatus`, {
                          ids: bussinessSelectedEmp,
                          status: "Active",
                        })
                        .then((res) => {
                          if (res.data.modifiedCount) {
                            setBussinessSelectedEmp([]);
                            getOneBussiness();
                            toast.success("Status updated successfully");
                          }
                        })
                        .catch((err) => {});
                    }}
                    className="bg-lightBlue px-4 py-0.5 rounded-lg mr-3"
                  >
                    Make Active
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      axios
                        .put(`${BUSSINESS_URI}/updateTokenStatus`, {
                          ids: bussinessSelectedEmp,
                          status: "Inactive",
                        })
                        .then((res) => {
                          if (res.data.modifiedCount) {
                            setBussinessSelectedEmp([]);
                            getOneBussiness();
                            toast.success("Status updated successfully");
                          }
                        })
                        .catch((err) => {});
                    }}
                    className="bg-lightBlue px-4 py-0.5 rounded-lg"
                  >
                    Make Inactive
                  </button>
                </div>
              ) : null}
            </div>
            <div
              className="grid items-center w-full border py-1.5 my-4 rounded-lg border-gray-500 text-gray-300"
              style={{ gridTemplateColumns: "5% 25% 25% 15% 15% 15%" }}
            >
              <p className="text-center">
                {bussinessSelectedEmp?.length > 0 ? (
                  <AiOutlineClose
                    size={22}
                    className="mx-auto cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setBussinessSelectedEmp([]);
                    }}
                  />
                ) : (
                  <BsCheck2All
                    size={22}
                    className="mx-auto cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setBussinessSelectedEmp([
                        ...currentTableData.map((e) => e.token),
                      ]);
                    }}
                  />
                )}
              </p>
              <p className="text-center">Name</p>
              <p className="text-center">Email</p>
              <p className="text-center">Phone</p>
              <p className="text-center">Designation</p>
              <p className="text-center">Status</p>
            </div>
            <div
              className={`bg-gray-900 h-[82%] rounded-2xl overflow-y-auto ${
                !bussiness?.bussinessOrders[0]?.tokens
                  ? "flex justify-center items-center"
                  : ""
              }`}
            >
              {tokens ? (
                <>
                  {currentTableData?.map((e, i) => {
                    return <Block data={e} key={i} index={i} />;
                  })}
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={tokens?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                    invert={true}
                  />
                </>
              ) : (
                <p className="text-2xl">You've not registered any Employee</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Block = ({ data, index }) => {
  const history = useNavigate();
  const [user, setUser] = useState();
  const { setViewEmployee, bussinessSelectedEmp, setBussinessSelectedEmp } =
    useContext(TapKaroContext);

  useEffect(() => {
    axios
      .get(`${PROFILE_URI}/${data?.token}`)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {});
  }, [data]);

  return (
    <div
      className="grid items-center w-full py-3 rounded-2xl border border-transparent transition-all hover:border-gray-600 mb-1 cursor-pointer"
      style={{ gridTemplateColumns: "5% 25% 25% 15% 15% 15%" }}
      onClick={(e) => {
        e.preventDefault();
        if (user?.email) {
          setViewEmployee({ ...user, status: data?.status });
          history("/bussiness/profile");
        }
      }}
    >
      <p className="text-center">
        <input
          type="checkbox"
          checked={
            bussinessSelectedEmp?.includes(data?.token) === true ? true : false
          }
          onClick={(e) => {
            e.preventDefault();
            if (bussinessSelectedEmp?.includes(data.token)) {
              let arr = bussinessSelectedEmp.filter((e) => e !== data.token);
              setBussinessSelectedEmp([...arr]);
            } else {
              setBussinessSelectedEmp([...bussinessSelectedEmp, data?.token]);
            }
          }}
        />
      </p>
      <p className="text-center flex items-center justify-center">
        {user?.email ? (
          <>
            {" "}
            <img
              alt="user"
              src={user?.profilePicture}
              className="w-[3vw] h-[3vw] object-cover object-center rounded-full mr-3"
            />
            {user?.name}
          </>
        ) : (
          <>{data.token}</>
        )}
      </p>
      <p className="text-center">{user?.email}</p>
      <p className="text-center">{user?.phone}</p>
      <p className="text-center">{user?.designation}</p>
      <p className="text-center">{data?.status}</p>
    </div>
  );
};

export default BussinessHome;
