import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import card from "../../images/tapkaro card.png";
import TapKaroContext from "../../context/Context";
import { BUSSINESS_URI } from "../../utils";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BussinessSettings = () => {
  const [cardData, setCardData] = useState({
    cards: 0,
    logo: "",
  });
  const [data, setData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    profile: "",
  });
  const { bussiness, getOneBussiness } = useContext(TapKaroContext);

  useEffect(() => {
    getOneBussiness();
    setData({
      name: bussiness?.name,
      company: bussiness?.company,
      email: bussiness?.email,
      phone: bussiness?.phone,
      profile: bussiness?.profile,
    });
  }, []);

  return (
    <div className="w-[100vw] flex h-[100vh] text-white">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="w-[85vw]">
        <div className="h-[12%] bg-lightNavyBlue justify-between flex items-center w-full px-7">
          <div>
            <h1 className="text-2xl font-medium">Dashboard</h1>
            <p className="text-gray-200">Manage cards in your organization</p>
          </div>
          <div className="flex items-center">
            <div className="text-end">
              <h1 className="text-xl font-medium">
                Hello {bussiness?.name.split(" ")[0]}!
              </h1>
              <p className="text-gray-200">Enjoy making new connections</p>
            </div>
            <div>
              <img
                alt="user"
                src={bussiness?.profile}
                className="w-[4vw] h-[4vw] rounded-full object-cover object-center ml-3"
              />
            </div>
          </div>
        </div>
        <div className="bg-navyBlue h-[88%] py-4 px-7 flex flex-col">
          <div className="bg-lightNavyBlue flex justify-between py-2 px-3 h-fit rounded-lg">
            <div className="w-[40%]">
              <h1 className="text-2xl font-semibold">
                Card Details ( {bussiness?.cards} Cards )
              </h1>
              <div className="flex items-start justify-between mt-3">
                <div className="w-[30%] mr-4">
                  <label htmlFor="Cards">Number of Cards</label>
                  <input
                    type="number"
                    value={cardData.cards}
                    onChange={(e) => {
                      setCardData({ ...cardData, cards: e.target.value });
                    }}
                    min={0}
                    id="Cards"
                    className="border w-[90%] border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg mb-3"
                  />
                </div>
                <div className="w-[70%]">
                  <label htmlFor="email">Logo</label>
                  <input
                    type="file"
                    value={cardData.logo}
                    onChange={(e) => {
                      const formData = new FormData();
                      formData.append("file", e.target.files[0]);
                      formData.append("upload_preset", "upload_photo");
                      formData.append("cloud_name", "dpbsogbtr");

                      fetch(
                        "https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload",
                        {
                          method: "POST",
                          body: formData,
                        }
                      )
                        .then((res) => res.json())
                        .then((res) => {
                          setCardData({ ...cardData, logo: res.url });
                        })
                        .catch((err) => {});
                    }}
                    id="email"
                    className="block mt-1.5 text-grey-500 file:mr-1 file:py-1.5 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-mediumfile:bg-blue-50 file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50hover:file:text-amber-700 w-[90%]"
                  />
                </div>
              </div>
              <input
                type="text"
                value={bussiness?.company}
                disabled={bussiness?.company}
                placeholder="Company Name"
                className="border w-full border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg mb-3"
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (parseInt(cardData?.cards) > 0) {
                    axios
                      .post(`${BUSSINESS_URI}/addBussinessOrder`, {
                        ...cardData,
                        id: bussiness?._id,
                      })
                      .then((res) => {
                        if (res.status === 200) {
                          toast.success("Bussiness order added successfully");
                        } else {
                          toast.warning(res.data);
                        }
                      })
                      .catch((err) => {
                        toast.error("Internal server error");
                      });
                  } else {
                    toast.warning("Card Value should be greater then 0");
                  }
                }}
                className="bg-lightBlue px-6 py-1 rounded-lg"
              >
                Order Now
              </button>
            </div>
            <div className="w-[30%] ml-[5%]">
              <img
                src={card}
                alt={card}
                className="rounded-3xl w-full border"
              />
            </div>
          </div>
          <div className="bg-lightNavyBlue flex justify-between py-2 px-3 h-fit rounded-lg mt-3">
            <div className="w-[40%]">
              <h1 className="text-2xl font-semibold">Personal Details</h1>
              <input
                type="text"
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
                placeholder="Name"
                className="border w-full border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg mb-2"
              />
              <input
                type="text"
                value={data.company}
                onChange={(e) => {
                  setData({ ...data, company: e.target.value });
                }}
                placeholder="Company Name"
                className="border w-full border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg mb-2"
              />
              <input
                type="email"
                value={data.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
                placeholder="Email"
                className="border w-full border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg mb-2"
              />
              <input
                type="number"
                value={data.phone}
                onChange={(e) => {
                  setData({ ...data, phone: e.target.value });
                }}
                placeholder="Phone"
                className="border w-full border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg mb-2"
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  axios
                    .post(`${BUSSINESS_URI}/edit/${bussiness?._id}`, data)
                    .then((res) => {
                      getOneBussiness();
                      toast.success("Details updated successfully");
                    })
                    .catch((err) => {
                      toast.error("Internal server error");
                    });
                }}
                className="bg-lightBlue px-6 py-1 rounded-lg"
              >
                Update
              </button>
            </div>
            <div className="w-[20%] ml-[5%]">
              <img
                src={data?.profile}
                alt={data?.profile}
                className="rounded-3xl w-[15vw] h-[15vw] object-cover object-center"
              />
              <input
                type="file"
                value={cardData.logo}
                onChange={(e) => {
                  const formData = new FormData();
                  formData.append("file", e.target.files[0]);
                  formData.append("upload_preset", "upload_photo");
                  formData.append("cloud_name", "dpbsogbtr");

                  fetch(
                    "https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload",
                    {
                      method: "POST",
                      body: formData,
                    }
                  )
                    .then((res) => res.json())
                    .then((res) => {
                      setData({ ...data, profile: res.url });
                    })
                    .catch((err) => {});
                }}
                id="email"
                className="block mt-1.5 mx-auto text-grey-500 file:mr-1 file:py-1.5 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-mediumfile:bg-blue-50 file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50hover:file:text-amber-700 w-[90%]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BussinessSettings;
