import React, { useEffect, useRef, useState, useMemo } from "react";
import Sidebar from "../Components/Sidebar";
import QRCodeStyling from "qr-code-styling";
import { GrDownload } from "react-icons/gr";
import { BUSSINESS_URI, PROFILE_URI } from "../../utils";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router";
import obj from "../../Components/QR";
import Pagination from "./Pagination";

const ViewTokens = () => {
  const { id } = useParams();
  const [bussiness, setBussiness] = useState();
  const history = useNavigate();
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    let token = bussiness?.bussinessOrders?.map((e) => {
      return e.tokens;
    });
    if (token) {
      setTokens([].concat(...token));
    }
  }, [bussiness]);

  let PageSize = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return [].concat(...tokens)?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, tokens]);

  const getBussiness = () => {
    try {
      axios
        .get(`${BUSSINESS_URI}/getOne/${id}`)
        .then((response) => {
          setBussiness(response.data);
        })
        .catch((err) => {
          if (err.status !== 200) {
            history("/admin/bussiness");
          }
        });
    } catch (error) {}
  };

  useEffect(() => {
    getBussiness();
  }, []);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[80vw] pt-6 px-6">
        <h1 className="text-3xl font-bold">Tokens Data</h1>
        <div className="h-[90%] mt-3 overflow-y-auto">
          {currentTableData?.map(({ token }, i) => {
            return <Block token={token} key={i} />;
          })}
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={tokens?.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
};

const Block = ({ token }) => {
  const ref = useRef(null);
  const [showQR, setShowQR] = useState(false);
  const [user, setUser] = useState();

  const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,
    ...obj,
    data: `${window.location.origin}/${token}`,
  });

  useEffect(() => {
    qrCode.update({
      data: `${window.location.origin}/${token}`,
    });
    if (!ref?.current?.innerHTML.includes("canvas")) {
      qrCode.append(ref.current);
    }
  }, [token]);

  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  useEffect(() => {
    axios
      .get(`${PROFILE_URI}/${token}`)
      .then((res) => {
        console.log(res.data);
        setUser(res.data);
      })
      .catch((err) => {});
  }, [token]);

  return (
    <div className="border mb-2 flex flex-col items-center px-6 rounded-lg w-[95%] m-auto">
      <div
        className="flex justify-between w-full py-2 cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          setShowQR(!showQR);
        }}
      >
        <p>
          {user?.email ? user?.email : window.location.origin + "/" + token}
        </p>
        <div>{user?.name}</div>
        <button
          onClick={async (e) => {
            e.stopPropagation();
            await qrCode.download({
              extension: "png",
            });
          }}
          className="flex items-center"
        >
          <GrDownload className="mr-1" />
          Download QR
        </button>
      </div>
      <div
        className={`${
          showQR ? "block" : "hidden"
        } mx-auto flex flex-col items-center mb-3`}
      >
        <div ref={ref} className={`mt-1 rounded-xl`}></div>
        <p className="text-sm mt-1">{token}</p>
      </div>
    </div>
  );
};

export default ViewTokens;
