import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Topbar from "../Components/Topbar";

import card from "../../images/tapkaro card.png";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { CARD_URI } from "../../utils/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import TapKaroContext from "../../context/Context";

const Card = () => {
  const { categories } = useContext(TapKaroContext);
  const history = useNavigate();
  const [mainImg, setMainImg] = useState();
  const [img1, setImg1] = useState();
  const [img2, setImg2] = useState();
  const [img3, setImg3] = useState();
  const [img4, setImg4] = useState();
  const [data, setData] = useState({
    title: "",
    desc: "",
    price: "",
    cutPrice: "",
    category: "PVC",
    customizedPrice: "",
  });

  let handleImageUpload = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "upload_photo");
    formData.append("cloud_name", "dpbsogbtr");

    fetch("https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        setMainImg(res.url);
      })
      .catch((err) => {});
  };

  let handleImage1Upload = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "upload_photo");
    formData.append("cloud_name", "dpbsogbtr");

    fetch("https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        setImg1(res.url);
      })
      .catch((err) => {});
  };

  let handleImage2Upload = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "upload_photo");
    formData.append("cloud_name", "dpbsogbtr");

    fetch("https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        setImg2(res.url);
      })
      .catch((err) => {});
  };

  let handleImage3Upload = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "upload_photo");
    formData.append("cloud_name", "dpbsogbtr");

    fetch("https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        setImg3(res.url);
      })
      .catch((err) => {});
  };

  let handleImage4Upload = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "upload_photo");
    formData.append("cloud_name", "dpbsogbtr");

    fetch("https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        setImg4(res.url);
      })
      .catch((err) => {});
  };

  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  return (
    <div className="flex h-[100vh]">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Sidebar />
      <div className="w-[80vw] pt-6 px-6 overflow-y-scroll">
        <Topbar title={"Add New Card"} />
        <div className="mt-4">
          <div className="flex items-center">
            <div className="w-[50%]">
              <img
                src={mainImg ? mainImg : card}
                alt={mainImg ? mainImg : card}
                className="w-full rounded-3xl"
              />
              <input
                type="file"
                className="block m-auto mt-2 text-grey-500 file:mr-5 file:py-2 file:px-6 file:rounded-full file:border-0 file:text-sm file:font-mediumfile:bg-blue-50 file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50hover:file:text-amber-700"
                onChange={(e) => handleImageUpload(e)}
              />
            </div>
            <div className="w-[25%] ml-5">
              <input
                type="text"
                className="text-lg border border-black block px-4 py-1.5 w-full rounded-lg outline-none"
                placeholder="Card Name"
                value={data.title}
                onChange={(e) => {
                  setData({ ...data, title: e.target.value });
                }}
              />
              <select
                value={data.category}
                onChange={(e) => {
                  setData({ ...data, category: e.target.value });
                }}
                className="text-lg border border-black block px-4 py-1.5 w-full rounded-lg outline-none my-4"
              >
                {categories.map((e, id) => {
                  return (
                    <option value={e} key={id}>
                      {e}
                    </option>
                  );
                })}
              </select>
              <input
                type="number"
                className="text-lg border border-black block px-4 py-1.5 w-full rounded-lg outline-none mb-4"
                placeholder="Market Price"
                value={data.cutPrice}
                onChange={(e) => {
                  setData({ ...data, cutPrice: e.target.value });
                }}
              />
              <input
                type="number"
                className="text-lg border border-black block px-4 py-1.5 w-full mb-4 rounded-lg outline-none"
                placeholder="Amount"
                value={data.price}
                onChange={(e) => {
                  setData({ ...data, price: e.target.value });
                }}
              />
              <input
                type="number"
                className="text-lg border border-black block px-4 py-1.5 w-full rounded-lg outline-none"
                placeholder="Customized Price"
                value={data.customizedPrice}
                onChange={(e) => {
                  setData({ ...data, customizedPrice: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="flex mt-3 ml-1">
            <div className="w-[23%] mr-2">
              <img
                src={img1 ? img1 : card}
                alt={img1 ? img1 : card}
                className="w-[20vw] h-[10vw] object-cover object-center rounded-lg"
              />
              <input
                type="file"
                className="block m-auto mt-2 text-grey-500 file:mr-2 file:py-1 file:px-3 file:rounded-full file:border-0 file:text-sm file:font-mediumfile:bg-blue-50 file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50hover:file:text-amber-700"
                onChange={(e) => handleImage1Upload(e)}
              />
            </div>
            <div className="w-[23%] mr-2">
              <img
                src={img2 ? img2 : card}
                alt={img2 ? img2 : card}
                className="w-[20vw] h-[10vw] object-cover object-center rounded-lg"
              />
              <input
                type="file"
                className="block m-auto mt-2 text-grey-500 file:mr-2 file:py-1 file:px-3 file:rounded-full file:border-0 file:text-sm file:font-mediumfile:bg-blue-50 file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50hover:file:text-amber-700"
                onChange={(e) => handleImage2Upload(e)}
              />
            </div>
            <div className="w-[23%] mr-2">
              <img
                src={img3 ? img3 : card}
                alt={img3 ? img3 : card}
                className="w-[20vw] h-[10vw] object-cover object-center rounded-lg"
              />
              <input
                type="file"
                className="block m-auto mt-2 text-grey-500 file:mr-2 file:py-1 file:px-3 file:rounded-full file:border-0 file:text-sm file:font-mediumfile:bg-blue-50 file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50hover:file:text-amber-700"
                onChange={(e) => handleImage3Upload(e)}
              />
            </div>
            <div className="w-[23%] mr-2">
              <img
                src={img4 ? img4 : card}
                alt={img4 ? img4 : card}
                className="w-[20vw] h-[10vw] object-cover object-center rounded-lg"
              />
              <input
                type="file"
                onChange={(e) => handleImage4Upload(e)}
                className="block m-auto mt-2 text-grey-500 file:mr-2 file:py-1 file:px-3 file:rounded-full file:border-0 file:text-sm file:font-mediumfile:bg-blue-50 file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50hover:file:text-amber-700"
              />
            </div>
          </div>
          <textarea
            className="w-[95%] ml-1 mt-3 px-3 py-2 border outline-none border-black rounded-lg"
            cols="30"
            rows="4"
            placeholder="Description"
            value={data.desc}
            onChange={(e) => {
              setData({ ...data, desc: e.target.value });
            }}
          ></textarea>
          <button
            onClick={(e) => {
              e.preventDefault();
              axios
                .post(`${CARD_URI}/`, {
                  ...data,
                  desc: data?.desc.replace(/\n/g, "\\n"),
                  img1,
                  img2,
                  img3,
                  img4,
                  mainImg,
                })
                .then((res) => {
                  toast.success("Card Added successfully");
                  history("/admin/cards");
                })
                .catch((err) => {
                  toast.error("Internal server error");
                });
            }}
            className="m-auto block py-1.5 px-12 text-lg bg-adminGreen rounded-lg mt-1 hover:bg-transparent hover:border-2 hover:border-adminGreen hover:text-adminGreen hover:font-semibold mb-2"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
