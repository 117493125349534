import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { AiOutlineArrowUp } from "react-icons/ai";
import Topbar from "../Components/Topbar";
import axios from "axios";
import { ADMIN_URI } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import { useContext } from "react";
import TapKaroContext from "../../context/Context";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const getChartOptionsUpper = () => {
  return {
    scales: {
      x: {
        ticks: {
          color: "black", // X-axis label color
        },
      },
      y: {
        ticks: {
          color: "black", // Y-axis label color
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "black", // Set the label color here
        },
      },
    },
  };
};

const getChartOptions = () => {
  return {
    scales: {
      x: {
        ticks: {
          color: "white", // X-axis label color
        },
      },
      y: {
        ticks: {
          color: "white", // Y-axis label color
          callback: function (value) {
            // Display only numeric and positive values on the Y-axis
            if (Number.isInteger(value) && value >= 0) {
              return value;
            }
          },
        },
      },
    },
    ticks: {
      precision: 0,
    },
    plugins: {
      legend: {
        display: false, // Set the dataset label display to false
        labels: {
          color: "white", // Set the label color here (if you have other labels, not dataset label)
        },
      },
    },
  };
};

const Admin = () => {
  const [websiteVisit, setWebsiteVisit] = useState("Week");
  const [labelsWebsite, setLabelsWebsite] = useState([
    "Day 1",
    "Day 2",
    "Day 3",
    "Day 4",
    "Day 5",
    "Day 6",
    "Day 7",
  ]);
  const [orderReport, setOrderReport] = useState();
  const [revenueReport, setRevenueReport] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [getUsersReport, setGetUsersReport] = useState({
    month: [],
    week: [],
  });

  useEffect(() => {
    axios
      .get(`${ADMIN_URI}/getOrderReport`)
      .then((response) => {
        setOrderReport(response.data);
      })
      .catch((err) => {});
    axios
      .get(`${ADMIN_URI}/getRevenue`)
      .then((response) => {
        setRevenueReport(response.data.report);
        setTotalRevenue(response.data.total);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (websiteVisit === "Month") {
      setLabelsWebsite([
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ]);
    } else if (websiteVisit === "Week") {
      setLabelsWebsite([
        "Day 1",
        "Day 2",
        "Day 3",
        "Day 4",
        "Day 5",
        "Day 6",
        "Day 7",
      ]);
    }
  }, [websiteVisit]);

  useEffect(() => {
    axios
      .get(`${ADMIN_URI}/getUsersReport`)
      .then((res) => {
        setGetUsersReport({
          month: res.data.month,
          week: res.data.week,
        });
      })
      .catch((err) => {});
  }, []);

  const data = {
    labels,
    datasets: [
      {
        label: "Total Revenue",
        data: [...revenueReport],
        backgroundColor: "black",
        borderRadius: 5,
      },
    ],
  };

  const dataWebsite = {
    labels: labelsWebsite,
    datasets: [
      {
        label: "Users Report",
        data:
          websiteVisit === "Week"
            ? getUsersReport?.week
            : getUsersReport?.month,
        backgroundColor: "white",
        borderRadius: 5,
      },
    ],
  };

  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[80vw] pt-6 px-6">
        <Topbar title={"Admin Dashboard"} />
        <div className="flex justify-between">
          <div className="w-[80%] px-[1vw] mt-3">
            <div className="bg-adminlightBlue h-[36vh] mb-3 flex justify-between items-center w-full px-[2vw] py-2 rounded-3xl">
              <div className="w-[40%] flex flex-col justify-between h-full py-3">
                <div>
                  <p className="text-lg text-gray-700">Revenue</p>
                  <h1 className="text-2xl font-bold">Rs. {totalRevenue}</h1>
                </div>
                <div className="flex items-center">
                  <AiOutlineArrowUp
                    size={32}
                    className="bg-black text-white rotate-[30deg] p-1 rounded-full mr-1"
                  />
                  <p className="text-xl ml-1 font-medium">45%</p>
                </div>
              </div>
              <div className="w-[60%] h-full">
                <Bar data={data} options={getChartOptionsUpper()} />
              </div>
            </div>
            <div className="bg-adminBlue text-white h-[42vh] w-full px-5 rounded-3xl">
              <div className="w-full flex justify-between pt-3">
                <div>
                  <p className="text-lg text-gray-100">Users Report</p>
                  <h1 className="text-2xl font-bold flex items-center">
                    <AiOutlineArrowUp className="text-adminGreen mr-1" />
                    12.33%
                  </h1>
                </div>
                <div className="flex justify-center items-center">
                  <select
                    onChange={(e) => {
                      setWebsiteVisit(e.target.value);
                    }}
                    className="block text-black outline-none px-4 py-0.5 rounded-lg"
                  >
                    <option value="Week">Week</option>
                    <option value="Month">Month</option>
                  </select>
                </div>
              </div>
              <div className="m-auto flex justify-center items-center h-[69%]">
                <Bar data={dataWebsite} options={getChartOptions()} />
              </div>
            </div>
          </div>
          <div className="w-[25%] bg-black h-[80vh] mt-3 rounded-3xl text-white px-4 pt-3">
            <h1 className="text-3xl font-semibold text-center">Orders</h1>
            <div className="flex flex-col justify-between items-center h-[78%] mt-[10%]">
              <div className="bg-white text-black w-[40%] flex flex-col justify-between items-center py-2 rounded-lg">
                This Day
                <div className="flex items-center">
                  <p className="text-2xl font-semibold">{orderReport?.day}</p>
                  <AiOutlineArrowUp
                    size={24}
                    className="ml-1 text-adminGreen"
                  />
                </div>
              </div>
              <div className="border bg-white text-black w-[40%] flex flex-col justify-between items-center py-2 rounded-lg">
                This Week
                <div className="flex items-center">
                  <p className="text-2xl font-semibold">{orderReport?.week}</p>
                  <AiOutlineArrowUp
                    size={24}
                    className="ml-1 text-adminGreen"
                  />
                </div>
              </div>
              <div className="border bg-white text-black w-[40%] flex flex-col justify-between items-center py-2 rounded-lg">
                This Month
                <div className="flex items-center">
                  <p className="text-2xl font-semibold">{orderReport?.month}</p>
                  <AiOutlineArrowUp
                    size={24}
                    className="ml-1 text-adminGreen"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
