import React, { useEffect, useRef, useState } from "react";
import { AiOutlineInstagram, AiOutlineLinkedin } from "react-icons/ai";

import ellipse16 from "../images/Ellipse 16.png";
import logo from "../images/tap karo logo.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useNavigate } from "react-router";
import { BASE_URI, QUERY_URI } from "../utils";
import axios from "axios";
import Axios from "axios";
import Captcha from "./Captcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Footer = ({ footer, home }) => {
  const captchaRef = useRef(null);
  const captchaRefMobile = useRef(null);
  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const history = useNavigate();

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.fromTo(
      "#bottomFooter",
      {
        y: -200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#topFooter",
          start: window.innerWidth < 550 ? "top 80%" : "top 40%",
          end: window.innerWidth < 550 ? "top 50%" : "top 10%",
          // scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#leftFooter",
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#topFooter",
          start: window.innerWidth < 550 ? "top 75%" : "top 25%",
          end: window.innerWidth < 550 ? "top 40%" : "top 0%",
          // scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#rightFooter",
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#topFooter",
          start: window.innerWidth < 550 ? "top 75%" : "top 25%",
          end: window.innerWidth < 550 ? "top 40%" : "top 0%",
          // scrub: true,
        },
      }
    );
    gsap.fromTo(
      "#ellipse16",
      {
        width: 0,
        opacity: 0,
      },
      {
        width: 200,
        opacity: 1,
        scrollTrigger: {
          trigger: "#topFooter",
          start: window.innerWidth < 550 ? "top 80%" : "top 40%",
          end: window.innerWidth < 550 ? "top 50%" : "top 10%",
          // scrub: true,
        },
      }
    );
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        ref={footer}
        id="topFooter"
        className={`w-full h-[45vw] bg-navyBlue flex-col justify-between relative md:flex mobile:hidden`}
      >
        <div className="absolute right-0 top-[-20%] z-0">
          <img id="ellipse16" src={ellipse16} alt={ellipse16} />
        </div>
        <div></div>
        <div
          id="bottomFooter"
          className="bg-gradient-to-b from-[#002e53] to-[#2c0752] h-[80%]"
        >
          <div className="relative flex justify-between p-5 h-fit bottom-0">
            <div
              id="leftFooter"
              className="flex items-center justify-between flex-col h-full w-full p-5"
            >
              <div className="text-gray-200 flex justify-evenly w-full">
                <p
                  className="cursor-pointer font-semibold"
                  onClick={(e) => {
                    history("/about");
                  }}
                >
                  About
                </p>
                <p
                  className="cursor-pointer font-semibold"
                  onClick={(e) => {
                    history("/shop");
                  }}
                >
                  Shop
                </p>
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    history("/terms");
                  }}
                  className="cursor-pointer font-semibold"
                >
                  Terms and Conditions
                </p>
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    history("/privacy-policy");
                  }}
                  className="cursor-pointer font-semibold"
                >
                  Privacy Policy
                </p>
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    history("/refund");
                  }}
                  className="cursor-pointer font-semibold"
                >
                  Refund Policy
                </p>
              </div>
              <img
                src={logo}
                alt={logo}
                className="w-[25%] block mt-20 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  history("/");
                  home?.current?.scrollIntoView({ scrollBehavior: "smooth" });
                }}
              />
              <p className="text-gray-300 text-xl font-semibold text-center w-[75%]">
                Tap Karo . Scan Karo . Connect Karo
              </p>
              <p
                className="text-gray-300 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  window.open("tel:+919810816367");
                }}
              >
                +91 98108 16367
              </p>
              <p
                className="text-gray-300 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  window.open("mailto:care@tapkaro.com");
                }}
              >
                care@tapkaro.com
              </p>
              <p className="text-gray-300">
                17, Max Arcade, Sector 76, Noida
              </p>
              <p className="text-gray-300">GST :- 09BHEPS2791A2Z9</p>
            </div>
            <div
              id="rightFooter"
              className="bg-gradient-to-b from-[#0f6386] opacity-50 backdrop-blur-lg text-white py-2 px-5 to-[#021731] h-fit w-[35%] -mb-64 z-10 ml-12 rounded-3xl flex flex-col items-center"
            >
              <h1 className="text-center text-2xl my-2 font-medium">
                Send us Your Enquiry
              </h1>
              <input
                type="text"
                value={data.name}
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
                className="input"
                placeholder="Name"
              />
              <input
                type="email"
                value={data.email}
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
                className="input"
                placeholder="Email"
              />
              <input
                type="number"
                value={data.mobile}
                onChange={(e) => {
                  setData({ ...data, mobile: e.target.value });
                }}
                className="input"
                placeholder="Contact"
              />
              <textarea
                className="input"
                id=""
                value={data.message}
                onChange={(e) => {
                  setData({ ...data, message: e.target.value });
                }}
                cols="30"
                rows="2"
                placeholder="Message"
              ></textarea>
              <div className="flex items-center w-[100%] justify-center my-2">
                <Captcha captchaRef={captchaRefMobile} />
              </div>
              <button
                onClick={async (e) => {
                  e.preventDefault();
                  let token = captchaRefMobile.current.getValue();
                  if (token) {
                    let response = await Axios.post(`${BASE_URI}verify-token`, {
                      reCAPTCHA_TOKEN: token,
                    });
                    if (
                      response.status === 200 &&
                      response.data.verification_info.success === true
                    ) {
                      axios
                        .post(`${QUERY_URI}`, {
                          ...data,
                        })
                        .then((res) => {
                          toast.success("Query added successfully");
                          setData({
                            name: "",
                            email: "",
                            mobile: "",
                            message: "",
                          });
                        })
                        .catch((err) => {
                          // toast.error("Internal server error");
                        });
                      captchaRefMobile.current.reset();
                    } else {
                      toast.warning("User not verified");
                    }
                  } else {
                    toast.warning("User not verified");
                  }
                }}
                className="btn m-auto"
              >
                Submit
              </button>
            </div>
          </div>
          <div className="p-2 text-gray-300 flex justify-between items-center bottom-0 absolute w-full">
            <p>© 2023 Tap Karo. All Rights Reserved.</p>

            <div className="flex items-center">
              <p>Follow us on:</p>
              <div className="w-[4vw] justify-between mx-2 flex">
                <AiOutlineInstagram
                  size={25}
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("https://instagram.com/tapkarodotcom/");
                  }}
                />
                <AiOutlineLinkedin
                  size={25}
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("https://www.linkedin.com/company/tapkaro/");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="topFooter"
        ref={footer}
        className={`w-[100vw] h-fit bg-navyBlue flex-col justify-between items-center relative mobile:flex md:hidden`}
      >
        <div className="absolute right-0 top-[-20%]">
          <img id="ellipse16" src={ellipse16} alt={ellipse16} />
        </div>
        <div
          id="rightFooter"
          className="bg-gradient-to-b from-[#0f6386] h-fit backdrop-blur-lg my-5 text-white py-2 px-5 to-[#021731] w-[85%] z-10 rounded-3xl flex flex-col items-center justify-between pb-5"
        >
          <h1 className="text-center text-2xl my-2 font-medium">
            Send us Your Enquiry
          </h1>
          <input
            type="text"
            value={data.name}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
            className="input"
            placeholder="Name"
          />
          <input
            type="email"
            value={data.email}
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
            }}
            className="input"
            placeholder="Email"
          />
          <input
            type="number"
            value={data.mobile}
            onChange={(e) => {
              setData({ ...data, mobile: e.target.value });
            }}
            className="input"
            placeholder="Contact"
          />
          <textarea
            className="input h-full"
            id=""
            value={data.message}
            onChange={(e) => {
              setData({ ...data, message: e.target.value });
            }}
            cols="30"
            rows="1"
            placeholder="Message"
          ></textarea>
          <div className="flex items-center w-[100%] justify-center my-2">
            <Captcha captchaRef={captchaRef} />
          </div>
          <button
            onClick={async (e) => {
              e.preventDefault();
              let token = captchaRef.current.getValue();
              if (token) {
                let response = await Axios.post(`${BASE_URI}verify-token`, {
                  reCAPTCHA_TOKEN: token,
                });
                if (
                  response.status === 200 &&
                  response.data.verification_info.success === true
                ) {
                  axios
                    .post(`${QUERY_URI}`, {
                      ...data,
                    })
                    .then((res) => {
                      toast.success("Query added successfully");
                      setData({
                        name: "",
                        email: "",
                        mobile: "",
                        message: "",
                      });
                    })
                    .catch((err) => {
                      // toast.error("Internal server error");
                    });
                  captchaRef.current.reset();
                } else {
                  toast.warning("User not verified");
                }
              } else {
                toast.warning("User not verified");
              }
            }}
            className="btn"
          >
            Submit
          </button>
        </div>
        <div
          id="bottomFooter"
          className="bg-gradient-to-b w-full flex h-fit flex-col items-center justify-center from-[#002e53] to-[#2c0752] h-[50vh]"
        >
          <div className="relative flex justify-between p-5 h-[35vh] bottom-0 w-full">
            <div
              id="leftFooter"
              className="flex items-center justify-between flex-col h-full"
            >
              <div className="text-gray-200 flex justify-evenly items-center text-sm mx-auto w-full">
                <p
                  className="text-white font-semibold mr-5"
                  onClick={(e) => {
                    history("/about");
                  }}
                >
                  About
                </p>
                <p
                  className="cursor-pointer text-center font-semibold"
                  onClick={(e) => {
                    history("/shop");
                  }}
                >
                  Shop
                </p>
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    history("/terms");
                  }}
                  className="cursor-pointer text-center font-semibold"
                >
                  Terms and Conditions
                </p>
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    history("/privacy-policy");
                  }}
                  className="cursor-pointer text-center font-semibold"
                >
                  Privacy Policy
                </p>
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    history("/refund");
                  }}
                  className="cursor-pointer font-semibold text-center"
                >
                  Refund Policy
                </p>
              </div>
              <img
                src={logo}
                alt={logo}
                className="w-[50%] block mt-10 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  history("/");
                }}
              />
              <p className="text-gray-300 text-xl font-semibold text-center w-full pt-4">
                Tap Karo . Scan Karo . Connect Karo
              </p>
            </div>
          </div>
          <div className="text-gray-300 flex justify-between flex-col items-center mt-6">
            <p>© 2023 Tap Karo. All Rights Reserved.</p>

            <div className="flex items-center mt-1">
              <p>Follow us on:</p>
              <div className="w-[12vw] justify-between mx-2 flex">
                <AiOutlineInstagram
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("https://instagram.com/tapkarodotcom/");
                  }}
                  size={20}
                />
                <AiOutlineLinkedin
                  size={20}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open("https://www.linkedin.com/company/tapkaro/");
                  }}
                />
              </div>
            </div>
            <p
              onClick={(e) => {
                e.preventDefault();
                window.open("tel:+919810816367");
              }}
            >
              +91 98108 16367
            </p>
            <p
              onClick={(e) => {
                e.preventDefault();
                window.open("mailto:care@tapkaro.com");
              }}
            >
              care@tapkaro.com
            </p>
            <p>17, Max Arcade, Sector 76, Noida</p>
            <p className="pb-2">GST :- 09BHEPS2791A2Z9</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
