import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({ captchaRef }) => {
  return (
    <ReCAPTCHA
      className="recaptcha"
      sitekey={"6LcwyiMnAAAAAIw5Ra2bwR8hYIz2QRQ33duZ1c-Y"}
      ref={captchaRef}
    />
  );
};

export default Captcha;
