// LOGIN
export const LOGIN_URI = "https://tapkaro.com:5000/api/login";
export const BASE_URI = "https://tapkaro.com:5000/api/";
export const QUERY_URI = "https://tapkaro.com:5000/api/query";

// Admin
export const ANNOUNCE_URI = "https://tapkaro.com:5000/api/announce-tokens";
export const ORDER_URI = "https://tapkaro.com:5000/api/orders";
export const ADMIN_URI = "https://tapkaro.com:5000/api/admin";
export const CARD_URI = "https://tapkaro.com:5000/api/cards";
export const BUSSINESS_URI = "https://tapkaro.com:5000/api/bussiness";
export const OFFER_URI = "https://tapkaro.com:5000/api/offers";
export const POP_URI = "https://tapkaro.com:5000/api/pop-ups";

// Profile
export const PROFILE_URI = "https://tapkaro.com:5000/api/profile";
