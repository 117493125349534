import React, { useContext, useEffect, useRef, useState } from "react";
import image from "../images/Free_3,5x2,5in_Business_Card_6.png";
import logo from "../images/tap karo logo.png";
import { gsap } from "gsap";
import { AiOutlineFilter } from "react-icons/ai";
import { BsSortDownAlt } from "react-icons/bs";
import { ScrollTrigger } from "gsap/all";
import { useNavigate } from "react-router";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import TapKaroContext from "../context/Context";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ShopNow from "../Components/ShopNow";
import Helmet from "react-helmet";

const ClassicCards = () => {
  const ref = useRef([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sortbydropdown, setSortbydropdown] = useState(false);
  const { cards, getCards } = useContext(TapKaroContext);
  const [sortType, setSortType] = useState("Relevence");
  const [filterType, setFilterType] = useState("All");
  const [viewLoadMore, setViewLoadMore] = useState(false);

  useEffect(() => {
    gsap.fromTo(
      "#leftShop",
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1.5,
      }
    );
    gsap.fromTo(
      "#rightShop",
      {
        y: 500,
        opacity: 0,
        rotate: 50,
      },
      {
        y: 0,
        rotate: 0,
        opacity: 1,
        duration: 1.5,
      }
    );
  }, []);

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.fromTo(
      "#leftHeading",
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggerHeading",
          // scrub: true,
          start: "top 90%",
          end: "top 60%",
        },
      }
    );
    gsap.fromTo(
      "#rightHeading",
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggerHeading",
          // scrub: true,
          start: "top 90%",
          end: "top 60%",
        },
      }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      ref.current,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.5,
        scrollTrigger: {
          trigger: "#blocksTrigger",
          // scrub: true,
          start: "top 50%",
          end: "top 0%",
        },
      }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      "#testCircles",
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#testCircles",
          start: window.innerWidth < 550 ? "top 90%" : "top 80%",
          end: window.innerWidth < 550 ? "top 70%" : "top 40%",
          // scrub: true,
        },
      }
    );
  }, []);

  const context = useContext(TapKaroContext);

  useEffect(() => {
    getCards();
  }, [context?.cardPage]);

  useEffect(() => {
    setViewLoadMore(
      context?.cardsConfig?.size * context?.cardPage < context?.cardsConfig?.max
    );
  }, [context?.cardPage, context?.cardsConfig?.max]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const reviews = [
    {
      name: "Rishab",
      img: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80",
      desc: "The best part like about this, Tap Karo card worked without downloading any application. ",
    },
    {
      name: "Ankur Pandey",
      img: "https://images.pexels.com/photos/9271168/pexels-photo-9271168.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "This is what I was looking for. This solved all my problems of sharing complete profile all at once.",
    },
    {
      name: "Vishwesh",
      img: "https://images.pexels.com/photos/12311514/pexels-photo-12311514.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "I do not save number by typing now. Just tap on phone and download cnotacts. That is so easy to connect now.",
    },
    {
      name: "Promila Bhardwaj",
      img: "https://images.pexels.com/photos/1844012/pexels-photo-1844012.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "My instagram Tap Karo card helped me to gain followers. That has become my things to carry along with now.",
    },
  ];

  const { categories } = useContext(TapKaroContext);

  return (
    <div className="overflow-x-hidden">
      <Nav />
      <ShopNow />
      <Helmet>
        <title>
          TAP KARO Digital Business Cards | Shop NFC and QR Code Cards
        </title>
        <meta
          name="description"
          content="TAP KARO Cards | Explore range of NFC & QR Code Cards! Unlock Great Designs, Create an Impressive Digital Profile & Leverage Great Analytics for Powerful Networking"
        />
        {/* <link rel="canonical" href="https://tapkaro.com/shop" />
        <meta name="robots" content="index, follow"></meta> */}
      </Helmet>
      <div>
        <div
          className="h-fit w-full flex md:flex-row mobile:flex-col justify-between px-[5.5vw] items-center mobile:pt-[10vh] md:pt-[15vh]"
          style={{
            background:
              "linear-gradient(0deg, rgba(9,22,40,1) 0%, rgba(27,117,189,1) 45%, rgba(9,22,40,1) 100%)",
          }}
        >
          <div
            id="leftShop"
            className="text-white mobile:w-full md:w-[75%] md:mt-0 mobile:mt-6 mobile:px-3 md:px-0"
          >
            <p
              style={
                window.innerWidth < 550
                  ? { lineHeight: "55px" }
                  : { lineHeight: "100px" }
              }
              className="md:text-7xl mobile:text-4xl font-bold"
            >
              Make Your Networking Easy With Us
            </p>
            <div className="flex items-center text-3xl font-bold ml-2 mobile:mt-6 md:mt-[20vh]">
              Just{" "}
              <img
                src={logo}
                alt={logo}
                className="mobile:w-[40%] md:w-[25%] ml-3"
              />
            </div>
          </div>
          <img
            id="rightShop"
            src={image}
            alt={image}
            className="md:mr-5 mobile:mr-2 md:w-[40%] brder mobile:w-full"
          />
        </div>
        <div
          id="triggerHeading"
          className="px-[6vw] mobile:py-4 md:py-0 flex items-center mobile:justify-end md:justify-between bg-[#091628] text-white"
        >
          <p
            id="leftHeading"
            className="text-xl border mobile:hidden md:block px-5 py-1 rounded-2xl cursor-pointer"
          >
            Tap Karo Cards
          </p>
          <div id="rightHeading" className="flex items-center z-50">
            <div className="relative z-50">
              <p
                className="flex items-center border px-2 mr-4 rounded-lg cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setSortbydropdown(!sortbydropdown);
                  setIsDropdownOpen(false);
                }}
              >
                <BsSortDownAlt size={20} className="mr-2" />
                Sort By
              </p>
              {sortbydropdown && (
                <div className="absolute top-5 right-0 mt-2 w-40 backdrop-blur-2xl bg-[#25374c] opacity-95 text-white rounded-md shadow-lg z-10">
                  <div className="py-1">
                    {[
                      "Relevence",
                      "Price Low to High",
                      "Price High To Low",
                    ].map((e, i) => {
                      return (
                        <p
                          key={i}
                          onClick={(element) => {
                            element.preventDefault();
                            setSortType(e);
                            setSortbydropdown(false);
                          }}
                          className={`${
                            sortType === e ? "text-gray-300" : "text-white"
                          } cursor-pointer text-center py-0.5 hover:text-gray-300`}
                        >
                          {e}
                        </p>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="relative z-50">
              <AiOutlineFilter
                size={25}
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setIsDropdownOpen(!isDropdownOpen);
                  setSortbydropdown(false);
                }}
              />
              {isDropdownOpen && (
                <div className="absolute top-5 right-0 mt-2 w-40 backdrop-blur-2xl bg-[#25374c] opacity-95 text-white rounded-md shadow-lg z-50">
                  <div className="py-1">
                    {["All", ...categories].map((e, i) => {
                      return (
                        <p
                          key={i}
                          onClick={(element) => {
                            element.preventDefault();
                            setFilterType(e);
                            setIsDropdownOpen(false);
                          }}
                          className={`${
                            filterType === e ? "text-gray-300" : "text-white"
                          } cursor-pointer text-center py-0.5 hover:text-gray-300`}
                        >
                          {e}
                        </p>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {cards
          ?.sort((a, b) => {
            if (sortType === "Price Low to High") {
              return a?.price - b?.price;
            } else if (sortType === "Price High To Low") {
              return b?.price - a?.price;
            }
            return a, b;
          })
          ?.filter((e) => {
            if (filterType !== "All") {
              return e?.category
                ?.toLowerCase()
                .includes(filterType.toLowerCase());
            }
            return e;
          })?.length > 0 ? (
          <>
            <div
              id="blocksTrigger"
              className="grid mobile:grid-cols-1 md:grid-cols-3 mobile:gap-6 md:gap-14 px-[5vw] bg-[#091628] pt-7 pb-6"
            >
              {cards
                ?.sort((a, b) => {
                  if (sortType === "Price Low to High") {
                    return a.price - b.price;
                  } else if (sortType === "Price High To Low") {
                    return b.price - a.price;
                  }
                  return a, b;
                })
                ?.filter((e) => {
                  if (filterType !== "All") {
                    return e?.category
                      ?.toLowerCase()
                      .includes(filterType.toLowerCase());
                  }
                  return e;
                })
                ?.map((e, i) => {
                  return (
                    <div
                      key={i}
                      ref={(element) => {
                        ref.current[i] = element;
                      }}
                    >
                      <Block data={e} />
                    </div>
                  );
                })}
            </div>
            <div className="w-full flex items-center justify-center bg-[#091628]">
              {viewLoadMore ? (
                <button
                  className="bg-gradient-to-br from-[#1B75BD] text-lg to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] px-6 py-0.5 rounded-lg text-white"
                  onClick={(e) => {
                    e.preventDefault();
                    context.setCardPage(context.cardPage + 1);
                  }}
                >
                  Load More
                </button>
              ) : null}
            </div>
          </>
        ) : (
          <div className="w-full text-white text-2xl font-semibold text-center bg-[#091628] py-7">
            No Card Exists
          </div>
        )}
        <div className="z-10 h-full mobile:py-7 md:py-0 bg-[#091628] text-white">
          {window.innerWidth < 550 ? (
            <div
              id="triggerSqure"
              className="flex flex-col justify-evenly mobile:mt-12 md:my-24 z-50 md:pb-0 mobile:pb-11"
            >
              <h1 className="text-center text-4xl mobile:pb-3 md:pb-0 font-semibold">
                Our Proud Tappers
              </h1>
              <div
                id="testSqures"
                className="flex md:items-center w-[100vw] mobile:h-[45vh] md:h-[40vh] mobile:mt-2 md:mt-5 md:mx-[10vw] md:justify-between mobile:mx-[5vw]"
              >
                <Carousel
                  showArrows={true}
                  showThumbs={false}
                  autoPlay={true}
                  infiniteLoop={true}
                  interval={5000}
                  swipeable={true}
                  emulateTouch={true}
                >
                  {reviews.map((e, i) => {
                    return <TestMobile data={e} key={i} />;
                  })}
                </Carousel>
              </div>
            </div>
          ) : (
            <div
              id="triggerSqure"
              className="h-[75vh] flex flex-col justify-evenly mobile:pt-12"
            >
              <h1 className="text-center text-4xl font-semibold">
                Our Proud Tappers
              </h1>
              <div
                id="testSqures"
                className="md:flex mobile:grid mobile:grid-cols-2 md:gap-10 mobile:gap-4 justify-between mobile:px-4 md:px-[8vw] mobile:mx-0 md:mx-[2vw] h-[45vh] z-50"
              >
                {reviews?.map((e, i) => {
                  return <TestDesktop data={e} key={i} />;
                })}
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

const Block = ({ data }) => {
  const history = useNavigate();
  const { cartData, setCartData } = useContext(TapKaroContext);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCartData(JSON.parse(localStorage.getItem("cart")));
    } else {
      setCartData([]);
    }
  }, [localStorage]);

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        history(`/shop/${data?._id}`);
      }}
      className="p-4 rounded-lg shadow-lg shadow-navyBlue mx-[3vw] border z-0 border-gray-600 cursor-pointer transition-all hover:scale-105"
    >
      <div className="bg-[#091628] rounded-lg flex justify-center items-center">
        <img src={data?.mainImg} alt={data?.mainImg} />
      </div>
      <h1 className="text-white text-center mt-1 mobile:text-2xl md:text-2xl font-bold">
        {data?.title}
      </h1>
      <div className="flex justify-between items-center pt-2">
        <p className="text-white">
          Rs.{" "}
          <span className="line-through text-red-400">{data?.cutPrice}</span>{" "}
          {data?.price} /-
        </p>
        <button
          onClick={(e) => {
            e.stopPropagation();
            let carts = localStorage.getItem("cart");
            carts = JSON.parse(carts);
            localStorage.setItem(
              "cart",
              JSON.stringify([
                ...cartData,
                {
                  ...data,
                  quantity: 1,
                  type: "Standard",
                  customization: { type: "Standard" },
                },
              ])
            );
            setCartData(JSON.parse(localStorage.getItem("cart")));
          }}
          className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] mobile:px-4 md:text-base mobile:text-lg md:px-3 rounded-lg py-1 text-white"
          disabled={
            cartData?.find((e) => e?._id === data?._id)?._id?.length > 1
          }
        >
          {cartData?.find((e) => e?._id === data?._id)?._id?.length > 1
            ? "Added"
            : "Add To Cart"}
        </button>
      </div>
    </div>
  );
};

const TestMobile = ({ data }) => {
  return (
    <div className="offScroll bg-gradient-to-tr from-[#fb00ff70] via-[#00b3b6] to-[#ffffff96] flex flex-col items-center justify-center md:w-[18vw] mobile:w-[90vw] border mobile:h-[45vh] mobile:px-1 md:px-0 md:h-full rounded-3xl mobile:opacity-100 cursor-pointer md:opacity-60 hover:opacity-100">
      <div className="md:w-[7vw] md:h-[7vw] mobile:w-[35vw] mobile:h-[35vw]">
        <img
          src={data?.img}
          alt="Default"
          className="rounded-full w-full h-full object-cover object-center"
        />
      </div>
      <h1 className="text-2xl mt-1 font-semibold text-center">{data?.name}</h1>
      <p className="text-center text-lg px-3">{data?.desc}</p>
    </div>
  );
};

const TestDesktop = ({ data }) => {
  return (
    <div className="transitionAddKro backdrop-blur-2xl bg-gradient-to-tr from-[#fb00ff70] via-[#00b3b6] to-[#ffffff96] md:w-[22%] h-[45vh] mobile:h-full rounded-3xl flex flex-col items-center mobile:opacity-100 md:opacity-60 hover:opacity-100 cursor-pointer md:py-3 mobile:py-3 z-30 overflow-y-scroll">
      <img
        src={data?.img}
        alt="Default"
        className="rounded-full mobile:w-[20vw] md:w-[7vw] mobile:h-[20vw] md:h-[7vw] object-cover object-center"
      />
      <h1 className="text-xl mt-1 font-semibold text-center">{data?.name}</h1>
      <p className="text-center px-3">{data?.desc}</p>
    </div>
  );
};

export default ClassicCards;
