import React from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { useEffect } from "react";

const Refund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="overflow-x-hidden">
      <Nav />
      <div className="h-fit bg-navyBlue text-white mobile:py-[13vh] mobile:px-[4vh] md:py-[14vh] md:px-[14vh]">
        <div className="mobile:w-full md:w-[65%]">
          <h1 className="text-4xl font-bold mb-3">Return and Refund Policy</h1>
          <div className="mobile:pl-1 md:pl-3">
            <p className="mb-3 mt-1">Last updated: July 20, 2023</p>
            <p className="my-1">
              Thank you for shopping at <b>Tap Karo</b>.
            </p>
            <p className="py-2">
              If, for any reason, You are not completely satisfied with a
              purchase We invite You to review our policy on refunds and
              returns.
            </p>
            <p className="my-2">
              The following terms are applicable for any products that You
              purchased with Us.
            </p>
            <div>
              <h1 className="text-3xl font-bold pt-3">
                Interpretation and Definitions
              </h1>
              <div className="mobile:pl-1 md:pl-2">
                <h3 className="text-2xl font-semibold mt-3">Interpretation</h3>
                <p className="leading-relaxed text-gray-200">
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
              </div>
              <div className="mobile:pl-1 md:pl-2">
                <h3 className="text-2xl font-semibold mt-3">Definitions</h3>
                <div className="text-gray-200">
                  <p className="pt-1 pb-3">
                    For the purposes of this Return and Refund Policy:
                  </p>
                  <p className="pb-2">
                    <b>Company</b> (referred to as either "the Company", "We",
                    "Us" or "Our" in this Agreement) refers to In Out Green, 17,
                    Sethi Arcade, Sector 76, Noida.
                  </p>
                  <p className="pb-2">
                    <b>Goods</b> refer to the items offered for sale on the
                    Service.
                  </p>
                  <p className="pb-2">
                    <b>Orders</b> mean a request by You to purchase Goods from
                    Us.
                  </p>
                  <p className="pb-2">
                    <b>Service</b> refers to the Website.
                  </p>
                  <p className="pb-2">
                    <b>Website</b> refers to Tap Karo, accessible from
                    https://tapkaro.com
                  </p>
                  <p className="pb-2">
                    <b>You</b> means the individual accessing or using the
                    Service, or the company, or other legal entity on behalf of
                    which such individual is accessing or using the Service, as
                    applicable.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h1 className="text-3xl font-bold pt-3">
                Your Order Cancellation Rights
              </h1>
              <div className="md:pl-2 mobile:pl-0 text-gray-200">
                <p className="leading-relaxed pb-2">
                  You are entitled to cancel Your Order within 3 days without
                  giving any reason for doing so.
                </p>
                <p className="leading-relaxed pb-2">
                  The deadline for cancelling an Order is 3 days from the date
                  on which You received the Goods or on which a third party you
                  have appointed, who is not the carrier, takes possession of
                  the product delivered.
                </p>
                <p className="leading-relaxed pb-2">
                  In order to exercise Your right of cancellation, You must
                  inform Us of your decision by means of a clear statement. You
                  can inform us of your decision by:
                </p>
                <p className="leading-relaxed pb-2">
                  By email: care [at] tapkaro.com
                </p>
                <p className="leading-relaxed pb-2">
                  We will reimburse You no later than 14 days from the day on
                  which We receive the returned Goods. We will use the same
                  means of payment as You used for the Order, and You will not
                  incur any fees for such reimbursement.
                </p>
              </div>
            </div>
            <div>
              <h1 className="text-3xl font-bold pt-3">
                Conditions for Returns
              </h1>
              <div className="md:pl-2 mobile:pl-0 text-gray-200">
                <p className="leading-relaxed pb-2">
                  In order for the Goods to be eligible for a return, please
                  make sure that:
                </p>
                <p className="leading-relaxed pb-2">
                  The Goods were purchased in the last 3 days
                  <br />
                  The Goods are in the original packaging
                  <br />
                  The following Goods cannot be returned:
                </p>
                <p className="leading-relaxed pb-2">
                  The supply of Goods made to Your specifications or clearly
                  personalized/customized.
                </p>
                <p className="leading-relaxed pb-2">
                  The supply of Goods which are written over by any app and
                  locked i.e. now no other data can be written on those goods.
                </p>
                <p className="leading-relaxed pb-2">
                  We reserve the right to refuse returns of any merchandise that
                  does not meet the above return conditions in our sole
                  discretion.
                </p>
                <p className="leading-relaxed pb-2">
                  Only regular priced Goods may be refunded. Unfortunately,
                  Goods on sale or discount cannot be refunded.
                </p>
              </div>
            </div>
            <div>
              <h1 className="text-3xl font-bold pt-3">Returning Goods</h1>
              <div className="md:pl-2 text-gray-200">
                <p className="leading-relaxed pb-2">
                  You are responsible for the cost and risk of returning the
                  Goods to Us. You should send the Goods at the following
                  address:
                </p>
                <p className="leading-relaxed pb-2">
                  FF-17, Sethi Arcade, Sector 76, Noida - 201301
                </p>
                <p className="leading-loose pb-2">
                  We cannot be held responsible for Goods damaged or lost in
                  return shipment. Therefore, We recommend an insured and
                  trackable mail service. We are unable to issue a refund
                  without actual receipt of the Goods or proof of received
                  return delivery.
                </p>
              </div>
            </div>
            <div>
              <h1 className="text-3xl font-bold pt-3">Contact Us</h1>
              <div className="md:pl-2 text-gray-200 mt-2">
                <p className="leading-relaxed pb-2">
                  If you have any questions about our Returns and Refunds
                  Policy, please contact us:
                </p>
                <p className="leading-relaxed pb-2">
                  By email: care [at] tapkaro.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Block = ({ data }) => {
  return (
    <div className="py-3">
      <h1 className="font-semibold text-lg">{data?.title}</h1>
      <p className="text-gray-200">{data?.value}</p>
    </div>
  );
};

export default Refund;
