import React, { useEffect, useState } from "react";

import image from "../../images/image 2.png";
import ellipse12 from "../../images/Ellipse 12.png";

import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import ConnectPopUp from "../../Components/ConnectPopUp";

const Faq = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isInfuencers, setIsInfuencers] = useState(false);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.fromTo(
      "#leftTwoCard",
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggerTwoCards",
          // scrub: true,
          start: "top 50%",
          end: "top 10%",
        },
      }
    );
    gsap.fromTo(
      "#rightTwoCard",
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggerTwoCards",
          // scrub: true,
          start: "top 50%",
          end: "top 10%",
        },
      }
    );
    gsap.fromTo(
      "#ellipse12",
      {
        width: 0,
        opacity: 0,
      },
      {
        width: 200,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggerTwoCards",
          // scrub: true,
          start: "top 50%",
          end: "top 10%",
        },
      }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      "#question",
      {
        width: 0,
        opacity: 0,
      },
      {
        width: 120,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggered",
          // scrub: true,
          start: "top 50%",
          end: "top 30%",
        },
      }
    );
    gsap.fromTo(
      "#faqs",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggered",
          // scrub: true,
          start: window.innerWidth < 550 ? "top 40%" : "top 60%",
          end: window.innerWidth < 550 ? "top 0%" : "top 40%",
        },
      }
    );
  }, []);

  const faq = [
    {
      title: "How does Tap Karo card works ?",
      answer:
        "The Tap Karo card has a NFC chip inside it, which transfers your profile to the phone. Tap the Tap Karo Card on phone and a link will open up with your contact and social media links which can be saved instantly. Ahh No NFC in your phone? Do not worry. There is a QR code on the back of it. Tap Karo, Scan Karo, Connect Karo.",
    },
    {
      title: "Is Tap Karo card compatible for my phone ?",
      answer:
        "90% of all android new phones are coming up with NFC features built in. Also, you can scan Tap Karo QR code provided at the back of the Tap Karo card with any Payment Wallet Apps available on your phone. All iPhones models over and above iPhone 7, are NFC enabled.",
    },
    {
      title: "Can I order multiple cards for my team ?",
      answer:
        "Yes, you can order as many Tap Karo cards as you want. Just fill the form down below and one of our executive will rech out to know your requirements as fast as possible.",
    },
    {
      title: "Can I update the information on my Tap Karo card ?",
      answer:
        "You can update the information multiple times. Your Tap Karo card has feature to add/remove any number of link at any point of time. Any changes edited/updated shows up instantly on your profile. ",
    },
    {
      title: "What are different Tap Karo cards option to buy from ?",
      answer:
        "We have PVC cards, Matellic cards, Wooden cards which can be completely customised as per your requirement. ",
    },
    {
      title:
        "I am influencer and want to have my personal social media card. How can I get that ?",
      answer:
        "Yes you can buy any of your social media card on the basis of your followers platforms i.e. Instagram, Linkedin, Youtube and many more.",
    },
  ];

  return (
    <>
      <ConnectPopUp
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        isInfuencers={isInfuencers}
      />
      <div className="mobile:h-fit md:h-[80vh] mobile:px-[6vw] md:px-0 w-full bg-navyBlue flex justify-center items-start">
        <div
          id="triggerTwoCards"
          className="flex md:flex-row mobile:flex-col justify-between items-center mobile:w-[100%] md:w-[64%] mobile:h-fit md:h-[49%] mobile:my-10 md:my-[3%] z-[0]"
        >
          <div
            id="leftTwoCard"
            className="bg-card1 bg-no-repeat text-white flex mobile:py-4 md:py-0 flex-col items-center justify-evenly mobile:h-full md:h-full mobile:w-full md:w-[50%] bg-cover rounded-lg md:mr-5 mobile:mr-0"
          >
            <h1 className="text-2xl uppercase font-medium">Influencers</h1>
            <p className="w-[60%] text-center">
              Reach out to Tap Karo to collaborate and earn a continuos income.
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(!modalIsOpen);
                setIsInfuencers(true);
              }}
              className="btn"
            >
              Connect With Us
            </button>
          </div>
          <div
            id="rightTwoCard"
            className="bg-card2 bg-no-repeat text-white flex mobile:py-4 mobile:mt-7 md:mt-0 md:py-0 flex-col items-center justify-evenly mobile:w-full rounded-lg md:w-[50%] bg-cover mobile:h-full md:h-full  md:ml-5 mobile:ml-0"
          >
            <h1 className="text-2xl uppercase font-medium">Partners</h1>
            <p className="w-[60%] text-center">
              Partner with us and be our ambassador / affiliate to make an extra
              earning
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(!modalIsOpen);
                setIsInfuencers(false);
              }}
              className="btn"
            >
              Connect With Us
            </button>
          </div>
        </div>
      </div>
      <div
        id="triggered"
        className="relative w-full h-[70vh] bg-navyBlue text-white flex justify-center items-center"
      >
        <img
          id="ellipse12"
          src={ellipse12}
          alt={ellipse12}
          className="absolute left-0 -z-0 mobile:top-0 md:-top-64"
        />
        <img
          id="question"
          src={image}
          alt={image}
          className="absolute md:-top-16 mobile:top-[10vw] md:right-[23vw] mobile:right-[-5vw]"
        />
        <div
          id="faqs"
          className=" bg-gradient-to-b z-0 from-[#d9d9d938] to-[#c437c72c] rounded-3xl backdrop-blur-lg mobile:w-[90vw] md:w-[45vw] mobile:h-[60vh] md:h-[70vh] border border-gray-500 py-4"
        >
          <h1 className="text-2xl text-center font-medium md:px-0">
            Frequently Asked Questions
          </h1>
          <div className="overflow-y-auto h-[90%]">
            {faq.map((e, i) => {
              return <Block data={e} key={i} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const Block = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className={`${
        open ? "bg-white" : "bg-transparent border border-white"
      } my-3 w-[75%] m-auto p-2 rounded-lg ${
        open ? "text-darkNavyBlue" : "text-white"
      }`}
    >
      <div className="flex items-center justify-between">
        <h1 className={`${open ? "font-semibold" : "font-normal"} w-[92%]`}>
          {data?.title}
        </h1>
        <div>
          {open ? (
            <AiOutlineUp
              size={22}
              className="block"
              onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
              }}
            />
          ) : (
            <AiOutlineDown
              size={22}
              className="block"
              onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
              }}
            />
          )}
        </div>
      </div>
      {open ? <div className="px-1 pt-1">{data?.answer}</div> : null}
    </div>
  );
};

export default Faq;
