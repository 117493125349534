import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Topbar from "../Components/Topbar";
import axios from "axios";
import { QUERY_URI } from "../../utils/index";
import { useLocation, useNavigate } from "react-router";

const Query = () => {
  const [Query, setQuery] = useState([]);
  useEffect(() => {
    axios
      .get(`${QUERY_URI}/`)
      .then((res) => {
        setQuery(res.data);
      })
      .catch((Err) => {
      });
  }, []);

  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <div className="w-[80vw] pt-6 px-6">
        <Topbar title={"Queries"} />
        <div className="mt-3">
          <div
            className="grid bg-black text-white px-2.5 py-1 rounded-lg"
            style={{ gridTemplateColumns: "5% 12% 18% 12% 33% 10% 10%" }}
          >
            <p className="text-center">S. No.</p>
            <p className="text-center">Name</p>
            <p className="text-center">Email</p>
            <p className="text-center">Mobile</p>
            <p className="text-center">Message</p>
            <p className="text-center">Date</p>
            <p className="text-center">Flag</p>
          </div>
          <div className="h-[70vh] overflow-y-auto">
            {Query.map((e, i) => {
              return (
                <div
                  key={i}
                  className="grid bg-gray-200 text-darkNavyBlue px-2.5 py-2 my-2 rounded-lg items-center"
                  style={{ gridTemplateColumns: "5% 12% 18% 12% 33% 10% 10%" }}
                >
                  <p className="text-center">{i + 1}</p>
                  <p className="text-center">{e?.name}</p>
                  <p className="text-center border px-5 break-words">
                    {e?.email}
                  </p>
                  <p className="text-center">{e?.mobile}</p>
                  <p className="text-center px-5">{e?.message}</p>
                  <p className="text-center px-3">
                    {new Date(e?.date).toISOString().slice(0, 10)}
                  </p>
                  <p className="text-center text-sm">
                    <span className="border border-darkNavyBlue w-fit h-fit px-2 rounded-lg py-0.5">
                      {e?.flag}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Query;
