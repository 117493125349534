import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import QRCodeStyling from "qr-code-styling";
import obj from "../../Components/QR";
import {
  AiOutlineCopy,
  AiOutlineEdit,
  AiOutlineArrowLeft,
} from "react-icons/ai";
import { BiStats } from "react-icons/bi";
import copy from "copy-to-clipboard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

import location from "../../images/contact/location.png";
import url from "../../images/contact/url.png";
import mail from "../../images/contact/mail.png";
import call from "../../images/contact/call.png";
import ReactModal from "../../Mobile/Components/ReactModal";
import TapKaroContext from "../../context/Context";
import axios from "axios";
import { PROFILE_URI } from "../../utils";
import ContactUpdate from "../../Mobile/Components/ContactUpdate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InfinitySpin } from "react-loader-spinner";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  PointElement,
  Legend,
  BarElement,
  LineElement,
  Filler
);

const getChartOptions = () => {
  return {
    scales: {
      x: {
        ticks: {
          color: "white", // X-axis label color
        },
      },
      y: {
        ticks: {
          color: "white", // Y-axis label color
          callback: function (value) {
            // Display only numeric and positive values on the Y-axis
            if (Number.isInteger(value) && value >= 0) {
              return value;
            }
          },
        },
      },
    },
    elements: {
      point: {
        radius: 2, // Set the desired dot size here
      },
    },
    ticks: {
      precision: 0,
    },
    plugins: {
      legend: {
        display: false, // Set the dataset label display to false
        labels: {
          color: "white", // Set the label color here (if you have other labels, not dataset label)
        },
      },
    },
  };
};

const ViewEditFull = () => {
  const qr = useRef(null);
  const sideBar = useRef(null);
  const history = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [profileImg, setProfileImg] = useState();
  const [backgroundImg, setBackgroundImg] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [contactData, setContactData] = useState();
  const [loader, setLoader] = useState(false);
  const [monthlyOrDaily, setMonthlyOrDaily] = useState({
    taps: true,
    profile: true,
    links: true,
  });
  const {
    profile,
    getProfile,
    profileTaps,
    connectionTaps,
    socialTaps,
    dailyTapsReport,
    getConnectionTaps,
    getProfileTaps,
    getSocialIconsReport,
  } = useContext(TapKaroContext);
  const [data, setData] = useState({
    name: "",
    designation: "",
    company: "",
  });

  const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,
    ...obj,
    data: `${window.location.origin}/${profile?._id}`,
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setData({
        name: profile?.name,
        designation: profile?.designation,
        company: profile?.company,
      });
    } else {
      history("/login");
    }
  }, []);

  useEffect(() => {
    qrCode.update({
      data: `${window.location.origin}/${profile?._id}`,
    });
    if (!qr?.current?.innerHTML.includes("canvas")) {
      qrCode.append(qr.current);
    }
  }, [profile]);

  useEffect(() => {
    getSocialIconsReport();
  }, [monthlyOrDaily, profile]);

  useEffect(() => {
    getProfileTaps();
  }, [monthlyOrDaily, profile]);

  useEffect(() => {
    getConnectionTaps();
  }, [monthlyOrDaily, profile]);

  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  function fillArrayWithIncrement(startingValue, length) {
    const arr = Array.from({ length }, (_, index) => startingValue + index);
    return arr;
  }

  const resultArray = fillArrayWithIncrement(
    1,
    new Date(
      new Date(Date.now()).getFullYear(),
      new Date(Date.now()).getMonth() + 1,
      0
    ).getDate()
  );

  const dataWebsite = {
    labels: monthlyOrDaily?.taps ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        data: monthlyOrDaily?.taps
          ? [...dailyTapsReport?.taps]
          : [...profileTaps],
        label: "Website Visits",
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const dataConnections = {
    labels: monthlyOrDaily?.profile ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        label: "Profile Saved",
        data: monthlyOrDaily?.profile
          ? [...dailyTapsReport?.profile]
          : [...connectionTaps],
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const dataSocialTap = {
    labels: monthlyOrDaily?.links ? resultArray : months,
    datasets: [
      {
        fill: "origin",
        label: "Social icons Taps",
        data: monthlyOrDaily?.links
          ? [...dailyTapsReport?.link]
          : [...socialTaps],
        borderColor: "#fff",
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.7,
      },
    ],
  };

  const [type, setType] = useState(false);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ReactModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} type={type} />
      <ContactUpdate
        modalIsOpen={openModal}
        setIsOpen={setOpenModal}
        iconData={{
          phone: profile?.phone ? profile?.phone : "",
          location: profile?.location ? profile?.location : "",
          portfolio: profile?.portfolio ? profile?.portfolio : "",
          cCode: profile?.cCode ? profile?.cCode : 91,
        }}
        contactData={contactData}
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          history("/user");
        }}
        className="p-1 bg-white rounded-full absolute left-0 top-0 z-50 text-darkNavyBlue m-2 cursor-pointer"
      >
        <AiOutlineArrowLeft size={22} className="cursor-pointer" />
      </div>
      <div className="bg-fbg w-[100vw] h-[100vh] bg-no-repeat bg-cover">
        {loader ? (
          <div className="w-full flex justify-center items-center h-[80vh]">
            <InfinitySpin width="300" color="#fff" />
          </div>
        ) : (
          <div className="backdrop-blur-md w-full h-full flex justify-between">
            <div className="w-[75vw]">
              <div
                style={{
                  backgroundImage: `url(${
                    backgroundImg ? backgroundImg : profile?.coverPicture
                  })`,
                }}
                className="h-[35%] bg-cover bg-no-repeat bg-bottom relative"
              >
                <label htmlFor="file" className="underline cursor-pointer">
                  <input
                    type="file"
                    id="file"
                    className="border inline-block px-2 py-2 cursor-pointer"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setLoader(true);
                      const formData = new FormData();
                      formData.append("file", e.target.files[0]);
                      formData.append("upload_preset", "upload_photo");
                      formData.append("cloud_name", "dpbsogbtr");

                      fetch(
                        "https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload",
                        {
                          method: "POST",
                          body: formData,
                        }
                      )
                        .then((res) => res.json())
                        .then((res) => {
                          setBackgroundImg(res.url);
                          setLoader(false);
                        })
                        .catch((err) => {
                          setLoader(false);
                        });
                    }}
                  />
                  <AiOutlineEdit
                    size={25}
                    color="black"
                    className="bg-white p-1 rounded-full absolute top-1.5 right-2 z-30"
                  />
                </label>
              </div>
              <div className="h-[60%] px-3 text-white">
                <div className="flex items-start justify-between my-2.5 -mt-[15px]">
                  <div className="w-[60%] flex items-end relative">
                    <img
                      src={profileImg ? profileImg : profile?.profilePicture}
                      alt="user"
                      className="w-[8.5vw] h-[8.5vw] object-cover object-center rounded-lg"
                    />
                    <label
                      htmlFor="fileProfile"
                      className="underline cursor-pointer"
                    >
                      <input
                        type="file"
                        id="fileProfile"
                        className="border inline-block px-2 py-2 cursor-pointer"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setLoader(true);
                          const formData = new FormData();
                          formData.append("file", e.target.files[0]);
                          formData.append("upload_preset", "upload_photo");
                          formData.append("cloud_name", "dpbsogbtr");

                          fetch(
                            "https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload",
                            {
                              method: "POST",
                              body: formData,
                            }
                          )
                            .then((res) => res.json())
                            .then((res) => {
                              setProfileImg(res.url);
                              setLoader(false);
                            })
                            .catch((err) => {
                              setLoader(false);
                            });
                        }}
                      />
                      <AiOutlineEdit
                        size={25}
                        color="black"
                        className="bg-white p-1 rounded-full left-[6.5rem] absolute -bottom-2 z-30"
                      />
                    </label>
                    <div className="ml-5">
                      <input
                        type="text"
                        value={data?.name}
                        onChange={(e) => {
                          setData({ ...data, name: e.target.value });
                        }}
                        placeholder="Name"
                        className="border-b block mb-1 text-lg font-medium w-[80%] outline-none bg-transparent pb-1 pl-1"
                      />
                      <input
                        type="text"
                        value={data?.designation}
                        onChange={(e) => {
                          setData({ ...data, designation: e.target.value });
                        }}
                        placeholder="Designation"
                        className="border-b block mb-2 outline-none w-[80%] bg-transparent pb-1 pl-1"
                      />
                      <input
                        type="text"
                        value={data?.company}
                        onChange={(e) => {
                          setData({ ...data, company: e.target.value });
                        }}
                        placeholder="Company"
                        className="border-b block outline-none w-[80%] bg-transparent pb-1 pl-1"
                      />
                    </div>
                  </div>
                  <div className="mt-8">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        axios
                          .post(`${PROFILE_URI}/user/edit`, {
                            token: localStorage.getItem("token"),
                            name: data?.name ? data?.name : profile?.name,
                            designation: data?.designation
                              ? data?.designation
                              : profile?.designation,
                            company: data?.company
                              ? data?.company
                              : profile?.company,
                            profilePicture: profileImg
                              ? profileImg
                              : profile?.profilePicture,
                            coverPicture: backgroundImg
                              ? backgroundImg
                              : profile?.coverPicture,
                          })
                          .then((res) => {
                            history("/user");
                            getProfile();
                            toast.success("User details updated successfully");
                          })
                          .catch((err) => {
                            toast.error("Internal server error");
                          });
                      }}
                      className="bg-lightBlue px-5 py-0.5 mr-2.5 rounded-lg"
                    >
                      Update Details
                    </button>
                  </div>
                </div>
                <p className="font-semibold w-[12%] text-center">
                  {profile?.username}
                </p>
                <p className="text-sm text-end w-[18%] float-right">
                  Suggested Banner Image Size 1026 X 218 Pixels
                </p>
              </div>
            </div>
            <div className="bg-rbg h-full w-[25vw] flex flex-col items-center py-8 text-white px-4 overflow-y-auto">
              <div ref={qr} className="bg-white p-0.5 rounded-lg"></div>
              <div className="mt-4 flex items-center relative w-[90%]">
                <input
                  type="text"
                  value={`${window.location.origin}/${profile?._id}`}
                  className="block bg-transparent border border-white py-1.5 pl-2 w-full rounded-lg pr-9 outline-none"
                />
                <AiOutlineCopy
                  onClick={(e) => {
                    e.preventDefault();
                    copy(`${window.location.origin}/${profile?._id}`);
                    toast.success("Copied success");
                  }}
                  size={28}
                  className="absolute right-1 cursor-pointer"
                />
              </div>
              <div className="bg-[rgba(0,0,0,0.5)] w-[98%] my-5 rounded-2xl flex items-center justify-evenly py-4 px-1">
                <ContactIcons
                  img={call}
                  modalIsOpen={openModal}
                  setIsOpen={setOpenModal}
                  setContactData={setContactData}
                  data={{
                    name: "phone",
                    url: profile?.phone,
                    cCode: profile?.cCode ? profile?.cCode : 91,
                  }}
                />
                <ContactIcons
                  img={mail}
                  modalIsOpen={openModal}
                  setIsOpen={setOpenModal}
                  setContactData={setContactData}
                  data={{ name: "mail", url: profile?.email }}
                />
                <ContactIcons
                  img={location}
                  modalIsOpen={openModal}
                  setIsOpen={setOpenModal}
                  setContactData={setContactData}
                  data={{ name: "location", url: profile?.location }}
                />
                <ContactIcons
                  img={url}
                  modalIsOpen={openModal}
                  setIsOpen={setOpenModal}
                  setContactData={setContactData}
                  data={{ name: "portfolio", url: profile?.portfolio }}
                />
              </div>
              <div className="w-[95%]">
                <div className="flex justify-between items-center">
                  <p className="font-semibold">Taps Insights</p>
                  <div className="flex items-center">
                    <BiStats
                      onClick={(e) => {
                        e.preventDefault();
                        sideBar.current.scrollIntoView({ behavior: "smooth" });
                      }}
                      size={30}
                      className="mr-2 cursor-pointer"
                    />
                  </div>
                </div>
                <div className="mt-7 h-fit">
                  <div className="flex items-center justify-between mb-1">
                    <p>Profile Taps :</p>
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        setMonthlyOrDaily({
                          ...monthlyOrDaily,
                          taps: !monthlyOrDaily.taps,
                        });
                      }}
                      className="text-sm border px-4 rounded-lg py-0.5 cursor-pointer"
                    >
                      {monthlyOrDaily?.taps ? "Monthly" : "Yearly"}
                    </p>
                  </div>
                  <Line data={dataWebsite} options={getChartOptions()} />
                </div>
                <div className="mt-7 h-fit">
                  <div className="flex items-center justify-between mb-1">
                    <p>Profile Saved :</p>
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        setMonthlyOrDaily({
                          ...monthlyOrDaily,
                          profile: !monthlyOrDaily.profile,
                        });
                      }}
                      className="text-sm border px-4 rounded-lg py-0.5 cursor-pointer"
                    >
                      {monthlyOrDaily?.profile ? "Monthly" : "Yearly"}
                    </p>
                  </div>
                  <Line data={dataConnections} options={getChartOptions()} />
                </div>
                <div className="mt-7 h-fit" ref={sideBar}>
                  <div className="flex items-center justify-between mb-1">
                    <p>Social Link Taps :</p>
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        setMonthlyOrDaily({
                          ...monthlyOrDaily,
                          links: !monthlyOrDaily.links,
                        });
                      }}
                      className="text-sm border px-4 rounded-lg py-0.5 cursor-pointer"
                    >
                      {monthlyOrDaily?.links ? "Monthly" : "Yearly"}
                    </p>
                  </div>
                  <Line data={dataSocialTap} options={getChartOptions()} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const ContactIcons = ({
  img,
  modalIsOpen,
  setIsOpen,
  setContactData,
  data,
}) => {
  return (
    <div
      onClick={(e) => {
        if (data) {
          e.preventDefault();
          setIsOpen(!modalIsOpen);
          setContactData(data);
        }
      }}
      className={`bg-[rgba(255,255,255,0.2)] p-3 w-[4vw] h-[4vw] backdrop-blur-md rounded-full flex justify-center items-center cursor-pointer`}
    >
      <img src={img} alt={img} />
    </div>
  );
};

export default ViewEditFull;
