import React, { useContext, useEffect, useState } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { CARD_URI, POP_URI } from "../utils";
import TapKaroContext from "../context/Context";

import vector1 from "../images/shopVectors/environmental-icon.png";
import vector2 from "../images/shopVectors/premium-icon.png";
import vector3 from "../images/shopVectors/themes-icon.png";
import ShopNow from "../Components/ShopNow";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewCard = () => {
  const { id } = useParams();
  const [card, setCard] = useState();
  let [Quantity, setQuantity] = useState(1);
  const { cards, modalIsOpen, setIsOpen } = useContext(TapKaroContext);
  const [clickedImg, setClickedImg] = useState("");
  const { cartData, setCartData } = useContext(TapKaroContext);
  const [data, setData] = useState({
    type: "Standard",
    email: "",
    name: "",
    company: "",
    phone: "",
    designation: "",
    logo: "",
  });

  useEffect(() => {
    axios
      .get(`${CARD_URI}/getOne/${id}`)
      .then((response) => {
        setCard(response.data);
      })
      .catch((err) => {});
  }, [id]);

  const reviews = [
    {
      name: "Rishab",
      img: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80",
      desc: "The best part like about this, Tap Karo card worked without downloading any application. ",
    },
    {
      name: "Ankur Pandey",
      img: "https://images.pexels.com/photos/9271168/pexels-photo-9271168.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "This is what I was looking for. This solved all my problems of sharing complete profile all at once.",
    },
    {
      name: "Vishwesh",
      img: "https://images.pexels.com/photos/12311514/pexels-photo-12311514.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "I do not save number by typing now. Just tap on phone and download cnotacts. That is so easy to connect now.",
    },
    {
      name: "Promila Bhardwaj",
      img: "https://images.pexels.com/photos/1844012/pexels-photo-1844012.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      desc: "My instagram Tap Karo card helped me to gain followers. That has become my things to carry along with now.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCartData(JSON.parse(localStorage.getItem("cart")));
    } else {
      setCartData([]);
    }
  }, [localStorage]);

  useEffect(() => {
    let cart = cartData?.find((e) => e?._id === id);
    if (cart) {
      setData({
        type: cart?.customization?.type,
        email: cart?.customization?.email,
        name: cart?.customization?.name,
        company: cart?.customization?.company,
        phone: cart?.customization?.phone,
        designation: cart?.customization?.designation,
      });
      setQuantity(cart?.quantity);
    }
  }, [cartData]);

  const [popup, setPopup] = useState([]);

  const getPopUps = () => {
    axios
      .get(`${POP_URI}/`)
      .then((res) => {
        setPopup(res.data[0].img);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getPopUps();
  }, [modalIsOpen]);

  useEffect(() => {
    if (popup.length > 1) {
      if (modalIsOpen !== "hard-false") {
        setTimeout(() => {
          setIsOpen("true");
        }, 30000);
      }
    }
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Nav />
      <ShopNow />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bg-navyBlue text-white mobile:pt-[12vh] md:pt-[14vh] mobile:px-[3vw] md:px-[5vw]">
        <p className="inline border mobile:mx-0 md:px-5 py-1 mobile:text-base md:text-lg rounded-md ml-3 px-2">
          {card?.category}
        </p>
        <div className="w-full bg-[rgba(0,0,0,0.25)] mt-5 mobile:p-[4.5vw] md:p-[2vw] rounded-lg">
          <div className="flex md:flex-row mobile:flex-col justify-between mobile:items-center md:items-start">
            <div className="mobile:w-full md:w-[60%] mobile:mr-0 md:mr-[5%]">
              <div className="flex justify-center items-center rounded-3xl">
                <img
                  src={clickedImg ? clickedImg : card?.mainImg}
                  alt={clickedImg ? clickedImg : card?.mainImg}
                  className="mobile:w-[100vw] md:w-[50vw] md:h-[22vw] mobile:h-[53vw] object-cover object-center rounded-lg"
                />
              </div>
              <div className="grid mobile:grid-cols-3 md:grid-cols-5 mobile:gap-3 md:gap-3 mobile:w-full md:w-[44%] md:-mt-20 mobile:mt-3 mobile:grid md:hidden">
                <SmallCard
                  card={card?.mainImg}
                  clickedImg={clickedImg}
                  setClickedImg={setClickedImg}
                />
                <SmallCard
                  card={card?.img1}
                  clickedImg={clickedImg}
                  setClickedImg={setClickedImg}
                />
                <SmallCard
                  card={card?.img2}
                  clickedImg={clickedImg}
                  setClickedImg={setClickedImg}
                />
                <SmallCard
                  card={card?.img3}
                  clickedImg={clickedImg}
                  setClickedImg={setClickedImg}
                />
                <SmallCard
                  card={card?.img4}
                  clickedImg={clickedImg}
                  setClickedImg={setClickedImg}
                />
              </div>
            </div>
            <div className="md:px-0 mobile:px-2 mobile:w-full md:w-[70%]">
              <h1 className="text-3xl font-bold mb-1 md:mt-0 mobile:mt-2">
                {card?.title}
              </h1>
              <p className="mobile:w-full md:mt-0 mobile:mt-1 md:w-[75%]">
                {/* {card?.desc} */}
                {card?.desc.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < card?.desc.split("\n").length - 1 && <br />}{" "}
                  </React.Fragment>
                ))}
              </p>
              <div className="md:mt-8 mobile:mt-2 mobile:flex md:hidden items-center">
                <div className="flex flex-col items-center">
                  <img
                    src={vector1}
                    alt={vector1}
                    className="md:w-[3vw] md:h-[3vw] mobile:w-[10vw] mobile:h-[10vw]"
                  />
                  <p className="mt-1 text-center">Eco-Friendly</p>
                </div>
                <div className="flex flex-col items-center mx-[13%]">
                  <img
                    src={vector3}
                    alt={vector3}
                    className="md:w-[3vw] md:h-[3vw] mobile:w-[10vw] mobile:h-[10vw]"
                  />
                  <p className="mt-1 text-center">Design Assistance</p>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={vector2}
                    alt={vector2}
                    className="md:w-[3vw] md:h-[4vw] mobile:w-[10vw] mobile:h-[13vw]"
                  />
                  <p className="mt-1 text-center">Build Connections</p>
                </div>
              </div>
              <div className="flex mt-2 items-center">
                <p className="mr-6 text-xl font-medium">
                  Rs.{" "}
                  <span className="mx-1 line-through text-red-400">
                    {data?.type === "Standard" ? card?.cutPrice : ""}
                  </span>{" "}
                  {data?.type === "Standard"
                    ? card?.price
                    : card?.customizedPrice}
                  /-{" "}
                </p>
              </div>
              <div className="md:mt-8 mobile:mt-2 mobile:hidden md:flex items-center">
                <div className="flex flex-col items-center">
                  <img
                    src={vector1}
                    alt={vector1}
                    className="md:w-[3vw] md:h-[3vw] mobile:w-[10vw] mobile:h-[10vw]"
                  />
                  <p className="mt-1 text-center">Eco-Friendly</p>
                </div>
                <div className="flex flex-col items-center mx-[13%]">
                  <img
                    src={vector3}
                    alt={vector3}
                    className="md:w-[3vw] md:h-[3vw] mobile:w-[10vw] mobile:h-[10vw]"
                  />
                  <p className="mt-1 text-center">Design Assistance</p>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={vector2}
                    alt={vector2}
                    className="md:w-[3vw] md:h-[4vw] mobile:w-[10vw] mobile:h-[13vw]"
                  />
                  <p className="mt-1 text-center">Build Connections</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row mobile:flex-col justify-between items-start mt-3">
            <div className="grid mobile:grid-cols-3 md:grid-cols-5 mobile:gap-3 md:gap-3 mobile:w-full md:w-[44%] mobile:mt-0 mobile:hidden md:grid">
              <SmallCard
                card={card?.mainImg}
                clickedImg={clickedImg}
                setClickedImg={setClickedImg}
              />
              <SmallCard
                card={card?.img1}
                clickedImg={clickedImg}
                setClickedImg={setClickedImg}
              />
              <SmallCard
                card={card?.img2}
                clickedImg={clickedImg}
                setClickedImg={setClickedImg}
              />
              <SmallCard
                card={card?.img3}
                clickedImg={clickedImg}
                setClickedImg={setClickedImg}
              />
              <SmallCard
                card={card?.img4}
                clickedImg={clickedImg}
                setClickedImg={setClickedImg}
              />
            </div>
            <div className="mobile:w-full md:w-[50%] text-white">
              <p className="text-lg mt-1">Quantity :</p>
              <div className="flex items-center bg-lightBlue w-fit px-3 rounded-md mt-1.5 text-gray-800 text-xl">
                <p
                  className="mr-16 font-bold text-2xl cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    if (Quantity <= 1) {
                      setQuantity(1);
                    } else {
                      setQuantity(Quantity - 1);
                    }
                  }}
                >
                  -
                </p>
                <p className="font-bold">{Quantity}</p>
                <p
                  className="ml-16 font-bold text-2xl cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setQuantity(Quantity + 1);
                  }}
                >
                  +
                </p>
              </div>
              <select
                name=""
                id=""
                value={data.type}
                onChange={(e) => {
                  setData({ ...data, type: e.target.value });
                }}
                className="bg-transparent border outline-none w-[32%] text-center rounded-lg mt-2.5 py-0.5"
              >
                <option value="Standard" className="text-navyBlue">
                  Standard
                </option>
                <option value="Customized" className="text-navyBlue">
                  Customized
                </option>
              </select>
              {data?.type === "Customized" ? (
                <div className="mobile:w-[88vw] md:w-[20vw] border border-gray-400 rounded-lg backdrop-blur-md mt-4">
                  <input
                    type="text"
                    className="input"
                    placeholder="Your Name"
                    value={data.name}
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                    }}
                  />
                  <input
                    type="text"
                    className="input"
                    placeholder="Email"
                    value={data.email}
                    onChange={(e) => {
                      setData({ ...data, email: e.target.value });
                    }}
                  />
                  <input
                    type="text"
                    className="input"
                    placeholder="Company Name"
                    value={data.company}
                    onChange={(e) => {
                      setData({ ...data, company: e.target.value });
                    }}
                  />
                  <input
                    type="number"
                    className="input"
                    placeholder="Phone Number"
                    value={data.phone}
                    onChange={(e) => {
                      setData({ ...data, phone: e.target.value });
                    }}
                  />
                  <input
                    type="text"
                    className="input"
                    placeholder="Designation"
                    value={data.designation}
                    onChange={(e) => {
                      setData({ ...data, designation: e.target.value });
                    }}
                  />
                  <div className="ml-4 mt-1 mb-2.5 flex items-center justify-between w-full">
                    <label htmlFor="logo">Logo: </label>
                    <input
                      type="file"
                      onChange={(e) => {
                        const formData = new FormData();
                        formData.append("file", e.target.files[0]);
                        formData.append("upload_preset", "upload_photo");
                        formData.append("cloud_name", "dpbsogbtr");

                        fetch(
                          "https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload",
                          {
                            method: "POST",
                            body: formData,
                          }
                        )
                          .then((res) => res.json())
                          .then((res) => {
                            setData({ ...data, logo: res.url });
                          })
                          .catch((err) => {});
                      }}
                      className="block text-grey-500 file:mr-2 file:py-1 file:px-3 file:rounded-full file:border-0 file:text-xs file:font-medium file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50 hover:file:text-amber-700 w-[80%]"
                    />
                  </div>
                </div>
              ) : null}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (cartData?.find((e) => e?._id === id)?._id?.length > 1) {
                    let cartDatas = cartData?.filter((e) => e?._id !== id);
                    if (data?.type === "Standard") {
                      localStorage.setItem(
                        "cart",
                        JSON.stringify([
                          ...cartDatas,
                          {
                            ...card,
                            quantity: Quantity,
                            type: data?.type,
                            customization: data,
                          },
                        ])
                      );
                      history("/cart");
                      setCartData(JSON.parse(localStorage.getItem("cart")));
                    } else {
                      if (
                        data?.name &&
                        data?.email &&
                        data?.designation &&
                        data?.company
                      ) {
                        localStorage.setItem(
                          "cart",
                          JSON.stringify([
                            ...cartDatas,
                            {
                              ...card,
                              quantity: Quantity,
                              type: data?.type,
                              customization: data,
                            },
                          ])
                        );
                        setCartData(JSON.parse(localStorage.getItem("cart")));
                        history("/cart");
                      } else {
                        toast.warning("Please fill all the details");
                      }
                    }
                  } else {
                    if (data?.type === "Standard") {
                      localStorage.setItem(
                        "cart",
                        JSON.stringify([
                          ...cartData,
                          {
                            ...card,
                            quantity: Quantity,
                            type: data?.type,
                            customization: data,
                          },
                        ])
                      );
                      setCartData(JSON.parse(localStorage.getItem("cart")));
                    } else {
                      if (
                        data?.name &&
                        data?.email &&
                        data?.designation &&
                        data?.company
                      ) {
                        localStorage.setItem(
                          "cart",
                          JSON.stringify([
                            ...cartData,
                            {
                              ...card,
                              quantity: Quantity,
                              type: data?.type,
                              customization: data,
                            },
                          ])
                        );
                        setCartData(JSON.parse(localStorage.getItem("cart")));
                      } else {
                        toast.warning("Please fill all the details");
                      }
                    }
                  }
                }}
                className="mt-4 bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] w-full rounded-lg py-0.5 text-lg font-semibold"
              >
                {cartData?.find((e) => e?._id === id)?._id?.length > 1
                  ? "Proceed to Pay"
                  : "Add To Cart"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="blocksTrigger"
        className="grid mobile:grid-cols-1 md:grid-cols-3 mobile:gap-4 md:gap-14 mobile:px-3 md:px-[5vw] bg-navyBlue mobile:py-4 md:py-10"
      >
        {cards?.slice(0, 3).map((e, i) => {
          return <Block key={i} data={e} />;
        })}
      </div>
      <div className="z-10 pt-[2vw] mobile:h-fit mobile:py-7 md:py-0 md:h-[80vh] bg-[#091628] text-white">
        {window.innerWidth < 550 ? (
          <div
            id="triggerSqure"
            className="flex flex-col justify-evenly mobile:mt-12 md:my-24 z-50 md:pb-0 mobile:pb-11"
          >
            <h1 className="text-center text-4xl mobile:pb-3 md:pb-0 font-semibold">
              Our Proud Tappers
            </h1>
            <div
              id="testSqures"
              className="flex md:items-center w-[100vw] mobile:h-[45vh] md:h-[40vh] mobile:mt-2 md:mt-5 md:mx-[10vw] md:justify-between mobile:mx-[5vw]"
            >
              <Carousel
                showArrows={true}
                showThumbs={false}
                autoPlay={true}
                infiniteLoop={true}
                interval={5000}
                swipeable={true}
                emulateTouch={true}
              >
                {reviews.map((e, i) => {
                  return <TestMobile key={i} data={e} />;
                })}
              </Carousel>
            </div>
          </div>
        ) : (
          <div
            id="triggerSqure"
            className="h-[75vh] flex flex-col justify-evenly mobile:pt-12"
          >
            <h1 className="text-center text-4xl font-semibold">
              Our Proud Tappers
            </h1>
            <div
              id="testSqures"
              className="md:flex mobile:grid mobile:grid-cols-2 md:gap-10 mobile:gap-4 justify-between mobile:px-4 md:px-[8vw] mobile:mx-0 md:mx-[2vw] h-[45vh] z-50"
            >
              {reviews.map((e, i) => {
                return <TestDesktop data={e} key={i} />;
              })}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

const SmallCard = ({ card, clickedImg, setClickedImg }) => {
  return card ? (
    <div>
      <div
        className={`${
          clickedImg === card ? "border-gray-300" : ""
        } border border-transparent bg-[#08101be5] flex justify-center items-center mobile:rounded-lg md:rounded-xl cursor-pointer`}
        onClick={(e) => {
          e.preventDefault();
          setClickedImg(card);
        }}
      >
        <img
          src={card}
          alt={card}
          className="mobile:w-[40vw] md:w-[20vw] mobile:h-[16vw] md:h-[4vw] object-cover object-center rounded-xl"
        />
      </div>
    </div>
  ) : null;
};

const Block = ({ data }) => {
  const history = useNavigate();
  const { cartData, setCartData } = useContext(TapKaroContext);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCartData(JSON.parse(localStorage.getItem("cart")));
    } else {
      setCartData([]);
    }
  }, [localStorage]);

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        history(`/shop/${data?._id}`);
      }}
      className="p-4 rounded-lg shadow-lg shadow-navyBlue mx-[3vw] border border-gray-600 cursor-pointer transition-all hover:scale-105"
    >
      <div className="bg-[#091628] rounded-lg flex justify-center items-center">
        <img src={data?.mainImg} alt={data?.mainImg} />
      </div>
      <h1 className="text-white text-center mt-1 mobile:text-2xl md:text-2xl font-bold">
        {data?.title}
      </h1>
      <div className="flex justify-between items-center px-1 pt-2">
        <p className="text-white">
          Rs.{" "}
          <span className="line-through text-red-400">{data?.cutPrice}</span>{" "}
          {data?.price} /-
        </p>
        <button
          onClick={(e) => {
            e.stopPropagation();
            localStorage.setItem(
              "cart",
              JSON.stringify([
                ...cartData,
                {
                  ...data,
                  quantity: 1,
                  type: "Standard",
                  customization: { type: "Standard" },
                },
              ])
            );
            setCartData(JSON.parse(localStorage.getItem("cart")));
          }}
          className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] mobile:px-4 md:text-base mobile:text-lg md:px-3 rounded-lg py-1 text-white"
          disabled={
            cartData?.find((e) => e?._id === data?._id)?._id?.length > 1
          }
        >
          {cartData?.find((e) => e?._id === data?._id)?._id?.length > 1
            ? "Added"
            : "Add To Cart"}
        </button>
      </div>
    </div>
  );
};

const TestMobile = ({ data }) => {
  return (
    <div className="offScroll bg-gradient-to-tr from-[#fb00ff70] via-[#00b3b6] to-[#ffffff96] flex flex-col items-center justify-center md:w-[18vw] mobile:w-[90vw] border mobile:h-[45vh] mobile:px-1 md:px-0 md:h-full rounded-3xl mobile:opacity-100 cursor-pointer md:opacity-60 hover:opacity-100">
      <div className="md:w-[7vw] md:h-[7vw] mobile:w-[35vw] mobile:h-[35vw]">
        <img
          src={data?.img}
          alt="Default"
          className="rounded-full w-full h-full object-cover object-center"
        />
      </div>
      <h1 className="text-2xl mt-1 font-semibold text-center">{data?.name}</h1>
      <p className="text-center text-lg px-3">{data?.desc}</p>
    </div>
  );
};

const TestDesktop = ({ data }) => {
  return (
    <div className="transitionAddKro backdrop-blur-2xl bg-gradient-to-tr from-[#fb00ff70] via-[#00b3b6] to-[#ffffff96] md:w-[22%] h-[45vh] mobile:h-full rounded-3xl flex flex-col items-center mobile:opacity-100 md:opacity-60 hover:opacity-100 cursor-pointer md:py-3 mobile:py-3 z-30 overflow-y-scroll">
      <img
        src={data?.img}
        alt="Default"
        className="rounded-full mobile:w-[20vw] md:w-[7vw] mobile:h-[20vw] md:h-[7vw] object-cover object-center"
      />
      <h1 className="text-xl mt-1 font-semibold text-center">{data?.name}</h1>
      <p className="text-center px-3">{data?.desc}</p>
    </div>
  );
};

export default ViewCard;
