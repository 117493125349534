import { useEffect, useState } from "react";
import {
  ANNOUNCE_URI,
  BUSSINESS_URI,
  CARD_URI,
  OFFER_URI,
  ORDER_URI,
  PROFILE_URI,
} from "../utils";
import axios from "axios";
import TapKaroContext from "./Context";
import { useLocation } from "react-router";

const TapKaroState = (props) => {
  const [profileTaps, setProfileTaps] = useState([]);
  const [viewEmployee, setViewEmployee] = useState({});
  const [clickedQr, setClickedQr] = useState();
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [cards, setCards] = useState([]);
  const [profile, setProfile] = useState();
  const [cart, setCart] = useState([]);
  const [cartItem, setCartItem] = useState({
    userId: "",
    fName: "",
    lName: "",
    email: "",
    cCode: 91,
    phone: "",
    designation: "",
    address: "",
    street: "",
    city: "",
    country: "India",
    state: "",
    pinCode: "",
    cardId: "",
    offer: "",
    price: "",
  });
  const [bussinessRegister, setBussinessRegister] = useState({
    otp: "",
    name: "",
    company: "",
    email: "",
    phone: "",
    password: "",
  });
  const [cardsConfig, setCardsConfig] = useState({
    search: "",
    size: 12,
    max: 0,
    page: 1,
  });
  const [cardPage, setCardPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const [bussinesses, setBussinesses] = useState([]);
  const [bussinessConfig, setBussinessConfig] = useState({
    page: 1,
    search: "",
    size: 10,
    max: 0,
  });
  const [offers, setOffers] = useState([]);
  const [bussiness, setBussiness] = useState();
  const [announed, setAnnouned] = useState([]);
  const [connectionTaps, setConnectionTaps] = useState([]);
  const [socialTaps, setSocialTaps] = useState([]);
  const [adminLoggedIn, setAdminLoggedIn] = useState(false);
  const location = useLocation();
  const [announceSelect, setAnnounceSelect] = useState([]);
  const [checkUser, setCheckUser] = useState(false);
  const [bussinessSelectedEmp, setBussinessSelectedEmp] = useState([]);
  const [popUpDisplay, setPopUpDisplay] = useState("false");
  const [checkLoader, setCheckLoader] = useState(false)
  const [dailyTapsReport, setDailyTapsReport] = useState({
    taps: [],
    profile: [],
    link: [],
  });

  const getAnnouncedTokens = () => {
    axios
      .get(`${ANNOUNCE_URI}/`)
      .then((res) => {
        setAnnouned(res.data);
      })
      .catch((err) => {});
  };

  const getProfileTaps = () => {
    if (localStorage.getItem("token")) {
      axios
        .post(`${PROFILE_URI}/taps`, { token: localStorage.getItem("token") })
        .then((res) => {
          setProfileTaps(res.data.taps);
          setDailyTapsReport({
            ...dailyTapsReport,
            taps: [...res?.data?.days],
          });
        })
        .catch((err) => {});
    }
  };

  const getConnectionTaps = () => {
    if (localStorage.getItem("token")) {
      axios
        .post(`${PROFILE_URI}/taps/connections`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          setConnectionTaps(res.data.taps);
          setDailyTapsReport({
            ...dailyTapsReport,
            profile: [...res.data.days],
          });
        })
        .catch((err) => {});
    }
  };

  const getSocialIconsReport = () => {
    if (localStorage.getItem("token")) {
      axios
        .post(`${PROFILE_URI}/social-taps`, {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          setSocialTaps(res.data.taps);
          setDailyTapsReport({ ...dailyTapsReport, link: [...res.data.days] });
        })
        .catch((err) => {});
    }
  };

  const getOneBussiness = () => {
    axios
      .post(`${BUSSINESS_URI}/getBussiness`, {
        token: localStorage.getItem("bussiness_token"),
      })
      .then((response) => {
        setBussiness(response.data);
      })
      .catch((err) => {});
  };

  const getProfile = () => {
    if (localStorage.getItem("token")) {
      axios
        .post(`${PROFILE_URI}/user`, { token: localStorage.getItem("token") })
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => {});
    }
  };

  const getBussinesses = () => {
    axios
      .get(
        `${BUSSINESS_URI}/?page=${bussinessConfig.page}&size=${bussinessConfig.size}&search=${bussinessConfig.search}&filter=${filter}`
      )
      .then((response) => {
        setBussinesses(response.data.bussiness);
        setBussinessConfig({ ...bussinessConfig, max: response.data.number });
      })
      .catch((err) => {});
  };

  const getOffers = () => {
    axios
      .get(`${OFFER_URI}/`)
      .then((res) => {
        setOffers(res.data);
      })
      .catch((err) => {});
  };

  const checkUserStatus = () => {
    let token = localStorage.getItem("token");
    setCheckLoader(true);
    axios
      .post(`${ORDER_URI}/check-status`, { token })
      .then((res) => {
        setCheckLoader(false);
        setCheckUser(res.data);
      })
      .catch((err) => {
        setCheckLoader(false);
      });
  };

  useEffect(() => {
    checkUserStatus();
  }, [location]);

  useEffect(() => {
    getOffers();
    getConnectionTaps();
  }, []);

  useEffect(() => {
    getBussinesses();
  }, []);

  const getCards = () => {
    axios
      .get(
        `${CARD_URI}/?page=${cardPage}&size=${cardsConfig.size}&search=${cardsConfig.search}&filter=${filter}`
      )
      .then((response) => {
        setCards(response.data.cards);
        setCardsConfig({ ...cardsConfig, max: response.data.number });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCards();
  }, []);

  const getOrders = () => {
    axios
      .get(
        `${ORDER_URI}/?page=${page}&size=${size}&search=${search}&filter=${filter}`
      )
      .then((response) => {
        setTotalOrders(response.data.number);
        setOrders(response.data.orders);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));
    } else {
      setCart([]);
    }
  }, [localStorage]);

  useEffect(() => {
    getOrders();
  }, [page, search, size, filter]);

  useEffect(() => {
    if (localStorage.getItem("admin_token")) {
      setAdminLoggedIn(true);
    }
  }, [location]);

  return (
    <TapKaroContext.Provider
      value={{
        getOrders,
        clickedQr,
        setClickedQr,
        orders,
        page,
        setPage,
        search,
        setSearch,
        size,
        setSize,
        filter,
        setFilter,
        totalOrders,
        getCards,
        cardsConfig,
        setCardsConfig,
        cards,
        getBussinesses,
        bussinessConfig,
        setBussinessConfig,
        bussinesses,
        getOffers,
        offers,
        profile,
        getProfile,
        bussiness,
        getOneBussiness,
        viewEmployee,
        setViewEmployee,
        getProfileTaps,
        profileTaps,
        bussinessRegister,
        setBussinessRegister,
        cartData: cart,
        setCartData: setCart,
        data: cartItem,
        setData: setCartItem,
        getAnnouncedTokens,
        announed,
        connectionTaps,
        getConnectionTaps,
        getSocialIconsReport,
        socialTaps,
        adminLoggedIn,
        announceSelect,
        setAnnounceSelect,
        categories: [
          "Absolute",
          "Premium",
          "Exclusive",
          "Metallic",
          "Scan Card",
          "Social Media",
          "Professionals",
          "Accessories",
        ],
        check: checkUser,
        cardPage,
        setCardPage,
        setBussinessSelectedEmp,
        bussinessSelectedEmp,
        modalIsOpen: popUpDisplay,
        setIsOpen: setPopUpDisplay,
        dailyTapsReport,
        checkLoader
      }}
    >
      {props.children}
    </TapKaroContext.Provider>
  );
};

export default TapKaroState;
