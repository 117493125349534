import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Topbar from "../Components/Topbar";
import axios from "axios";
import { ANNOUNCE_URI, PROFILE_URI } from "../../utils/index";
import { AiOutlineClose, AiOutlineDownload } from "react-icons/ai";
import { BsCheck2All } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router";
import TapKaroContext from "../../context/Context";
import QRCodeStyling from "qr-code-styling";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import obj from "../../Components/QR";
import { CSVLink } from "react-csv";

import Pagination from "./Pagination";
import { InfinitySpin } from "react-loader-spinner";

const AnnounceToken = () => {
  const history = useNavigate();
  const [cards, setCards] = useState();
  const { announed, getAnnouncedTokens, setAnnounceSelect, announceSelect } =
    useContext(TapKaroContext);
  const [tokens, setTokens] = useState([]);
  const [loader, setLoader] = useState(false);

  let PageSize = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return [].concat(...tokens)?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, tokens]);

  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  useEffect(() => {
    getAnnouncedTokens();
  }, []);

  useEffect(() => {
    setTokens([
      ...announed?.map((e) => {
        return e?.tokens;
      }),
    ]);
  }, [announed]);

  return (
    <div className="flex h-[100vh]">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Sidebar />
      <div className="w-[80vw] pt-6 px-6">
        <Topbar title={"Announce Tokens"} />
        {loader ? (
          <div className="w-full flex justify-center items-center h-[80vh]">
            <InfinitySpin width="300" color="#000" />
          </div>
        ) : (
          <div>
            <div className={`flex justify-between items-center w-full`}>
              <div className="flex items-center flex-row-reverse pt-1">
                {announceSelect?.length > 0 ? (
                  <>
                    <p className="pl-2">{announceSelect?.length} Selected</p>
                    <AiOutlineClose
                      size={20}
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setAnnounceSelect([]);
                      }}
                    />
                  </>
                ) : (
                  <div>
                    <BsCheck2All
                      size={20}
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setAnnounceSelect([
                          ...currentTableData.map((e) => e.token),
                        ]);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="flex items-center">
                <p>{[].concat(...tokens)?.length} Tokens</p>
                <input
                  type="number"
                  value={cards}
                  onChange={(e) => {
                    setCards(e.target.value);
                  }}
                  placeholder="Enter Value"
                  className="border outline-none py-1 px-2 border-black rounded-lg ml-2 w-[8vw]"
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (parseInt(cards) > 0) {
                      setLoader(true);
                      axios
                        .post(`${ANNOUNCE_URI}/`, { cards })
                        .then((res) => {
                          toast.success("Tokens added successfully");
                          setCards("");
                          getAnnouncedTokens();
                          setTokens([
                            ...announed?.map((e) => {
                              return e?.tokens;
                            }),
                          ]);
                          setLoader(false);
                        })
                        .catch((err) => {
                          toast.error("Internal server error");
                          setLoader(false);
                        });
                    } else {
                      toast.warning("Card Number should be greater then 0");
                    }
                  }}
                  className="outline-none py-1 px-2 bg-black text-white rounded-lg ml-2 w-fit"
                >
                  Generate Token
                </button>
                <CSVLink
                  data={
                    announceSelect?.length > 0
                      ? announceSelect.map((e) => {
                          return { token: e };
                        })
                      : [].concat(...tokens)
                  }
                  filename="Export"
                  disabled={tokens?.length === 0}
                  className="outline-none py-1 px-2 bg-black text-white rounded-lg ml-2 w-fit"
                >
                  {announceSelect?.length > 0
                    ? "Export Selected"
                    : "Export All"}
                </CSVLink>
                {announceSelect?.length > 0 ? (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        axios
                          .post(`${ANNOUNCE_URI}/update`, {
                            ids: announceSelect,
                            status: "Active",
                          })
                          .then((res) => {
                            if (res.data.modifiedCount) {
                              setAnnounceSelect([]);
                              getAnnouncedTokens();
                            }
                          })
                          .catch((err) => {});
                      }}
                      className="outline-none py-1 px-2 bg-black text-white rounded-lg ml-2 w-fit"
                    >
                      Make Active
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        axios
                          .post(`${ANNOUNCE_URI}/update`, {
                            ids: announceSelect,
                            status: "Inactive",
                          })
                          .then((res) => {
                            if (res.data.modifiedCount) {
                              setAnnounceSelect([]);
                              getAnnouncedTokens();
                            }
                          })
                          .catch((err) => {});
                      }}
                      className="outline-none py-1 px-2 bg-black text-white rounded-lg ml-2 w-fit"
                    >
                      Make Inactive
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        axios
                          .post(`${ANNOUNCE_URI}/reset`, {
                            ids: announceSelect,
                          })
                          .then((res) => {
                            toast.success("Token reset successfully");
                            setAnnounceSelect([]);
                            getAnnouncedTokens();
                          })
                          .catch((err) => {});
                      }}
                      className="outline-none py-1 px-2 bg-black text-white rounded-lg ml-2 w-fit"
                    >
                      Reset Tokens
                    </button>
                  </>
                ) : null}
              </div>
            </div>
            <div className="mt-3 h-[75vh] overflow-y-auto">
              {tokens
                ? currentTableData?.map((e, i) => {
                    return <Block data={e.token} status={e?.status} key={i} />;
                  })
                : null}
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={[].concat(...tokens)?.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Block = ({ data, status }) => {
  const qr = useRef(null);
  const [user, setUser] = useState();
  const [showQR, setShowQR] = useState(false);
  const { setAnnounceSelect, announceSelect } = useContext(TapKaroContext);

  const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,
    ...obj,
    data: `${window.location.origin}/${data}`,
  });

  useEffect(() => {
    qrCode.update({
      data: `${window.location.origin}/${data}`,
    });
    if (!qr?.current?.innerHTML.includes("canvas")) {
      qrCode.append(qr.current);
    }
  }, [data]);

  useEffect(() => {
    axios
      .get(`${PROFILE_URI}/${data}`)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {});
  }, [data]);

  return (
    <div className="flex flex-col items-center bg-adminlightBlue mb-2 rounded-xl px-4 py-2">
      <div
        onClick={(e) => {
          e.preventDefault();
          setShowQR(!showQR);
        }}
        className="w-[99%] grid m-auto cursor-pointer font-medium"
        style={{ gridTemplateColumns: "3% 28% 25% 20% 20% 10%" }}
      >
        <input
          type="checkbox"
          className="border w-fit"
          checked={announceSelect.includes(data)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            if (announceSelect?.includes(data)) {
              let arr = announceSelect.filter((e) => e != data);
              setAnnounceSelect([...arr]);
            } else {
              setAnnounceSelect([...announceSelect, data]);
            }
          }}
        />
        {user?.name ? (
          <p className="text-center">{user?.name}</p>
        ) : (
          <p className="text-center">
            {window.location.origin}/{data}
          </p>
        )}
        <p className="text-center">{user?.email}</p>
        <p className="text-center">{user?.phone}</p>
        <p className="text-center">{status}</p>
        <AiOutlineDownload
          size={25}
          onClick={(e) => {
            e.preventDefault();
            qrCode.download({
              extension: "png",
            });
          }}
        />
      </div>
      <div
        className={`${
          showQR ? "block" : "hidden"
        } mx-auto flex flex-col items-center`}
      >
        <div ref={qr} className={`mt-1 rounded-xl`}></div>
        <p className="text-sm mt-1">
          {window.location.origin}/{data}
        </p>
      </div>
    </div>
  );
};

export default AnnounceToken;
