import React, { useEffect, useRef, useState } from "react";
import facebook from "../../images/icons/facebook.png";
import linkedin from "../../images/icons/linkedin.png";
import instagram from "../../images/icons/instagram.png";
import youtube from "../../images/icons/youtube.png";
import whatsapp from "../../images/icons/whatsapp.png";
import paytm from "../../images/icons/paytm.png";
import gpay from "../../images/icons/google-pay.png";
import twitter from "../../images/icons/twitter.png";
import phonepe from "../../images/icons/phonepe.png";
import { useNavigate, useParams } from "react-router";
import QRCodeStyling from "qr-code-styling";
import obj from "../../Components/QR";
import {
  AiOutlineCopy,
  AiOutlineDownload,
  AiOutlineUserAdd,
} from "react-icons/ai";
import location from "../../images/contact/location.png";
import url from "../../images/contact/url.png";
import mail from "../../images/contact/mail.png";
import call from "../../images/contact/call.png";
import AddConnection from "../../Mobile/Components/AddConnection";
import { BASE_URI, PROFILE_URI } from "../../utils";
import axios from "axios";
import Axios from "axios";
import fileDownload from "js-file-download";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import calling from "../../images/icons/call.png";
import reviews from "../../images/icons/google-reviews.png";
import indiamart from "../../images/icons/indiamart.png";
import justdial from "../../images/icons/justdial.png";
import meesho from "../../images/icons/meesho.png";
import snapchat from "../../images/icons/snapchat.png";
import telegram from "../../images/icons/telegram.png";
import threads from "../../images/icons/threads.png";
import Helmet from "react-helmet";
import ShowLocation from "../../Mobile/Components/ShowLocation";

const ViewProfile = () => {
  const qr = useRef(null);
  const { id } = useParams();
  const history = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState();
  const [count, setcount] = useState(0);

  const fetchData = async () => {
    try {
      const resp = await axios.get("https://api64.ipify.org?format=json");
      let ip = resp.data.ip;
      if (ip !== null && ip !== undefined && ip.length > 1) {
        axios
          .post(`${BASE_URI}unauthorized-user/addTap/${id}`, {
            location: ip,
          })
          .then((res) => {
            setcount(1);
          })
          .catch((err) => {});
      } else {
        fetchData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    axios
      .get(`${PROFILE_URI}/${id}`)
      .then((res) => {
        setUser(res.data);
        if (!res.data?.email) {
          localStorage.setItem("id", id);
          history("/register");
        }
      })
      .catch((err) => {
        history("/");
      });
  }, [id]);

  const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,
    ...obj,
    data: `${window.location.origin}/${id}`,
  });

  useEffect(() => {
    qrCode.update({
      data: `${window.location.origin}/${id}`,
    });
    if (!qr?.current?.innerHTML?.includes("canvas")) {
      qrCode.append(qr.current);
    }
  }, [id]);

  const genrateVcfCard = () => {
    axios
      .post(`${BASE_URI}unauthorized-user/addProfileSave/${id}`)
      .then((res) => {})
      .catch((err) => {});

    // Details
    let uid = id;
    let firstName = user?.name?.includes(" ")
      ? user?.name.split(" ")[0]
      : user?.name;
    let lastName = user?.name?.includes(" ") ? user?.name.split(" ")[1] : "";
    let organization = user?.company;
    let role = user?.designation;
    let email = user?.email;

    let phone = user?.icons?.find((e) =>
      e?.name?.toLowerCase()?.includes("office")
    );

    let landline = user?.icons?.find((e) =>
      e?.name?.toLowerCase()?.includes("landline")
    );

    let cellPhone =
      "+" +
      (user?.cCode?.toString() ? user?.cCode?.toString() : "91") +
      user?.phone?.toString();

    let homePhone =
      "+" +
      (landline?.cCode?.toString() ? landline?.cCode?.toString() : "91") +
      +landline?.url;

    let workPhone =
      "+" +
      (user?.cCode?.toString() ? user?.cCode?.toString() : "91") +
      phone?.url;

    let url = `https://tapkaro.com/${id}`;
    let homeAddress = user?.location;

    let facebookIcon = user?.icons?.find((e) =>
      e?.name?.toLowerCase()?.includes("facebook")
    );
    let facebook = facebookIcon?.url;

    let linkedinIcon = user?.icons?.find((e) =>
      e?.name?.toLowerCase()?.includes("link")
    );
    let linkedin = linkedinIcon?.url;

    const vCardData = `BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${firstName}
N;CHARSET=UTF-8:${lastName};${firstName};;;
UID;CHARSET=UTF-8:${uid}
EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${email}
${
  !cellPhone?.includes(undefined) && !cellPhone.includes(NaN)
    ? `TEL;TYPE=CELL:${cellPhone}`
    : ""
}
${
  !homePhone?.includes(undefined) &&
  !cellPhone.includes(NaN) &&
  landline?.visibility
    ? `TEL;TYPE=HOME,VOICE:${homePhone}`
    : ""
}
${
  !workPhone?.includes(undefined) &&
  !cellPhone.includes(NaN) &&
  phone?.visibility
    ? `TEL;TYPE=WORK,VOICE:${workPhone}`
    : ""
}
${homeAddress ? `ADR;CHARSET=UTF-8;TYPE=HOME:${homeAddress}` : ""}
TITLE;CHARSET=UTF-8:${role}
ORG;CHARSET=UTF-8:${organization}
${user?.portfolio ? `URL;CHARSET=UTF-8:${user?.portfolio}` : url}
${
  facebook && facebookIcon.visibility
    ? `X-SOCIALPROFILE;TYPE=facebook:${facebook}`
    : ""
}
${
  linkedin && linkedinIcon.visibility
    ? `X-SOCIALPROFILE;TYPE=linkedin:${linkedin}`
    : ""
}
REV:${new Date()?.toISOString()}
END:VCARD`;

    try {
      const url =
        "data:text/x-vcard;charset=utf-8," + encodeURIComponent(vCardData);
      // window.location.href = url;
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `${firstName}.vcf`);
      link.click();
      toast.success("Vcf card downloaded successfully");
    } catch (error) {
      console.error("Error generating or opening vCard:", error);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Helmet>
        <title>Tap Karo Digital Business Card</title>
      </Helmet>
      <AddConnection modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <div className="bg-fbg w-[100vw] h-[100vh] bg-no-repeat bg-cover">
        <div className="backdrop-blur-md w-full h-full flex justify-between">
          <div className="w-[75vw]">
            <div
              style={{
                backgroundImage: `url(${user?.coverPicture})`,
              }}
              className="h-[35%] bg-userBackground bg-cover bg-no-repeat bg-center"
            ></div>
            <div className="h-[60%] px-3 text-white">
              <div className="flex items-start justify-between my-2.5 -mt-[35px]">
                <div className="w-[60%] flex items-end">
                  <img
                    src={user?.profilePicture}
                    alt="user"
                    className="w-[8.5vw] h-[8.5vw] object-cover object-center rounded-lg"
                  />
                  <div className="ml-3">
                    <h1 className="text-2xl font-semibold">{user?.name}</h1>
                    <h1 className="text-lg">{user?.designation}</h1>
                    <h1 className="text-lg">{user?.company}</h1>
                  </div>
                </div>
                <div className="mt-12 flex items-center">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      genrateVcfCard();
                    }}
                    className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] px-5 py-0.5 mr-2.5 rounded-lg flex items-center"
                  >
                    Save Contact{" "}
                    <AiOutlineDownload className="ml-2" size={16} />
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpen(!modalIsOpen);
                    }}
                    className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] px-5 py-0.5 mr-2.5 rounded-lg flex items-center"
                  >
                    Send Contact <AiOutlineUserAdd className="ml-2" size={16} />
                  </button>
                </div>
              </div>
              <div className="mt-7 px-2">
                <div>
                  <div className="flex items-center flex-wrap my-2">
                    {user?.icons
                      ?.filter((e) => {
                        return (
                          e?.type?.toLowerCase()?.includes("social") &&
                          e?.visibility === true
                        );
                      })
                      .map((e, i) => {
                        return (
                          <Icons
                            key={i}
                            img={
                              e?.name?.toLowerCase()?.includes("face")
                                ? facebook
                                : e?.name?.toLowerCase()?.includes("insta")
                                ? instagram
                                : e?.name?.toLowerCase()?.includes("what")
                                ? whatsapp
                                : e?.name?.toLowerCase()?.includes("you")
                                ? youtube
                                : e?.name?.toLowerCase()?.includes("twitter")
                                ? twitter
                                : e?.name?.toLowerCase()?.includes("linkedin")
                                ? linkedin
                                : e?.name?.toLowerCase()?.includes("call")
                                ? calling
                                : e?.name?.toLowerCase()?.includes("reviews")
                                ? reviews
                                : e?.name?.toLowerCase()?.includes("justdial")
                                ? justdial
                                : e?.name?.toLowerCase()?.includes("meesho")
                                ? meesho
                                : e?.name?.toLowerCase()?.includes("snapchat")
                                ? snapchat
                                : e?.name?.toLowerCase()?.includes("telegram")
                                ? telegram
                                : e?.name?.toLowerCase()?.includes("threads")
                                ? threads
                                : indiamart
                            }
                            data={e}
                          />
                        );
                      })}
                  </div>
                </div>
                <div className="mt-8">
                  <div className="flex items-center flex-wrap my-2">
                    {user?.icons
                      ?.filter((e) => {
                        return (
                          e?.type?.toLowerCase()?.includes("upi") &&
                          e?.visibility === true
                        );
                      })
                      .map((e, i) => {
                        return (
                          <Icons
                            key={i}
                            img={
                              e?.name?.toLowerCase()?.includes("paytm")
                                ? paytm
                                : e?.name?.toLowerCase()?.includes("phonepe")
                                ? phonepe
                                : gpay
                            }
                            data={e}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-rbg h-full w-[25vw] flex flex-col items-center justify-center text-white px-4">
            <div ref={qr} className="bg-white p-2 rounded-lg"></div>
            <div className="mt-4 flex items-center relative w-[90%]">
              <input
                type="text"
                value={`${window.location.origin}/${id}`}
                className="block bg-transparent border border-white py-1.5 pl-2 w-full rounded-lg pr-9 outline-none"
              />
              <AiOutlineCopy
                size={28}
                className="absolute right-1 cursor-pointer"
                onClick={(e) => {
                  copy(`${window.location.origin}/${id}`);
                  toast.success("Copied successfully");
                }}
              />
            </div>
            <div className="bg-[rgba(0,0,0,0.5)] w-[98%] my-5 rounded-2xl flex items-center justify-evenly py-4 px-1">
              <ContactIcons
                name={"call"}
                img={call}
                url={user?.phone}
                cCode={user?.cCode}
              />
              <ContactIcons name={"mail"} img={mail} url={user?.email} />
              <ContactIcons
                name={"location"}
                img={location}
                url={user?.location}
              />
              <ContactIcons name={"url"} img={url} url={user?.portfolio} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ContactIcons = ({ img, url, name, cCode }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <ShowLocation
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        data={url}
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          if (name === "url") {
            window.open(url);
          } else if (name === "mail") {
            window.open(`mailto:${url}`);
          } else if (name === "call") {
            window.open(`tel:${"+" + cCode + url}`);
          } else {
            setIsOpen(true);
          }
        }}
        className={`bg-[rgba(255,255,255,0.2)] p-3 w-[4vw] h-[4vw] backdrop-blur-md rounded-full flex justify-center items-center cursor-pointer`}
      >
        <img src={img} alt={img} />
      </div>
    </>
  );
};

const Icons = ({ img, padding, data }) => {
  const { id } = useParams();
  return (
    <div
      className={`${
        padding ? "p-3 bg-[rgba(255,255,255,0.2)]" : "p-0"
      } backdrop-blur-md rounded-lg w-[6.5%] mr-4 cursor-pointer`}
      onClick={(e) => {
        e.preventDefault();
        axios
          .get(`${BASE_URI}unauthorized-user/addSocialTap/${id}`, {
            token: localStorage.getItem("token"),
            name: data?.name,
          })
          .then((res) => {})
          .catch((err) => {});
        if (img?.includes("whatsapp")) {
          window.open(`https://api.whatsapp.com/send?phone=${data?.url}`);
        } else if (img?.includes("paytm")) {
          window.open("https://paytmapp.app.link/");
        } else if (img?.includes("phonepe")) {
          window.open(
            "https://play.google.com/store/apps/details?id=com.phonepe.app&hl=en_IN&gl=US"
          );
        } else if (img?.includes("google-pay")) {
          window.open(
            "https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user&hl=en&gl=US"
          );
        } else if (img?.includes("call")) {
          window.open(`tel:${data?.url}`);
        } else {
          window.open(data?.url);
        }
      }}
    >
      <img src={img} alt={img} />
    </div>
  );
};

export default ViewProfile;
