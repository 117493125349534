import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Topbar from "../Components/Topbar";
import axios from "axios";
import { POP_URI } from "../../utils/index";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import TapKaroContext from "../../context/Context";

const Popups = () => {
  const [img, setImg] = useState();
  const [popup, setPopup] = useState([]);
  const [title, setTitle] = useState("");
  const [marquee, setMarquee] = useState();

  const getPopUps = () => {
    axios
      .get(`${POP_URI}/`)
      .then((res) => {
        setPopup(res.data[0]);
      })
      .catch((err) => {});
  };

  const getMarquee = () => {
    axios
      .get(`${POP_URI}/marquee`)
      .then((res) => {
        setMarquee(res.data[0]);
        setTitle(res?.data[0]?.title);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getPopUps();
    getMarquee();
  }, []);

  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("admin_token")) {
      history("/admin/login");
    }
  }, [location]);

  return (
    <div className="flex h-[100vh]">
      <Sidebar />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="w-[80vw] pt-6 px-6">
        <Topbar title={"Discounts and Offers"} />
        <div>
          <div className="mt-3">
            <h1 className="font-bold text-2xl mb-1.5">Pop Up</h1>
            <div className="flex justify-start items-start">
              <div className="w-[80%] flex flex-col items-center">
                {img || popup?.img ? (
                  <img
                    src={popup?.img ? popup?.img : img}
                    alt={popup?.img ? popup?.img : img}
                    className="w-[97%] h-[50vh] object-cover object-center border border-black rounded-md"
                  />
                ) : (
                  <div className="w-[97%] m-auto rounded-md bg-gray-300 h-[50vh] mt-0.5"></div>
                )}
                <input
                  type="file"
                  onChange={(e) => {
                    const formData = new FormData();
                    formData.append("file", e.target.files[0]);
                    formData.append("upload_preset", "upload_photo");
                    formData.append("cloud_name", "dpbsogbtr");

                    fetch(
                      "https://api.Cloudinary.com/v1_1/dpbsogbtr/image/upload",
                      {
                        method: "POST",
                        body: formData,
                      }
                    )
                      .then((res) => res.json())
                      .then((res) => {
                        setImg(res.url);
                      })
                      .catch((err) => {});
                  }}
                  className="block m-auto mt-2 text-grey-500 file:mr-2 file:py-2 file:px-5 file:rounded-full file:border-0 file:text-sm file:font-mediumfile:bg-blue-50 file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50hover:file:text-amber-700"
                />
              </div>
              <div className="flex flex-col w-[20%]">
                {popup?.img ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      axios
                        .delete(`${POP_URI}/${popup?._id}`)
                        .then((res) => {
                          toast.success("Popup deleted successfully");
                          getPopUps();
                        })
                        .catch((err) => {
                          toast.error("Internal server error");
                        });
                    }}
                    className="bg-black text-center text-white w-[60%] mt-2 py-1 rounded-md"
                  >
                    Delete
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (img) {
                        axios
                          .post(`${POP_URI}/`, { img })
                          .then((res) => {
                            toast.success("Popup added successfully");
                            getPopUps();
                          })
                          .catch((err) => {
                            toast.error("Internal server error");
                          });
                      } else {
                        toast.warning("Please add the image");
                      }
                    }}
                    className="bg-black text-center text-white w-[60%] mt-2 py-1 rounded-md"
                  >
                    Add New
                  </button>
                )}
              </div>
            </div>
          </div>
          <div>
            <h1 className="font-bold text-2xl mb-1.5">Marquee</h1>
            <div>
              <input
                type="text"
                disabled={marquee?.title}
                placeholder="Enter text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                className="outline-none border border-black rounded-md px-3 py-1 w-[98%] block m-auto"
              />
              <div className="flex w-[20%] float-right">
                {marquee?.title ? (
                  <button
                    onClick={(e) => {
                      axios
                        .delete(`${POP_URI}/marquee/${marquee?._id}`)
                        .then((res) => {
                          getMarquee();
                          toast.success("Offer deleted successfully");
                        })
                        .catch((err) => {
                          toast.error("Internal server error");
                        });
                    }}
                    className="bg-black text-center mr-2 text-white w-[60%] mt-2 py-1 rounded-md"
                  >
                    Delete
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      axios
                        .post(`${POP_URI}/marquee`, { title })
                        .then((res) => {
                          toast.success("Marquee added successfully");
                          getMarquee();
                        })
                        .catch((err) => {
                          toast.error("Internal server error");
                        });
                    }}
                    className="bg-black text-center text-white w-[60%] mt-2 py-1 rounded-md"
                  >
                    Add New
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popups;
