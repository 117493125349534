import React, { useEffect, useRef } from "react";

import LandingPage from "../Sub-Screens/Home/LandingPage";
import ScrollingCards from "../Sub-Screens/Home/ScrollingCards";
import AnimatedCards from "../Sub-Screens/Home/AnimatedCards";
import Testimonials from "../Sub-Screens/Home/Testimonials";
import Faq from "../Sub-Screens/Home/Faq";
import Shop from "../Sub-Screens/Home/Shop";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import ShopNow from "../Components/ShopNow";
import Helmet from "react-helmet";

const Home = () => {
  const feature = useRef(null);
  const home = useRef(null);
  const footer = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-hidden">
      <Helmet>
        <link rel="canonical" href="https://tapkaro.com/" />
        <meta name="robots" content="index, follow" />
        <title>
          TAP KARO | Grow Your Network with NFC QR Code Digital Business Card
        </title>
        <meta
          name="description"
          content="Share business card details seamlessly with Tap Karo | Tap or Scan to build connections! Elevate networking with the Ultimate all-in-One Digital Business Card"
        />
      </Helmet>
      <ShopNow />
      <Nav feature={feature} home={home} footer={footer} />
      <LandingPage home={home} />
      <ScrollingCards />
      <AnimatedCards />
      <Shop feature={feature} />
      <Testimonials />
      <Faq />
      <Footer footer={footer} home={home} />
    </div>
  );
};

export default Home;
