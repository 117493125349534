import React, { useContext, useEffect, useRef, useState } from "react";
import ShopNow from "../Components/ShopNow";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useNavigate } from "react-router";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import TapKaroContext from "../context/Context";
import { AiFillDelete } from "react-icons/ai";
import Checkout from "../Components/Checkout";
import { CiDiscount1 } from "react-icons/ci";

const ClassicCards = () => {
  const ref = useRef([]);
  const history = useNavigate();
  const { cards, getCards } = useContext(TapKaroContext);
  const [promo, setPromo] = useState("");
  const [discount, setDiscount] = useState(0);
  useEffect(() => {
    gsap.fromTo(
      "#leftShop",
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1.5,
      }
    );
    gsap.fromTo(
      "#rightShop",
      {
        y: 500,
        opacity: 0,
        rotate: 50,
      },
      {
        y: 0,
        rotate: 0,
        opacity: 1,
        duration: 1.5,
      }
    );
  }, []);

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.fromTo(
      "#leftHeading",
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggerHeading",
          // scrub: true,
          start: "top 90%",
          end: "top 60%",
        },
      }
    );
    gsap.fromTo(
      "#rightHeading",
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#triggerHeading",
          // scrub: true,
          start: "top 90%",
          end: "top 60%",
        },
      }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      ref.current,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.5,
        scrollTrigger: {
          trigger: "#blocksTrigger",
          // scrub: true,
          start: "top 50%",
          end: "top 0%",
        },
      }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      "#testCircles",
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#testCircles",
          start: window.innerWidth < 550 ? "top 90%" : "top 80%",
          end: window.innerWidth < 550 ? "top 70%" : "top 40%",
          // scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    getCards();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { offers } = useContext(TapKaroContext);
  const [readyToPay, setReadyToPay] = useState(false);
  const { cartData, setCartData } = useContext(TapKaroContext);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCartData(JSON.parse(localStorage.getItem("cart")));
    } else {
      setCartData([]);
    }
  }, [localStorage]);

  useEffect(() => {
    let string = offers.find((e) => {
      return e?.code?.toLowerCase() === promo?.toLowerCase();
    });
    if (string) {
      setDiscount(string?.discount);
    } else {
      setDiscount(0);
    }
  }, [promo]);

  return (
    <div className="overflow-x-hidden">
      <Nav />
      <ShopNow />
      <div className="text-white">
        <div className="pt-20 mobile:px-[5.5vw] md:px-[6vw] pb-2 bg-[#091628]">
          <p className="text-2xl font-semibold text-white">Cart</p>
        </div>
        <div
          className={`mobile:px-[6vw] md:px-[7.8vw] ${
            readyToPay
              ? "md:h-[106vh]"
              : discount > 0
              ? "md:h-[72vh]"
              : "md:h-[67vh]"
          } bg-[#091628] mobile:h-fit flex md:flex-row mobile:flex-col items-center justify-between md:py-0 mobile:py-4`}
        >
          <div
            className={`md:w-[69%] mobile:w-full offScroll rounded-xl mobile:border mobile:border-gray-600 mobile:h-[60vh] md:h-full overflow-x-hidden overflow-y-auto py-1 ${
              cartData?.length === 0
                ? "flex items-center justify-center border border-gray-500"
                : ""
            }`}
          >
            {cartData?.length !== 0 ? (
              cartData
                ?.sort((a, b) =>
                  a.title?.toLowerCase().localeCompare(b.title?.toLowerCase())
                )
                ?.map((e, i) => {
                  return <CheckOutBlock data={e} key={i} />;
                })
            ) : (
              <div className="flex flex-col items-center">
                <p className="text-2xl font-semibold mobile:px-8 md:px-0 text-center">
                  No Items Added In the Cart
                </p>
                <button
                  className="btn m-auto mt-2"
                  onClick={(e) => {
                    history("/shop");
                  }}
                >
                  Shop Now
                </button>
              </div>
            )}
          </div>
          <div className="md:w-[29%] mobile:w-full mobile:mt-3 md:mt-0 bg-[rgba(0,0,0,0.3)] h-full rounded-xl py-3 px-3 text-white">
            <h1 className="text-2xl font-medium">Order Summary</h1>
            <p className="text-gray-400">{cartData?.length} Item</p>
            <div className="w-full bg-gray-300 h-[1px] my-4"></div>
            <div>
              <div className="flex items-center justify-between mb-0.5">
                <p>Sub Total</p>
                <p className="text-oceanGreen">
                  {cartData?.length > 0
                    ? cartData
                        ?.map(
                          (e) =>
                            (e?.type === "Standard"
                              ? e?.price
                              : e?.customizedPrice) * e?.quantity
                        )
                        ?.reduce((prev, next) => prev + next)
                    : 0}
                  /-
                </p>
              </div>
              <div className="flex items-center justify-between mb-0.5">
                <p>
                  GST <span className="text-oceanGreen">(18%)</span>
                </p>
                <p className="text-oceanGreen">
                  {(
                    (cartData?.length > 0
                      ? cartData
                          ?.map(
                            (e) =>
                              (e?.type === "Standard"
                                ? e?.price
                                : e?.customizedPrice) * e?.quantity
                          )
                          ?.reduce((prev, next) => prev + next)
                      : 0) *
                    (18 / 100)
                  ).toFixed(2)}
                  /-
                </p>
              </div>
              <div className="flex items-center justify-between mb-0.5 mt-1">
                <p>Promo Code</p>
                <input
                  type="text"
                  value={promo}
                  onChange={(e) => {
                    setPromo(e.target.value);
                  }}
                  className="outline-none bg-transparent border text-center px-2 w-[35%] py-0.5 rounded-lg"
                />
              </div>
              {discount > 0 ? (
                <div className="flex text-sm items-center justify-end mb-0.5 mt-1 text-oceanGreen">
                  <p className="flex items-center">
                    {discount}% Off
                    <CiDiscount1 className="ml-1" size={22} />
                  </p>
                </div>
              ) : null}
            </div>
            <div className="w-full bg-gray-300 h-[1px] my-4"></div>
            <div className="flex items-center text-xl justify-between mb-0.5">
              <p>Total</p>
              <p className="text-oceanGreen">
                {(
                  parseInt(
                    cartData?.length > 0
                      ? cartData
                          ?.map(
                            (e) =>
                              (e?.type === "Standard"
                                ? e?.price
                                : e?.customizedPrice) * e?.quantity
                          )
                          ?.reduce((prev, next) => prev + next)
                      : 0
                  ) +
                  parseFloat(
                    (
                      (cartData?.length > 0
                        ? cartData
                            ?.map(
                              (e) =>
                                (e?.type === "Standard"
                                  ? e?.price
                                  : e?.customizedPrice) * e?.quantity
                            )
                            ?.reduce((prev, next) => prev + next)
                        : 0) *
                      (18 / 100)
                    ).toFixed(2)
                  ) -
                  parseInt(
                    (cartData?.length > 0
                      ? cartData
                          ?.map(
                            (e) =>
                              (e?.type === "Standard"
                                ? e?.price
                                : e?.customizedPrice) * e?.quantity
                          )
                          ?.reduce((prev, next) => prev + next)
                      : 0) *
                      (discount / 100)
                  )
                ).toFixed(2)}
                /-
              </p>
            </div>
            <p className="font-semibold text-center mt-7 text-sm">
              Secure Payment
            </p>
            <p className="text-sm text-center mt-3">
              Make a lasting impression with our NFC-enabled business card that
              features a sleek and modern design.
            </p>
            {!readyToPay ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setReadyToPay(true);
                }}
                className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] m-auto w-full mt-3 text-lg font-semibold py-0.5 rounded-lg"
              >
                Ready To Pay
              </button>
            ) : (
              <Checkout
                amount={(
                  parseInt(
                    cartData?.length > 0
                      ? cartData
                          ?.map(
                            (e) =>
                              (e?.type === "Standard"
                                ? e?.price
                                : e?.customizedPrice) * e?.quantity
                          )
                          ?.reduce((prev, next) => prev + next)
                      : 0
                  ) +
                  parseFloat(
                    (
                      (cartData?.length > 0
                        ? cartData
                            ?.map(
                              (e) =>
                                (e?.type === "Standard"
                                  ? e?.price
                                  : e?.customizedPrice) * e?.quantity
                            )
                            ?.reduce((prev, next) => prev + next)
                        : 0) *
                      (18 / 100)
                    ).toFixed(2)
                  ) -
                  parseInt(
                    (cartData?.length > 0
                      ? cartData
                          ?.map(
                            (e) =>
                              (e?.type === "Standard"
                                ? e?.price
                                : e?.customizedPrice) * e?.quantity
                          )
                          ?.reduce((prev, next) => prev + next)
                      : 0) *
                      (discount / 100)
                  )
                ).toFixed(2)}
              />
            )}
          </div>
        </div>
        <div className="pt-8 px-[6vw] pb-4 bg-[#091628]">
          <p className="text-2xl font-semibold text-white">
            Recommended Products
          </p>
        </div>
        <div
          id="blocksTrigger"
          className="grid mobile:grid-cols-1 md:grid-cols-3 mobile:gap-6 md:gap-14 px-[5vw] bg-[#091628] pb-6"
        >
          {cards.slice(0, 3)?.map((e, i) => {
            return (
              <div
                key={i}
                ref={(element) => {
                  ref.current[i] = element;
                }}
              >
                <Block data={e} />
              </div>
            );
          })}
        </div>
        <Footer />
      </div>
    </div>
  );
};

const Block = ({ data }) => {
  const history = useNavigate();
  const { cartData, setCartData } = useContext(TapKaroContext);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCartData(JSON.parse(localStorage.getItem("cart")));
    } else {
      setCartData([]);
    }
  }, [localStorage]);

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        history(`/shop/${data?._id}`);
      }}
      className="p-4 rounded-lg shadow-lg shadow-navyBlue mx-[3vw] border border-gray-600 cursor-pointer transition-all hover:scale-105"
    >
      <div className="bg-[#091628] rounded-lg flex justify-center items-center">
        <img src={data?.mainImg} alt={data?.mainImg} />
      </div>
      <h1 className="text-white text-center mt-1 mobile:text-2xl md:text-2xl font-bold">
        {data?.title}
      </h1>
      <div className="flex justify-between items-center px-1 pt-2">
        <p className="text-white">
          Rs.{" "}
          <span className="line-through text-red-400">{data?.cutPrice}</span>{" "}
          {data?.price} /-
        </p>
        <button
          onClick={(e) => {
            e.stopPropagation();
            localStorage.setItem(
              "cart",
              JSON.stringify([
                ...cartData,
                {
                  ...data,
                  quantity: 1,
                  type: "Standard",
                  customization: { type: "Standard" },
                },
              ])
            );
            setCartData(JSON.parse(localStorage.getItem("cart")));
          }}
          className="bg-gradient-to-br from-[#1B75BD] to-[#7825CC] hover:from-[#fb00ff70] hover:to-[#00b3b6] mobile:px-4 md:text-base mobile:text-lg md:px-3 rounded-lg py-1 text-white"
          disabled={
            cartData?.find((e) => e?._id === data?._id)?._id?.length > 1
          }
        >
          {cartData?.find((e) => e?._id === data?._id)?._id?.length > 1
            ? "Added"
            : "Add To Cart"}
        </button>
      </div>
    </div>
  );
};

const CheckOutBlock = ({ data }) => {
  const history = useNavigate();
  const { cartData, setCartData } = useContext(TapKaroContext);
  const [Quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      setCartData(JSON.parse(localStorage.getItem("cart")));
    } else {
      setCartData([]);
    }
  }, [localStorage]);

  useEffect(() => {
    setQuantity(data?.quantity);
  }, []);

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        history(`/shop/${data?._id}`);
      }}
      className="px-4 py-3 md:mb-4 mobile:m-2 flex md:flex-row mobile:flex-col rounded-lg bg-[rgba(0,0,0,0.4)] shadow-md shadow-black border border-gray-800 cursor-pointer transition-all"
    >
      <div className="rounded-lg flex justify-center items-center">
        <img
          src={data?.mainImg}
          alt={data?.mainImg}
          className="mobile:w-full md:w-[20vw] rounded-3xl"
        />
      </div>
      <div className="pl-[1vw] flex flex-col mobile:w-full md:w-[80%]">
        <h1 className="text-white mt-1 text-2xl font-medium md:text-start mobile:text-center">
          {data?.title}
        </h1>
        <p className="text-sm mobile:text-center md:text-start">
          {data?.desc?.length > 110
            ? data?.desc.slice(0, 110) + "..."
            : data?.desc}
        </p>
        <div className="flex items-center justify-between md:mt-5 mobile:mt-3 text-white">
          <div className="flex items-center w-fit px-3 rounded-md mt-1.5 text-2xl font-bold">
            <p
              className="cursor-pointer bg-white text-gray-900 mobile:w-[8vw] md:w-[2vw] flex items-center justify-center rounded-md"
              onClick={(e) => {
                e.stopPropagation();
                if (Quantity <= 1) {
                  let dataCart = cartData;
                  let shortData = dataCart.find((e) => {
                    return e?._id?.toString() === data?._id?.toString();
                  });
                  dataCart = dataCart?.filter((e) => {
                    return e?._id !== data?._id;
                  });
                  dataCart = [...dataCart, { ...shortData, quantity: 1 }];
                  localStorage.setItem("cart", JSON.stringify(dataCart));
                  setCartData(JSON.parse(localStorage.getItem("cart")));
                  setQuantity(1);
                } else {
                  let dataCart = cartData;
                  let shortData = dataCart.find((e) => {
                    return e?._id?.toString() === data?._id?.toString();
                  });
                  dataCart = dataCart?.filter((e) => {
                    return e?._id !== data?._id;
                  });
                  dataCart = [
                    ...dataCart,
                    { ...shortData, quantity: Quantity - 1 },
                  ];
                  localStorage.setItem("cart", JSON.stringify(dataCart));
                  setCartData(JSON.parse(localStorage.getItem("cart")));
                  setQuantity(Quantity - 1);
                }
              }}
            >
              -
            </p>
            <p className="mx-3">{data?.quantity}</p>
            <p
              className="cursor-pointer bg-white text-gray-900 mobile:w-[8vw] md:w-[2vw] flex items-center justify-center rounded-md"
              onClick={(e) => {
                e.stopPropagation();
                setQuantity(Quantity + 1);
                let dataCart = cartData;
                let shortData = dataCart.find((e) => {
                  return e?._id?.toString() === data?._id?.toString();
                });
                dataCart = dataCart?.filter((e) => {
                  return e?._id !== data?._id;
                });
                dataCart = [
                  ...dataCart,
                  { ...shortData, quantity: Quantity + 1 },
                ];
                localStorage.setItem("cart", JSON.stringify(dataCart));
                setCartData(JSON.parse(localStorage.getItem("cart")));
              }}
            >
              +
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-white text-lg mr-2">
              Rs.{" "}
              {data?.type === "Standard" ? data?.price : data?.customizedPrice}
              /-
            </p>
            <AiFillDelete
              size={23}
              onClick={(e) => {
                e.stopPropagation();
                const updatedCart = cartData?.filter((e) => {
                  return e?._id !== data?._id;
                });
                localStorage.setItem("cart", JSON.stringify(updatedCart));
                setCartData(JSON.parse(localStorage.getItem("cart")));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassicCards;
