import React from "react";

import card1 from "../../images/Scrolling animation/Scrolling animation/1 (1).png";
import card2 from "../../images/Scrolling animation/Scrolling animation/1 (10).png";
import card3 from "../../images/Scrolling animation/Scrolling animation/1 (11).png";
import card4 from "../../images/Scrolling animation/Scrolling animation/1 (12).png";
import card5 from "../../images/Scrolling animation/Scrolling animation/1 (13).png";
import card6 from "../../images/Scrolling animation/Scrolling animation/1 (14).png";
import card7 from "../../images/Scrolling animation/Scrolling animation/1 (15).png";
import card8 from "../../images/Scrolling animation/Scrolling animation/1 (16).png";
import card10 from "../../images/Scrolling animation/Scrolling animation/1 (18).png";
import card11 from "../../images/Scrolling animation/Scrolling animation/1 (19).png";
import card12 from "../../images/Scrolling animation/Scrolling animation/1 (2).png";
import card13 from "../../images/Scrolling animation/Scrolling animation/1 (20).png";
import card14 from "../../images/Scrolling animation/Scrolling animation/1 (22).png";
import card15 from "../../images/Scrolling animation/Scrolling animation/1 (23).png";
import card16 from "../../images/Scrolling animation/Scrolling animation/1 (24).png";
import card17 from "../../images/Scrolling animation/Scrolling animation/1 (21).png";
import card18 from "../../images/Scrolling animation/Scrolling animation/1 (25).png";
import card19 from "../../images/Scrolling animation/Scrolling animation/1 (3).png";
import card20 from "../../images/Scrolling animation/Scrolling animation/1 (4).png";
import card21 from "../../images/Scrolling animation/Scrolling animation/1 (5).png";
import card22 from "../../images/Scrolling animation/Scrolling animation/1 (6).png";
import card23 from "../../images/Scrolling animation/Scrolling animation/1 (7).png";
import card24 from "../../images/Scrolling animation/Scrolling animation/1 (8).png";
import card25 from "../../images/Scrolling animation/Scrolling animation/3.png";
import card26 from "../../images/Scrolling animation/Scrolling animation/1 (9).png";

const ScrollingCards = () => {
  const images = [
    card1,
    card2,
    card3,
    card4,
    card5,
    card6,
    card7,
    card8,
    card10,
    card11,
    card12,
    card13,
    card14,
    card15,
    card16,
    card17,
    card18,
    card19,
    card20,
    card21,
    card22,
    card23,
    card24,
    card25,
    card26,
  ];
  let reverse = [
    card16,
    card17,
    card18,
    card19,
    card20,
    card21,
    card22,
    card23,
    card24,
    card25,
    card26,
    card1,
    card2,
    card10,
    card11,
    card12,
    card13,
    card14,
    card15,
    card3,
    card4,
    card5,
    card6,
    card7,
    card8,
  ];
  let set1 = [card1, card2, card3, card4, card5, card6, card7, card8];
  let set2 = [card10, card11, card12, card13, card14, card15, card16, card17];
  let set3 = [card18, card19, card20, card21, card22, card23, card24, card26];
  let speed = window.innerWidth < 550 ? 60000 : 20000;
  return (
    <div className="w-full md:h-[100vh] mobile:h-[65vh] bg-darkNavyBlue overflow-clip mobile:pt-20 md:pt-0 flex flex-col justify-between">
      <Banner images={window.innerWidth < 550 ? images : set1} speed={speed} />
      <Banner
        images={window.innerWidth < 550 ? reverse : set2}
        speed={speed}
        direction={"reverse"}
      />
      {window.innerWidth < 550 ? null : <Banner images={set3} speed={speed} />}
    </div>
  );
};

const Banner = ({ images, speed, direction }) => {
  return (
    <div
      className={`inner mobile:h-[25vh] md:h-[30vh] ${
        direction ? "rotate-[168deg]" : "rotate-[348deg]"
      } mobile:-mt-8 md:-mt-8`}
    >
      <div className="wrapper">
        <Section images={images} speed={speed} direction={direction} />
        <Section images={images} speed={speed} direction={direction} />
        <Section images={images} speed={speed} direction={direction} />
        <Section images={images} speed={speed} direction={direction} />
        <Section images={images} speed={speed} direction={direction} />
        <Section images={images} speed={speed} direction={direction} />
      </div>
    </div>
  );
};

const Section = ({ speed, images, direction }) => {
  return (
    <section
      style={{ "--speed": `${speed}ms` }}
      className={`mobile:w-[2200vw] md:w-[190vw]`}
    >
      {images.map((image, id) => (
        <div key={id} className={`mx-4 ${direction ? "rotate-[180deg]" : ""}`}>
          <img
            src={image}
            alt={id}
            className="mobile:h-[25vh] md:h-[30vh] w-full"
          />
        </div>
      ))}
    </section>
  );
};

export default ScrollingCards;
