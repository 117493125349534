import React, { useContext, useRef, useState } from "react";
import bg from "../../images/Free_3,5x2,5in_Business_Card_6.png";
import { useNavigate } from "react-router";
import axios from "axios";
import Axios from "axios";
import { BASE_URI, BUSSINESS_URI } from "../../utils";
import TapKaroContext from "../../context/Context";
import Captcha from "../../Components/Captcha";
import { toast } from "react-toastify";

const BussinessOtp = () => {
  const captchaRef = useRef(null);
  const [data, setData] = useState({
    otp: "",
  });
  const { bussinessRegister } = useContext(TapKaroContext);
  const history = useNavigate();

  return (
    <div className="bg-gradient-to-br from-navyBlue to-lightBlue w-[100vw] mobile:h-fit md:h-[100vh] flex md:flex-row mobile:flex-col items-center text-white">
      <img src={bg} alt={bg} className="h-full mobile:p-5 md:p-7" />
      <div className="md:ml-[4%] mobile:w-[80%] md:w-[35%] md:pb-0 mobile:pb-6">
        <h1 className="text-3xl font-bold">Congratulations !!</h1>
        <h1 className="text-2xl font-semibold my-2">
          Your company get Successfully Registered{" "}
        </h1>
        <p className="mb-8">Please Verify your details to move further</p>
        <label htmlFor="email">Enter OTP received on your Email ID</label>
        <input
          type="text"
          id="email"
          value={data.otp}
          onChange={(e) => {
            setData({ ...data, otp: e.target.value });
          }}
          className="border w-full border-gray-400 mt-1 outline-none py-1 px-3 bg-transparent block rounded-lg mb-3"
        />
        <p className="text-end float-right mb-2 text-sm text-gray-300 w-[80%]">
          Check your inbox. If it doesn’t appear within a few minutes, check
          your spam folder.
        </p>
        <div className="flex items-center w-[100%] justify-center my-2">
          <Captcha captchaRef={captchaRef} />
        </div>
        <button
          onClick={async (e) => {
            e.preventDefault();
            let token = captchaRef.current.getValue();
            if (token) {
              let response = await Axios.post(`${BASE_URI}verify-token`, {
                reCAPTCHA_TOKEN: token,
              });
              if (
                response.status === 200 &&
                response.data.verification_info.success === true
              ) {
                if (data.otp == bussinessRegister?.otp) {
                  axios
                    .post(`${BUSSINESS_URI}/register`, bussinessRegister)
                    .then((res) => {
                      if (res.status === 200) {
                        toast.success("Bussines verified successfully");
                        history("/bussiness/login");
                        captchaRef.current.reset();
                      } else if (res.status === 203) {
                        toast.warning(res.data.data);
                      } else {
                        toast.warning("Internal server error");
                      }
                    })
                    .catch((err) => {
                      toast.error("Internal server error");
                    });
                } else {
                  toast.warning("Otp is incorrect");
                  captchaRef.current.reset();
                }
              } else {
                captchaRef.current.reset();
                toast.warning("User not verified");
              }
            } else {
              captchaRef.current.reset();
              toast.warning("User not verified");
            }
          }}
          className="w-full bg-lightBlue py-1.5 rounded-lg font-medium mt-3"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default BussinessOtp;
