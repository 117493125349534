import React from "react";
import { Route, Routes } from "react-router";

import Home from "./Screens/Home";
import ClassicCards from "./Screens/ClassicCards";
import Login from "./Screens/Login";
import Register from "./Screens/Register";
import PasswordReset from "./Screens/PasswordReset";
import ViewCard from "./Screens/ViewCard";
import Cart from "./Screens/Cart";

import AdminLogin from "./Admin/Screen/AdminLogin";
import Admin from "./Admin/Screen/Admin";
import AnnounceToken from "./Admin/Screen/AnnounceToken";
import Orders from "./Admin/Screen/Orders";
import Bussiness from "./Admin/Screen/Bussiness";
import Card from "./Admin/Screen/Card";
import ViewOrder from "./Admin/Screen/ViewOrder";
import ViewBussiness from "./Admin/Screen/ViewBussiness";
import ViewTokens from "./Admin/Screen/ViewTokens";
import AddNewCard from "./Admin/Screen/AddNewCard";
import Offers from "./Admin/Screen/Offers";
import Popups from "./Admin/Screen/Popups";
import Query from "./Admin/Screen/Query";

import BussinessHome from "./Bussiness/Screen/BussinessHome";
import BussinessProfile from "./Bussiness/Screen/BussinessProfile";
import BussinessLogin from "./Bussiness/Screen/BussinessLogin";
import BussinessRegister from "./Bussiness/Screen/BussinessRegister";

import Profile from "./Mobile/Screen/Profile";
import EditProfile from "./Mobile/Screen/EditProfile";
import Connections from "./Mobile/Screen/Connections";
import ViewQr from "./Mobile/Screen/ViewQr";
import LinkTaps from "./Mobile/Screen/LinkTaps";
import ViewProfileMobile from "./Mobile/Screen/User/ViewProfileMobile";
import ViewProfileQr from "./Mobile/Screen/User/ViewProfileQr";
import ViewEditUser from "./Screens/User/ViewEditUser";
import ViewProfile from "./Screens/User/ViewProfile";
import EditProfileFull from "./Screens/User/EditProfileFull";
import BussinessSettings from "./Bussiness/Screen/BussinessSettings";
import ViewConnections from "./Screens/User/ViewConnections";
import BussinessOtp from "./Bussiness/Screen/BussinessOtp";
import AdminPasswordReset from "./Admin/Screen/AdminPasswordReset";
import Failure from "./Payment/Failure";
import Success from "./Payment/Success";
import BussinessPasswordReset from "./Bussiness/Screen/BussinessPasswordReset";
import PayCheck from "./Payment/PayCheck";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import Refund from "./Screens/Refund";
import UpdateCard from "./Admin/Screen/UpdateCard";
import PopUp from "./PopUp";
import TermsAndCondition from "./Screens/TermsAndCondition";
import About from "./Screens/About";

const App = () => {
  return (
    <div>
      <PopUp />
      <Routes>
        {/* Payments */}
        <Route exact path="/payment-fail" element={<Failure />} />
        <Route exact path="/payment-success" element={<Success />} />
        <Route path="/pay/:id" element={<PayCheck />} />

        {/* Privacy pages */}
        <Route exact path="/refund" element={<Refund />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms" element={<TermsAndCondition />} />

        {/* Login Routes */}
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route
          exact
          path="/password-reset/:id/:token"
          element={<PasswordReset />}
        />

        {/* Profile Routes */}
        {/* View Profile */}
        <Route
          exact
          path="/:id"
          element={
            window.innerWidth < 550 ? <ViewProfileMobile /> : <ViewProfile />
          }
        />
        <Route exact path="/qr/:id" element={<ViewProfileQr />} />

        {/* View Edit Profile */}
        <Route
          exact
          path="/user"
          element={window.innerWidth < 550 ? <Profile /> : <ViewEditUser />}
        />
        <Route
          exact
          path="/user/edit/"
          element={
            window.innerWidth < 550 ? <EditProfile /> : <EditProfileFull />
          }
        />
        <Route
          exact
          path="/user/connections/"
          element={
            window.innerWidth < 550 ? <Connections /> : <ViewConnections />
          }
        />
        <Route exact path="/user/view-qr/" element={<ViewQr />} />
        <Route exact path="/user/link-taps/" element={<LinkTaps />} />

        {/* Main Website */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/shop" element={<ClassicCards />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/shop/:id" element={<ViewCard />} />

        {/* Bussiness Panel */}
        <Route exact path="/bussiness/login" element={<BussinessLogin />} />
        <Route
          exact
          path="/bussiness/register"
          element={<BussinessRegister />}
        />
        <Route
          exact
          path="/bussiness/password-reset/:id/:token"
          element={<BussinessPasswordReset />}
        />
        <Route
          exact
          path="/bussiness/verification"
          element={<BussinessOtp />}
        />
        <Route exact path="/bussiness" element={<BussinessHome />} />
        <Route exact path="/bussiness/profile" element={<BussinessProfile />} />
        <Route
          exact
          path="/bussiness/settings"
          element={<BussinessSettings />}
        />

        {/* Admin Routes */}
        <Route exact path="/admin/login" element={<AdminLogin />} />
        <Route
          exact
          path="/admin/password-reset/:id/:token"
          element={<AdminPasswordReset />}
        />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/admin/announce" element={<AnnounceToken />} />
        <Route exact path="/admin/orders" element={<Orders />} />
        <Route exact path="/admin/orders/:id" element={<ViewOrder />} />
        <Route exact path="/admin/bussiness" element={<Bussiness />} />
        <Route exact path="/admin/bussiness/:id" element={<ViewBussiness />} />
        <Route
          exact
          path="/admin/bussiness/:id/view-tokens"
          element={<ViewTokens />}
        />
        <Route exact path="/admin/cards" element={<Card />} />
        <Route exact path="/admin/cards/addNew" element={<AddNewCard />} />
        <Route exact path="/admin/cards/edit/:id" element={<UpdateCard />} />
        <Route exact path="/admin/offers" element={<Offers />} />
        <Route exact path="/admin/pop-ups" element={<Popups />} />
        <Route exact path="/admin/query" element={<Query />} />
      </Routes>
    </div>
  );
};

export default App;
