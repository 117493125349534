import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import TapKaroContext from "./context/Context";
import axios from "axios";
import { POP_URI } from "./utils";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth < 550 ? "80vw" : "50vw",
    height: window.innerWidth < 550 ? "28vh" : "60vh",
    borderRadius: "0.5rem",
    padding: 0,
    backgroundPosition: "center",
    zIndex: 100,
    position: "absolute",
  },
};

const PopUp = () => {
  let { modalIsOpen, setIsOpen } = useContext(TapKaroContext);
  const [popup, setPopup] = useState([]);

  const getPopUps = () => {
    axios
      .get(`${POP_URI}/`)
      .then((res) => {
        setPopup(res.data[0].img);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getPopUps();
  }, []);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen === "true"}
        onRequestClose={() => {
          setIsOpen("hard-false");
        }}
        style={customStyles}
      >
        {" "}
        <img
          className="object-cover object-center w-full h-full"
          src={popup}
          alt={popup}
        />
      </Modal>
    </div>
  );
};

export default PopUp;
