import React, { useContext, useState } from "react";
import Modal from "react-modal";
import bg from "../../images/user/fbg.jpg";
import axios from "axios";
import { PROFILE_URI } from "../../utils";
import { useParams } from "react-router";
import TapKaroContext from "../../context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth < 550 ? "80vw" : "20vw",
    borderRadius: "0.8rem",
    backgroundImage: `url(${bg})`,
    padding: 0,
    backgroundPosition: "center",
  },
};

const AddConnection = ({ setIsOpen, modalIsOpen }) => {
  const { id } = useParams();
  const { getProfile } = useContext(TapKaroContext);
  const [data, setData] = useState({
    name: "",
    designation: "",
    email: "",
    phone: "",
  });
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="text-white bg-transparent h-full w-full px-5 p-4">
          <h1 className="text-lg font-medium mb-3 text-center">
            Add Connection
          </h1>
          <input
            type="text"
            placeholder="Name"
            value={data?.name}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
            className="outline-none text-center block px-2 my-1 bg-transparent w-full border-b mb-4 pb-1 border-b-gray-400"
          />
          <input
            type="text"
            placeholder="Designation"
            value={data?.designation}
            onChange={(e) => {
              setData({ ...data, designation: e.target.value });
            }}
            className="outline-none text-center block px-2 my-1 bg-transparent w-full border-b mb-4 pb-1 border-b-gray-400"
          />
          <input
            type="number"
            value={data?.phone}
            onChange={(e) => {
              setData({ ...data, phone: e.target.value });
            }}
            placeholder="Phone no."
            className="outline-none text-center block px-2 my-1 bg-transparent w-full border-b mb-4 pb-1 border-b-gray-400"
          />
          <input
            type="email"
            value={data?.email}
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
            }}
            placeholder="Email"
            className="outline-none text-center block px-2 my-1 bg-transparent w-full border-b mb-4 pb-1 border-b-gray-400"
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              if (
                data?.email &&
                data?.name &&
                data?.designation &&
                data?.phone
              ) {
                axios
                  .post(`${PROFILE_URI}/connection/${id}`, data)
                  .then((res) => {
                    toast.success("Connection added successfully");
                    getProfile();
                    setIsOpen(false);
                    setData({
                      name: "",
                      designation: "",
                      email: "",
                      phone: "",
                    });
                  })
                  .catch((err) => {
                    toast.error("Internal server error");
                  });
              } else {
                toast.error("Please fill all the fields");
              }
            }}
            className="bg-lightBlue block mx-auto mt-3 px-4 py-1 rounded-lg"
          >
            Connect
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AddConnection;
